


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})

export class LocationService {

    currency;

    constructor(
      private http: HttpClient
    ) { }

    getCurrency() {
      return new Promise((resolve, reject) => {
        if(this.currency) return resolve(this.currency);
        fetch('https://ipapi.co/currency/')
          .then((res) => res.text())
          .then((txt) => {
            this.currency = txt;
            resolve(txt);
          })
          .catch((err) => {
            console.log('Error in gettin currency ::', err);
            const location = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if(location == 'Asia/Calcutta' || location  == 'asia/kolkata') {
              this.currency = 'INR';
              resolve('INR');
            }
            this.currency = 'USD';
            resolve('USD');
          });
      });
    }

}



