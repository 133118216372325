import { Component, Inject, Renderer2 } from '@angular/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import {
  AlertController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
// import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { StatusBar, Style } from '@capacitor/status-bar';
import { environment } from 'src/environments/environment';
import { WacConfigService } from './services/wac-config/wac-config.service';
import { Title } from '@angular/platform-browser';
import { SplashPage } from './splash/splash.page';
import { AlertService } from './services/alert/alert.service';
import { DOCUMENT, Location } from '@angular/common';
import { CommonService } from './services/common/common.service';
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Router,
} from '@angular/router';
import { UserService } from './services/user/user.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { mapTo } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { App as CapacitorApp } from '@capacitor/app';
// import { DpadHandlerService } from './services/dpadHandler/dpadHandler.service';
import { OrderService } from './services/order/order.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { UserDeviceService } from './services/userDevice/userDevice.service';
import { SplashService } from './services/splash/splash.service';
import { Plugins } from '@capacitor/core';
import { AppTimeService } from './services/appTime/app-time.service';
import { LocationService } from './services/location/location.service';
const { ExitAppIosPlugin } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  appTermination = false;
  public appIsOnline$: Observable<boolean> = undefined;
  internetCheck: boolean;
  count = 1;
  deviceInfo;
  onResumeSubscription: Subscription;
  internetAlert;
  showSplash = true;
  try = 0;

  constructor(
    private platform: Platform,
    // private statusBar: StatusBar,
    private alertService: AlertService,
    private modalCtrl: ModalController,
    public wacConfig: WacConfigService,
    public common: CommonService,
    private _location: Location,
    public screenOrientation: ScreenOrientation,
    public _title: Title,
    private _renderer2: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private deeplinks: Deeplinks,
    private network: Network,
    public nav: NavController,
    public userService: UserService,
    public device: Device,
    public userDeviceService: UserDeviceService,
    public alertController: AlertController,
    // public dpadHandlerService: DpadHandlerService,
    public toastController: ToastController,
    public orderService: OrderService,
    private splashService: SplashService,
    private appTimeService: AppTimeService,
    private currency: LocationService,
    @Inject(DOCUMENT) private _document: Document
  ) {
  }

  async ngOnInit() {
    await this.beforeNgOnInit();
    this.appTimeService.appOpened();
    // const start = performance.now();

    // await this.logOutDevice();
    this.deviceInfo = this.device;

    // this.common.goToPage("");
    this.checkInternet().subscribe((isOnline) => {
      this.internetCheck = isOnline;

      // checking internet connection
      if (this.internetCheck == true) {
        let navTransition;
        if (this.internetAlert) {
          navTransition = this.alertController?.dismiss();
          navTransition.then(() => {
            this.nav.pop();
          });
        }
      } else {
        this.internetAlert = this.alertService.showAlertV2(
          'Sorry! No Internet',
          'Please close the app, check internet and try again!',
          () => {
            window.location.reload();
          },
          () => {
            if (this.platform.is('android')) {
              CapacitorApp.exitApp();
            } else if (this.platform.is('ios')) {
              ExitAppIosPlugin.killApp().then();
            } else {
              window.location.reload();
            }
          }
        );
      }
    });

    // Check for encrypted string in query
    this.route.queryParamMap.subscribe(async (paramMap: ParamMap) => {
      if (paramMap.has('t')) {
        await this.userService.encryptedLogin(paramMap.get('t'));
      }
    });

    // const end = performance.now();
  }

  /**
   * @description - code to be executed before the ngOnInit
   */
  public async beforeNgOnInit(): Promise<void> {
    // this.splashScreen.show();
    await this.initializeApp();

    CapacitorApp.addListener('backButton', ({ canGoBack }) => {

      this.common.iframeFullscreenListner.next(false);
      if (
        !canGoBack ||
        this.router.url == '/' ||
        this.router.url == '/slider'
      ) {
      //   if (this.common.deviceType == 'androidTv')
      //     return CapacitorApp.exitApp();
      //   this.showExitConfirm();
      } else {
        window.history.back();
      }
    });

    // this.showExitConfirm();

    CapacitorApp.addListener('appStateChange', async ({ isActive }) => {
      // Check for tempPayInfo - if true set user pkg
      if (localStorage.getItem('tempPayInfo')) await this.setPkg();
      if (!isActive) {
        this.appTimeService.clearMonitor();
        return;
      }
      this.appTimeService.appOpened();
    });
  }

  showExitConfirm() {
    if (this.appTermination) return;

    let buttons = [
      {
        text: 'Yes',
        handler: () => {
          // this.dpadHandlerService.backButtonHandler( 'close' );
          CapacitorApp.exitApp();
        },
      },
      {
        text: 'No',
        role: 'cancel',
        handler: () => {
          // this.dpadHandlerService.backButtonHandler('close');
          this.appTermination = false;
          // window.location.reload();
        },
      },
    ];
    this.alertService
      .showAlert('Exit App', 'Do you want to close the app?', buttons)
      .then(() => {
        this.appTermination = true;

        // setTimeout(() => {
        //   this.dpadHandlerService.backButtonHandler('open');
        // }, 1000);
      });
  }

  loadDeepLink() {
    this.deeplinks
      .route({
        '/about-us': SplashPage,
      })
      .subscribe(
        (match) => {
          this.showSplash = false;

          // match.$route - the route we matched, which is the matched entry from the arguments to route()
          // match.$args - the args passed in the link
          // match.$link - the full link data
        },
        (nomatch) => {
          // nomatch.$link - the full link data
          if (nomatch.$link) this.router.navigateByUrl(nomatch.$link.path);
        }
      );

    // App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
    //   console.log('appUrlOpen',event);
    //   this.zone.run(() => {
    //     console.log("event.url",event.url)
    //     const domain = 'nonedev.mogiio.tv';

    //     const pathArray = event.url.split(domain);
    //     // The pathArray is now like ['https://devdactic.com', '/details/42']

    //     // Get the last element with pop()
    //     const appPath = pathArray.pop();
    //     if (appPath) {
    //       this.router.navigateByUrl(appPath);
    //     }
    //   });
    // });
  }

  checkInternet() {
    if (!window || !navigator || !('onLine' in navigator)) return;

    this.appIsOnline$ = Observable.create((observer) => {
      observer.next(true);
    }).pipe(mapTo(true));

    if (this.platform.is('cordova')) {
      // on Device - when platform is cordova
      this.appIsOnline$ = merge(
        this.network.onConnect().pipe(mapTo(true)),
        this.network.onDisconnect().pipe(mapTo(false))
      );
    } else {
      // on Browser - when platform is Browser
      this.appIsOnline$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      );
    }

    return this.appIsOnline$;
  }

  setTheme() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      document.body.classList.toggle(
        this.wacConfig.configuration.colorPallete.theme,
        true
      );
    } else {
      // let disableConsoleLog = "window.console = {log:()=>{},error:()=>{},warn:()=>{}};";
      let disableConsoleLog = '';
      try {
        const debugFlag = this.route.snapshot.queryParamMap.get('debug');
        //baseUrl: "https://apis.mogiio.com"
        // console.log("debugFlag :: ", debugFlag);
        if (debugFlag == 'true') {
          disableConsoleLog = '';
        }
      } catch (error) {
        console.error('ours debugging checking');
      }
    }
  }

  setFabIcon() {
    this._document.body.classList.toggle(
      this.wacConfig.configuration.colorPallete.theme,
      true
    );
    this._document
      .getElementById('appFavicon')
      .setAttribute('href', this.wacConfig.configuration.logoUrl);

    if(document.title && this.wacConfig.configuration.seoConfig.home.title)
      document.title = this.wacConfig.configuration.seoConfig.home.title || this.wacConfig.configuration.appName;
  }

  async initializeApp() {

    if (
      (this.platform.is('android') || this.platform.is('ios')) &&
      !this.platform.is('mobileweb') 
      // && this.common.deviceType != 'androidTv'
    ) {
      if (!sessionStorage.getItem('temp-last-url'))
        this.showVideoSplash(
          './assets/videos/video.mp4',
          './assets/imgs/poster.png'
        );
      // this.common.goToPage('splash');
      // this.router.navigate(['splash']);
    } else {
      setTimeout(() => {
        this.splashService.spalshVideoStarted.next();
      }, 100);
    }

    await this.initWacConfig();
    this.setFabIcon();

    this.platform.ready().then(async () => {
      if (
        (this.platform.is('android') && !this.platform.is('mobileweb') ) ||
        (this.platform.is('ios') && !this.platform.is('mobileweb') )
      ) {
        await this.setupDevice();
        this.loadDeepLink();
      }
      this.setUpHeaderElementsIdsArr();
      this.backButtonHandler();
      this.nativeLoginCheck();

      try {
        await this.wacConfig.setConfiguration();

        this.setTheme();
      } catch (error) {
        console.error('out error', error);
      }
      // alert("this.platform.is :: " + this.platform.platforms() + " Width : " + this.platform.width() );
      if (
        (this.platform.is('android') || this.platform.is('ios')) &&
        !this.platform.is('mobileweb') 
        // && this.common.deviceType != 'androidTv'
      ) {
        // if (this.showSplash) {
        //   this.showVideoSplash("./assets/videos/video.mp4", "./assets/imgs/poster.png");
        //   // this.wacConfig.splashVideoCompleted = true;
        // }
        // else
        //   this.wacConfig.splashVideoCompleted = true;
      } else {
        // SplashScreen.hide();
        // this.splashScreen.hide();
        this.wacConfig.splashVideoCompleted = true;
        // this.splashService.spalshVideoStarted.next();

        if (this.wacConfig.domainName != 'localhost') {
          environment[
            'userImgDefault'
          ] = `https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/https://${this.wacConfig.domainName}/${environment['userImgDefault']}`;
          environment[
            'verticalDefaultImg'
          ] = `https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/https://${this.wacConfig.domainName}/${environment['verticalDefaultImg']}`;
          environment[
            'horizontalDefaultImg'
          ] = `https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/https://${this.wacConfig.domainName}/${environment['horizontalDefaultImg']}`;
        }
      }

      try {
        this.injectCode(this.wacConfig.configuration.injectCode);
      } catch (err) {
        console.error('ours setting injectCode');
      }

      this.onResumeSubscription = this.platform.resume.subscribe(() => {

        if (localStorage.getItem('tempPayInfo')) this.setPkg();
      });
    });

    // just to have a cached value
    setTimeout(() => {
      this.currency.getCurrency();
    }, 4000);
  }

  backButtonHandler() {}

  async nativeLoginCheck() {
    try {
      if (!localStorage) return;

      let updateApp = JSON.parse(await localStorage.getItem('updateAppPopUp'));
      let data: any = {
        count: 1,
      };
      if (updateApp) {
        updateApp['count'] =
          updateApp && updateApp['count'] ? updateApp['count'] + 1 : 1;
      } else {
        updateApp = data;
      }

      localStorage.setItem('updateAppPopUp', JSON.stringify(updateApp));

      let user;
      let token;
      try {
        user = JSON.parse(localStorage.getItem('user'));
        token = localStorage.getItem('token');
      } catch (e) {
        user = null;
      }
      // let count = 0;
      if (
        // this.common.deviceType !== 'androidTv' &&
        user &&
        token &&
        (!user['name'] ||
          !user['email'] ||
          !user['mobile'] ||
          !user['genre'] ||
          !user['gender'] ||
          !user['dob']) &&
        user['loginCount'] == 2
      )
        setTimeout(() => {
          if (!sessionStorage.getItem('temp-last-url'))
            this.common.goToPage('update-profile');
        }, 3000);

      if (user && user['type'] != 'dummy') {
        this.count = this.count + 1;

        if (!user['loginCount']) {
          user['loginCount'] = this.count;
          localStorage.setItem('user', JSON.stringify(user));

        } else {
          this.count = user['loginCount'] + 1;
          user['loginCount'] = this.count;
          localStorage.setItem('user', JSON.stringify(user));
        }

        // else
        // this.checkPackage();

        // let promise = new Promise((resolve, reject) => {

        //   this.nativeStorage.getItem('userLoginCount')
        //     .then(
        //       userNativeData => {
        //         console.log("userNative", userNativeData);
        //         resolve(userNativeData);
        //         console.log("userNativeData geeta", userNativeData)
        //         if (userNativeData == undefined) {
        //           console.log("userNativeData geeta", userNativeData, this.count)
        //           this.nativeStorage.setItem('userLoginCount', { loginCount: this.count, details: user })
        //             .then(
        //               () =>
        //                 console.log('Stored item! geeta'),
        //               error => console.error('Error storing item', error)
        //             );
        //         } else {
        //           this.count = userNativeData.loginCount + 1;
        //           this.nativeStorage.setItem('userLoginCount', { loginCount: this.count, details: user })
        //             .then(
        //               () =>
        //                 console.log('Stored item!'),
        //               error => console.error('Error storing item', error)
        //             );
        //         }
        //         if (user && (!user['name'] || !user['email'] || !user['mobile'] || !user['genre'] || !user['gender'] || !user['dob']) && userNativeData.loginCount == 2)
        //           this.common.goToPage('update-profile');
        //         else
        //           this.checkPackage();

        //         console.log("userNativeData", userNativeData)
        //         console.log("userNativeData", userNativeData.loginCount)
        //       },
        //       error => console.error(error)

        //     ), error => {
        //       reject(error);
        //     };

        // });
      } else {
        // this.checkPackage();
      }
    } catch (error) {
      console.error('our', error);
    }
  }

  setupDevice() {
    // console.log("this.platform.width() :: ", this.platform.width());
    // alert("setting this.platform.width() " + this.platform.width() );
   
    // if (this.common.deviceType == 'androidTv') {
    //   this.screenOrientation.lock(
    //     this.screenOrientation.ORIENTATIONS.LANDSCAPE
    //   );
    // } else 
    if (
      this.platform.width() < 900 &&
      (this.platform.is('android') || this.platform.is('ios')) &&
      !this.platform.is('mobileweb')
    ) {
      // this.screenOrientation.unlock();
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      StatusBar.setOverlaysWebView({ overlay: false });
      // this.statusBar.overlaysWebView(false);
    }

    try {
      // this.statusBar.backgroundColorByHexString(this.wacConfig.configuration.splashVideoBackgroundColor);
      if (
        (this.wacConfig.configuration.colorPallete?.theme == 'blue-white' ||
          this.wacConfig.configuration.colorPallete?.theme == 'orange-white') &&
        this.platform.is('ios')
      ) {
        const setStatusBarStyleDark = async () => {
          await StatusBar.setStyle({ style: Style.Light });
        };
        setStatusBarStyleDark();

        // this.statusBar.backgroundColorByHexString('#ffffff');
        // this.statusBar.styleDefault();
      }
      // else {
      // this.statusBar.backgroundColorByHexString('#000000');
      // this.statusBar.styleLightContent();
      // }
      // this.statusBar.styleBlackTranslucent();
    } catch (error) {}

    setTimeout(async () => {
      await this.initPushNotification();
    }, 1000);
  }

  async initPushNotification() {
    try {
      const user: any = this.userService.getLocalUser();
      if (!user?._id) return;

      let OneSignal = null;
      OneSignal = await import('onesignal-cordova-plugin');

      OneSignal.set;
      // await this.oneSignal.startInit(this.wacConfig.configuration.oneSignalId, this.wacConfig.configuration.firebaseId);
      let oneSignalId =
        this.wacConfig.configuration.oneSignalId ||
        '3dfbad2c-93d9-477c-a64c-c397e92a72f6';
      let firebaseId =
        this.wacConfig.configuration.firebaseId || '303776170719';
      //.startInit( '3dfbad2c-93d9-477c-a64c-c397e92a72f6', '303776170719' )
  

      // Uncomment to set OneSignal device logging to VERBOSE
      // OneSignal.setLogLevel(6, 0);

      // NOTE: Update the setAppId value below with your OneSignal AppId.
      OneSignal.setAppId(oneSignalId);
      if (user?._id)
        OneSignal.setExternalUserId(user['_id'], (results) => {
          // The results will contain push and email success statuses
        

          // Push can be expected in almost every situation with a success status, but
          // as a pre-caution its good to verify it exists
          if (results['push'] && results['push'].success) {
        
          }
        });

      // const notificationOpenedHandlerCallback = (jsonData: OpenedEvent) => {
      //   console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      //   // this.router.navigate(['/', jsonData.notification.launchURL]);
      // };

      // const pushNotificationsWithUserResponseCallback = (jsonData: OpenedEvent) => {
      //   console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      //   // this.router.navigate(['/', jsonData.notification.launchURL]);
      // }

      // OneSignal.setNotificationOpenedHandler(function (jsonData) {
      //   console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      //   this.router.navigate(['/', jsonData.notification.launchURL]);
      // });

      // OneSignal.setNotificationOpenedHandler(notificationOpenedHandlerCallback.bind(this));
      // OneSignal.setNotificationOpenedHandler(pushNotificationsWithUserResponseCallback.bind(this));

      // iOS - Prompts the user for notification permissions.
      //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.

      // OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      //   console.log("User accepted notifications: " + accepted);
      // });
    } catch (error) {}

    // this.oneSignal.startInit( oneSignalId, firebaseId );
    // this.oneSignal.getIds().then(async (data) => {
    //   // localStorage.setItem('onesignal', data.userId);
    //   console.log("oneSignal.getIds().then", data)
    //   await this.userService.updateUserInfo({onesignalUserId :data.userId});
    // });
    // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
    // this.inAppNotificationHandler();
    // this.oneSignal.endInit();
  }

  showAlert(header, msg) {
    let buttons = [
      {
        text: 'OK',
        handler: () => {},
      },
    ];
    this.alertService.showAlert(header, msg, buttons);
  }

  // inAppNotificationHandler() {
  //   // Notifcation was received in general
  //   this.oneSignal.handleNotificationReceived().subscribe(data => {
  //     let msg = data.payload.body;
  //     let title = data.payload.title;
  //     let additionalData = data.payload.additionalData;
  //     //console.log("Notification Data ::: ", data);
  //     this.showAlert(title, msg);
  //   });

  //   // Notification was really clicked/opened
  //   this.oneSignal.handleNotificationOpened().subscribe(data => {
  //     let additionalData = data.notification.payload.additionalData;
  //     //console.log("/n/n/nOpened Notification data ::: ", data);
  //     this.showAlert('Notification opened', 'You already read this before');
  //   });
  // }

  routeUser() {}

  async initWacConfig() {

    return new Promise(async (resolve, reject) => {
      let config = await this.wacConfig.setConfiguration();
      if (config) {
        // console.log("ritik", this.wacConfig.configuration);
        this._title.setTitle(`${this.wacConfig.configuration.appName}`);
    
        // if (this.wacConfig.configuration.splashVideoUrl)
        //   this.showVideoSplash(this.wacConfig.configuration.splashVideoUrl, this.wacConfig.configuration.splashImageUrl);
        return resolve(true);
      }
      this.try++;
      if (this.try < 4) return resolve(await this.initWacConfig());
      else return reject(false);
    });
  }

  showVideoSplash(src, poster) {
    this.modalCtrl
      .create({
        component: SplashPage,
        componentProps: {
          src: encodeURI(src),
          poster: encodeURI(poster),
        },
        cssClass: 'fullscreenSlider',
      })
      .then((splash) => {
        splash.present();
      });
  }

  injectCode(injectCode) {
    try {
      // console.log("setting injectCode :: ", injectCode);
      if (!injectCode) return;
      if (injectCode.header) {
        for (let i = 0; i < injectCode.header.length; i++) {
          let script = this._renderer2.createElement('script');
          let scriptTextOnly = `${injectCode.header[i]
            .replace(/<script>/g, '')
            .replace(/<script/g, '')
            .replace(/><\/script>/g, '')
            .replace(/<\/script\>/g, '')}`;

          let urls = this.detectURLsInString(scriptTextOnly);
          // console.log("inside at i : ", i, injectCode.header[i], urls, scriptTextOnly );

          if (
            (urls &&
              urls.length &&
              injectCode.header[i].includes('<script ') &&
              injectCode.header[i].includes(' src=')) ||
            injectCode.header[i].includes(' src =')
          ) {
            scriptTextOnly.includes(' async ') ? (script.async = true) : '';
            script.src = urls[0].replace('"', '');
            this._renderer2.appendChild(this._document.head, script);
          } else {
            script.type = `text/javascript`;
            script.text = '\`' + scriptTextOnly + '\`';
            this._renderer2.appendChild(this._document.head, script);
          }
        }
      }
      if (injectCode.body) {
        for (let i = 0; i < injectCode.body.length; i++) {
          let script = this._renderer2.createElement('script');
          let scriptTextOnly = `${injectCode.body[i]
            .replace(/<script>/g, '')
            .replace(/<script/g, '')
            .replace(/><\/script>/g, '')
            .replace(/<\/script\>/g, '')}`;

          let urls = this.detectURLsInString(scriptTextOnly);
          // console.log("inside at i : ", i, injectCode.body[i], urls, scriptTextOnly );

          if (
            urls &&
            urls.length &&
            injectCode.body[i].includes('<script ') &&
            (injectCode.body[i].includes(' src=') ||
              injectCode.body[i].includes(' src ='))
          ) {
            scriptTextOnly.includes(' async ') ? (script.async = true) : '';
            script.src = urls[0].replace('"', '');
            this._renderer2.appendChild(this._document.body, script);
          } else {
            script.type = `text/javascript`;
            script.text = scriptTextOnly;
            this._renderer2.appendChild(this._document.body, script);
          }
        }
      }
      if (injectCode.noScript) {
        for (let i = 0; i < injectCode.noScript.length; i++) {
          let node = this._renderer2.createElement('noscript');
          let scriptTextOnly = `${injectCode.noScript[i]
            .replace(/<noscript>/g, '')
            .replace(/<noscript/g, '')
            .replace(/><\/noscript>/g, '')
            .replace(/<\/noscript\>/g, '')}`;

          node.innerHTML = scriptTextOnly;
          // console.log("noScript ::: ", node, scriptTextOnly);
          this._renderer2.appendChild(this._document.body, node);
        }
      }
    } catch (err) {
      console.error('ours injectCode :: ', err);
    }
  }

  detectURLsInString(string) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return string.match(urlRegex);
  }

  setUpHeaderElementsIdsArr() {
    // this.common.headerElementArr = [
    //   { id :'homePage', type: 'header' },
    //   { id : 'myListPage', type: 'header'},
    //   { id: 'packagePage', type: 'header' },
    //   { id: 'updateProfilePage', type: 'header'},
    //   { id: 'helpPage', type: 'header' },
    //   { id: 'termsConditionPage', type: 'header'},
    //   { id :'searchPage', type: 'header' },
    //   { id :'logoutPage', type: 'header' }
    // ];
  }

  async setPkg() {
    let info: any = localStorage.getItem('tempPayInfo');
    info = JSON.parse(info);

    // Check order status
    const result: any = await this.orderService.getOrder(info.orderId);
    if (result.data.status == 'payment_verified') {
      // setpackage or whatever

      const { pkgId, validity } = info;

      this.userService
        .setUserPackage({ pkgId, validity })
        .then(async (res) => {
          if (res == 200) {
            const toast = await this.toastController.create({
              message: 'Subscriptions is successfully purchased.',
              duration: 2000,
              position: 'bottom',
            });
            toast.present();

            let myUser = JSON.parse(localStorage.getItem('user'));
            let myDate = new Date();
            let newExpiryDate = new Date(
              new Date().setDate(myDate.getDate() + validity)
            );
            myUser['validity'] = newExpiryDate.toISOString();
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(myUser));

            localStorage.removeItem('tempPayInfo');
            this.common.goToPage('');
          }
        })
        .catch((err) => console.error(err));
    }

  }

  //logout device

  // async logOutDevice() {
  //   let getUserDevices: any = await this.userDeviceService.getUserDevices();
  //   if (!getUserDevices) return;
  //   getUserDevices.forEach(device => {
  //     if (this.platform.is('android') || this.platform.is('ios')) {
  //       if (device.userDeviceId != this.deviceInfo['uuid']) {
  //         this.userService.logout();
  //       }
  //     } else {
  //       if (device.userDeviceId != this.common.hash) {
  //         this.userService.logout();
  //       }
  //     }

  //   });

  // }
}
