import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, delay, retryWhen, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WacConfigService } from './wac-config/wac-config.service';
import { promise } from 'protractor';
import { rejects } from 'assert';

@Injectable({
  providedIn: 'root',
})
export class HttpProvider {
  httpOptions;
  handleError(error: HttpErrorResponse) {
    //console.log("our handleError", error);
    // if (error.error instanceof ErrorEvent) {
    //     console.error('An error occurred:', error.error.message);
    // } else {
    //     console.error(
    //         `Backend returned code ${error.status}, ` +
    //         `body was: ${error.error}`);
    // }
    return throwError('Something bad happened; please try again later.');
  }

  constructor(
    private httpClient: HttpClient,
    public wacConfig: WacConfigService
  ) {}

  public get(url) {
    console.log('service post', url);

    console.log("environment['baseUrl'] + url", environment['baseUrl'] + url);
    this.setAuthInHeaders();
    return this.httpClient
      .get(environment['baseUrl'] + url, this.httpOptions)
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            take(1), // retry 1 times
            delay(2000), // 1s delay between retries
            take(1),
            delay(4000),
            take(1),
            delay(8000)
          )
        ),
        catchError(this.handleError)
      );
  }

  public put(url, body) {
    console.log('service post', url, body);

    this.setAuthInHeaders();
    return this.httpClient
      .put(environment['baseUrl'] + url, JSON.stringify(body), this.httpOptions)
      .pipe(retry(2), catchError(this.handleError));
  }

  public post(url, body) {
    console.log('service post', url, body);
    this.setAuthInHeaders();
    return this.httpClient
      .post(
        environment['baseUrl'] + url,
        JSON.stringify(body),
        this.httpOptions
      )
      .pipe(retry(2), catchError(this.handleError));
  }

  public delete(url) {
    this.setAuthInHeaders();
    return this.httpClient
      .delete(environment['baseUrl'] + url, this.httpOptions)
      .pipe(retry(2), catchError(this.handleError));
  }

  public setAuthInHeaders() {
    try {
      let headerObj = {
        'Content-Type': 'application/json',
        'app-id': this.wacConfig?.appId || this.getTempWAC(),
      };
      // console.log('\n\n\nheaderObj', headerObj);
      try {
        // let user = this.userService.user;
        if (localStorage && localStorage.getItem('user')) {
          let user = JSON.parse(localStorage.getItem('user'));
          if (user && user['token']) {
            headerObj['authorization'] = user['token'];
          }
        }
      } catch (error) {}

      this.httpOptions = {
        headers: new HttpHeaders(headerObj),
      };

      return this.httpOptions;
    } catch (error) {
      console.log('\n\n\n Error in setting headers', error);
    }
  }

  getTempWAC() {
    try {
      let wac: any = localStorage.getItem('temp-wac');
      if (!wac) return;
      wac = JSON.parse(wac);
      return wac._id;
    } catch (e) {
      console.log('Error in gettin new WAC - http.service.ts', e);
      return;
    }
  }
}
