import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import { HttpProvider } from 'src/app/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class AdvtConfigsService {
  private advtConfigs: any[] = [];

  constructor(
    private http: HttpClient,
    private customHttp: HttpProvider
  ) { }

  /**
   * @description - gets the advt configs array containing fields ('vastUrl', 'interval')
   */
  public async getAdvtConfigs(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if(this.advtConfigs.length) {
          resolve(this.advtConfigs);
        } else {
          this.http
            .get(`${environment.baseUrl}/boss/advt-configs/`, {...this.customHttp.setAuthInHeaders()})
            .subscribe((response: any) => {
              if(response.status && response.status.code === 200) {
                if(response.data && response.data.length) {
                  resolve(response.data);
                  this.advtConfigs = response.data;
                } else {
                  resolve([]);
                }
              } else {
                reject(response);
              }
            }, error => {
              reject(error);
            });
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}
