import { Injectable } from '@angular/core';
import { HttpProvider } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class CredentialService {

  constructor(private http: HttpProvider) { }

  getCreds() {
    return new Promise((resolve, reject) => {
      this.http.get("/payment/credentials").subscribe(res => {
        resolve(res['data']);
      },
      error =>{
        reject(error);
      });
    });
  }
}
