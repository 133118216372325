import { ApplicationRef, ChangeDetectorRef, Injectable } from '@angular/core';
import {
  IAPError,
  IAPProduct,
  InAppPurchase2,
} from '@ionic-native/in-app-purchase-2/ngx';
import { Platform } from '@ionic/angular';
import { OrderService } from '../order/order.service';
import { PaymentService } from '../payment/payment.service';
import { UserService } from '../user/user.service';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class IapService {
  products: IAPProduct[] = [];
  productId = '';
  pkg;
  searchToast = false;
  packages;
  pkgInfo;

  constructor(
    public store: InAppPurchase2,
    private plt: Platform,
    // private ref: ChangeDetectorRef,
    public paymentService: PaymentService,
    public userService: UserService,
    public orderService: OrderService,
    public toastController: ToastController,
    public ref: ApplicationRef
  ) {}

  async initPurchase(
    pkg: any,
    productId: any = '',
    packages,
    pkgInfo
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.packages = packages;
        this.pkgInfo = pkgInfo;
        if (!productId) return;
        this.productId = productId;
        this.pkg = pkg;
        this.pkg.productId = productId;

        this.plt.ready().then(() => {
          // this.registerProducts(pkg.productId);
          this.setupListeners();

          // Get the real product information
          this.store.ready(() => {
            const currentPackageIos = this.store.products.find(
              (o) => o.id === pkg.productIdIos
            );
            const currentPackageAndroid = this.store.products.find(
              (o) => o.id === pkg.productIdAndroid
            );
            console.log(
              'Products :::',
              this.store.products,
              'currentPackageIos :: ',
              currentPackageIos,
              'android product id ::',
              currentPackageAndroid
            );
            // this.products=this.store.products
            this.purchase(
              this.plt.is('ios') ? currentPackageIos : currentPackageAndroid,
              resolve
            );
            this.ref.tick();
          });
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  registerProducts(productId) {
    let a = this.store.register({
      id: productId,
      type: this.store.CONSUMABLE,
    });
    console.log('return on register ::::', a, 'for productId : ', productId);
    this.store.refresh();

    // Register event handlers for the specific product
    this.store.when(productId).registered((product: IAPProduct) => {
      console.log('Registered: ' + JSON.stringify(product));

      // const productRegistered = this.store.get(productId);
      // console.log("fetching product after registered:: ", productRegistered);
    });

    const product = this.store.get(productId);
    console.log('fetching product outside:: ', product);
    return product;
  }

  setupListeners() {
    console.log('entering in setupListeners::::');
    try {
      this.store
        .when('product')
        .approved(async (p: IAPProduct) => {
          console.log('Payment approved ::', p);
          // Handle the product deliverable

          if (p.transaction?.id) {
            // create order with google's orderId in it - it will give us hrId
            let order;
            try {
              order = await this.orderService.inAppPurchase(
                this.pkg,
                p.transaction.id
              );
              console.log('Order created ::', order, p.transaction.id);
              // this.products = [];
              // console.log('empty products array  Order created ', this.products);
            } catch (e) {
              console.error('\nError in creating order ::', e);
            }

            // make verification call to payment with google's orderId and our hrId
            try {
              const ress = await this.paymentService.verifyInApp(
                p,
                order?.hrId
              );
              console.log('Payment verified ::', ress);
              if (ress['status'] == 'payment_verified') {
                // if yes -
                p.verify();
                this.setUserPackage(this.packages);
              }
              // this.products = [];
              // console.log(
              //   'empty products array Payment verified ',
              //   this.products
              // );
            } catch (e) {
              console.error(
                '\nError in verifying InApp purchase payment ::',
                e
              );
            }
          }

          // make call to check if order is verified
        })
        .verified((p: IAPProduct) => {
          p.finish();
          console.log('PAYMENT SUCCESS!!!!!', p);

          p = null;
          console.log('P after null PAYMENT SUCCESS!!!!!', p);
        });

      this.store.when('product').cancelled((p: IAPProduct) => {
        console.log(`cacelled by users : ${p}`);
        this.presentToast('Payment has been cancelled');
        return;
      });
      this.store.when('product').error((err: IAPError) => {
        console.log(`error occurred in purchase package : ${err}`);
        this.presentToast('Please try again, something went wrong');
      });
    } catch (e) {
      console.log('error in setupListeners', e);
      this.presentToast('Please try again, something went wrong');
    }
  }

  purchase(product: IAPProduct, cb) {
    try {
      console.log('enter in purchase function::::::::', product);
      this.store.order(product).then(
        (p) => {
          console.log('Purchase in progress ::', p);
          cb(false);
          // Purchase in progress!
        },
        (e) => {
          console.log('Failed', `Failed to purchase: ${e}`);
          cb(false);
        }
      );
    } catch (e) {
      console.log('error in purchase', e);
      cb(false);
    }
  }

  restore() {
    this.store.refresh();
  }

  // initPurchase(pkg: any) {
  //   this.productId = pkg.productId;

  //   if(!this.productId) return;

  //   // find a product in store
  //   const productDetails = this.store.get(this.productId);
  //   // if found start payment
  //   if(productDetails) {
  //     console.log('Found ::::', productDetails);
  //     return this.setupListeners();
  //   }

  //   // else register product
  //   this.registerProducts();
  //   // then start payment
  //   this.setupListeners();
  // }
  setUserPackage(packages) {
    this.userService
      .setUserPackage(packages)
      .then((res) => {
        if (res == 200) {
          this.pkgInfo['purchased'] = true;
          this.presentToast('Package is successfully purchased.');
          this.ref.tick();
          const myUser = JSON.parse(localStorage.getItem('user'));
          const myDate = new Date();
          const newExpiryDate = new Date(
            new Date().setDate(myDate.getDate() + packages.validity)
          );
          myUser['validity'] = newExpiryDate.toISOString();
          localStorage.removeItem('user');
          localStorage.setItem('user', JSON.stringify(myUser));
          localStorage.removeItem('tempPayInfo');
        }
      })
      .catch((err) => console.log(err));
  }
  async presentToast(message, pos?) {
    // console.log(message);
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      cssClass: 'authTosterMessage',
      position: pos ? pos : 'bottom',
      buttons: [
        {
          side: 'end',
          icon: 'close-outline',
        },
      ],
    });
    // toast.present();
    toast.present().then(() => {
      this.searchToast = true;
    });
    toast.onDidDismiss().then(() => {
      this.searchToast = false;
      // console.log("on searchToasts", this.searchToast);
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class TvodIapService {
  products: IAPProduct[] = [];
  productId = '';
  video;
  options;
  searchToast = false;

  constructor(
    private store: InAppPurchase2,
    private plt: Platform,
    public paymentService: PaymentService,
    public userService: UserService,
    public orderService: OrderService,
    public toastController: ToastController,
    private ref: ApplicationRef
  ) {}

  async initTvodPurchase(options, video?: any, productId: any = '') {
    return new Promise((resolve, reject) => {
      try {
        this.video = video;
        this.options = options;
        if (!productId) reject('No Product Id!');
        this.productId = productId;

        this.plt.ready().then(() => {
          // this.registerProducts(productId);
          this.setupListeners();

          // Get the real product information
          this.store.ready(() => {
            const currentPackage = this.store.products.find(
              (o) => o.id === productId
            );

            console.log(
              'Products :::',
              this.store.products,
              'currentPackage :: ',
              currentPackage
            );
            this.purchase(currentPackage);
            this.ref.tick();
          });
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  registerProducts(productId) {
    let a = this.store.register({
      id: productId,
      type: this.store.CONSUMABLE,
    });
    console.log('return on register ::::', a);
    this.store.refresh();
    // Register event handlers for the specific product
    this.store.when(productId).registered((product: IAPProduct) => {
      console.log('Registered: ' + JSON.stringify(product));

      // const productRegistered = this.store.get(productId);
      // console.log("fetching product after registered:: ", productRegistered);
    });

    const product = this.store.get(productId);
    console.log('fetching product outside:: ', product);
    return product;
  }

  setupListeners() {
    try {
      this.store
        .when('product')
        .approved(async (p: IAPProduct) => {
          console.log('Payment approved ::', p);

          if (p.transaction?.id) {
            let order;
            try {
              order = await this.orderService.tvodInAppPurchase(
                this.options,
                p.transaction.id,
                this.video
              );
              console.log('Order created ::', order);
            } catch (e) {
              console.error('\nError in creating order ::', e);
            }

            try {
              const ress = await this.paymentService.verifyInApp(
                p,
                order?.hrId,
                this.video._id
              );
              if (ress['status'] == 'payment_verified') {
                // if yes -
                p.verify();
                this.presentToast('Video is successfully purchased.');
              }
              console.log('Payment verified ::', ress);
            } catch (e) {
              console.error(
                '\nError in verifying InApp purchase payment ::',
                e
              );
            }
          }
        })
        .verified((p: IAPProduct) => {
          p.finish();
          console.log('PAYMENT SUCCESS!!!!!', p);
          p = null;
          console.log('P after null PAYMENT SUCCESS!!!!!', p);
        });
      this.store.when('product').cancelled((p: IAPProduct) => {
        console.log(`cacelled by users : ${p}`);
        this.presentToast('Payment has been cancelled');
      });
      this.store.when('product').error((err: IAPError) => {
        console.log(`error occurred in purchase package : ${err}`);
        this.presentToast('Please try again, something went wrong');
      });
    } catch (e) {
      console.log('logging the error ', e);
      this.presentToast('Please try again, something went wrong');
    }
  }

  purchase(product: IAPProduct) {
    console.log('enter in purchase function::::::::', product);
    this.store.order(product).then(
      (p) => {
        console.log('Purchase in progress ::', p);
        // Purchase in progress!
      },
      (e) => {
        console.log('Failed', `Failed to purchase: ${e}`);
      }
    );
  }

  restore() {
    this.store.refresh();
  }
  async presentToast(message, pos?) {
    // console.log(message);
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      cssClass: 'authTosterMessage',
      position: pos ? pos : 'bottom',
      buttons: [
        {
          side: 'end',
          icon: 'close-outline',
        },
      ],
    });
    // toast.present();
    toast.present().then(() => {
      this.searchToast = true;
    });
    toast.onDidDismiss().then(() => {
      this.searchToast = false;
      // console.log("on searchToasts", this.searchToast);
    });
  }
}
