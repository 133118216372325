import { Injectable } from '@angular/core';
import { HttpProvider } from '../http.service';
import { WacConfigService } from '../wac-config/wac-config.service';

@Injectable({
  providedIn: 'root'
})
export class WatchHistoryService {

  constructor(
    public _http: HttpProvider,
    public wacConfig: WacConfigService
  ) { }
  
  
  async addWatchHistoryVideo(watchHistoryVideo) {
    if(!localStorage) return;
    let user = JSON.parse(localStorage.getItem('user'))
    if(user && user._id) {
      watchHistoryVideo['date'] = new Date();
      console.log("watch history", watchHistoryVideo)
      let data = {
          userId: user._id,
          video: watchHistoryVideo,
          appId: this.wacConfig.configuration._id,
          videoId: watchHistoryVideo._id,
          status: 1
  
      }
      return new Promise((resolve, reject) => {
          this._http.post("/ott/watch-history", data).subscribe(res => {
              resolve(res);
          },error => {
            reject(error);
          });
      });
    }

  }

  deleteWatchHistory() {
    if(!localStorage) return;
    let user = JSON.parse(localStorage.getItem('user'))
    let data ={
      status : -1
    }
    return new Promise((resolve, reject) => {
        this._http.put("/ott/watch-history/" + user['_id'], data).subscribe(res => {
            resolve(res);
        }, error => {
          reject(error);
        })
    })
}

async getWatchHistory() {
  if(!localStorage) return;
  let user = JSON.parse(localStorage.getItem('user'));
  if (user)
      return new Promise((resolve, reject) => {
          this._http.get("/ott/watch-history/" + user['_id']).subscribe(res => {
              resolve(res);
          }, error => {
            reject(error);
          });
      });
}

async deleteSingleWatchHistory(video){
  if(!localStorage) return;
    let user = JSON.parse(localStorage.getItem('user'))
    let data ={
      status : -1
    }
    return new Promise((resolve, reject) => {
        this._http.put("/ott/watch-history/" + user['_id'] + "/video/" +video._id, data).subscribe(res => {
            resolve(res);
        }, error => {
          reject(error);
        })
    })
}


}
