import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
// import { HttpProvider } from '../http.service';
import { SeoService } from '../seo/seo.service';

@Injectable({
  providedIn: 'root',
})
export class WacConfigService {
  configuration: any;
  appId;
  splashVideoCompleted: boolean = false;
  public domainName;
  constructor(
    private httpClient: HttpClient,
    // private httpProvider: HttpProvider,
    private seoService: SeoService,
    private platform: Platform,
    @Optional() @Inject('X_FORWARDED_HOST') private host: any
  ) {}

  /**
   * @description - gets the wac config
   * @param id - wacId
   * @param fresh if true then loads the new wac-config else loads the previously loaded one
   * @returns
   */
  getConfig(id: string, fresh = false) {
    return new Promise((resolve, reject) => {
      let wac = localStorage.getItem('wac');
      // console.log('wac ::', wac)
      if (wac && !fresh) resolve(JSON.parse(wac));
      // if (this.configuration && !fresh) {
      //   localStorage.setItem('wac', JSON.stringify(this.configuration));
      //   resolve(this.configuration);
      // }
      this.httpClient
        .get(environment['baseUrl'] + '/wac-config/' + id)
        .subscribe((res) => {
          this.configuration = Array.isArray(res['data'])
            ? res['data'][0]
            : res['data'];
          localStorage.setItem('wac', JSON.stringify(this.configuration));
          resolve(this.configuration);
        });
    });
  }
  /**
   * @description - gets the wac config
   * @param domain -
   * @param _new if true then loads the new wac-config else loads the previously loaded one
   * @returns
   */
  getConfigByDomain(domain: string, fresh = false) {
    return new Promise((resolve, reject) => {
      // const wac = JSON.parse(localStorage.getItem('wac'));

      // if(wac) {
      //   console.log('getting the wac from the local storage');
      //   resolve(wac);
      // } else {
      //   if (this.configuration && this.configuration._id && !fresh) {
      //     localStorage.setItem('wac', JSON.stringify(this.configuration));
      //     resolve(this.configuration);
      //   }
      // }
      // localStorage.setItem('wac', JSON.stringify(this.configuration));
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders({ 'x-consumer': `web-app` }), 
      };
      if (this.configuration && this.configuration._id && !fresh) {
        resolve(this.configuration);
      } else {
        this.httpClient
          .get(environment['baseUrl'] + '/wac-config/?domain=' + domain, requestOptions)
          .subscribe(
            (res) => {
              this.configuration = Array.isArray(res['data'])
                ? res['data'][0]
                : res['data'];

              localStorage.setItem('temp-wac', JSON.stringify(res['data']));
              resolve(res['data']);
            },
            (err) => reject(err)
          );
      }
    });
  }
  /**
   * @description - gets the wac-config
   * @param fresh - if true then loads fresh wac-config
   * @returns
   */
  loadingWac = false;
  async setConfiguration(fresh = false) {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.configuration?._id || this.loadingWac) return resolve(true);
        this.loadingWac = true;
        console.log('this.configuration 1', this.configuration);

        if (
          (this.platform.is('android') || this.platform.is('ios')) &&
          !this.platform.is('mobileweb')
        ) {
          console.log('loading not by domain');

          this.configuration = await this.getConfig(
            environment['appId'],
            fresh
          );
        } else {
          console.log('loading by domain');
          try {
            this.domainName = window.location.hostname;
          } catch (error) {
            this.domainName = this.host;
          }

          this.configuration = await this.getConfigByDomain(
            this.domainName,
            fresh
          );
          this.loadingWac = false;
        }

        if (this.configuration) {
          this.appId = this.configuration._id;

          try {
            if (
              this.configuration.slider &&
              this.configuration.slider.slider &&
              this.configuration.slider.slider.backgroundImage
            ) {
              if (
                !this.configuration.slider.slider.backgroundImage.startsWith(
                  'https://mott-img.b-cdn.net/'
                )
              ) {
                this.configuration.slider.slider.backgroundImage = `https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-${this.seoService.setImageWidth()}/${
                  this.configuration.slider.slider.backgroundImage
                }`;
              }
            }
            if (
              this.configuration.slider &&
              this.configuration.slider.slider &&
              this.configuration.slider.slider.slides
            ) {
              for (
                let i = 0;
                i < this.configuration.slider.slider.slides.length;
                i++
              ) {
                if (
                  !this.configuration.slider.slider.slides[i].img.startsWith(
                    'https://mott-img.b-cdn.net/'
                  )
                ) {
                  this.configuration.slider.slider.slides[
                    i
                  ].img = `https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-${this.seoService.setImageWidth()}/${
                    this.configuration.slider.slider.slides[i].img
                  }`;
                }
              }
            }
          } catch (err) {
            console.log('ours :: ', err);
          }

          if (
            this.configuration.colorPallete &&
            this.configuration.colorPallete.theme
          ) {
          }
          return resolve(true);
        }
        this.loadingWac = false;

        return resolve(false);
      } catch (error) {
        this.loadingWac = false;

        reject(error);
      }
    });
  }
}
