import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DummyService {

  dummyData = [
    {
      img: 'assets/imgs/image1.jpeg',
      name: 'Stranger Things',
    },
    {
      img: 'assets/imgs/image2.jpeg',
      name: 'Lucifer',
    },
    {
      img: 'assets/imgs/image3.jpeg',
      name: 'Dangal',
    },
    {
      img: 'assets/imgs/image4.jpeg',
      name: 'Kabir Singh',
    },
    {
      img: 'assets/imgs/image5.jpeg',
      name: 'Phanton',
    },
    {
      img: 'assets/imgs/image6.jpeg',
      name: 'Saaho',
    },
    {
      img: 'assets/imgs/image7.jpeg',
      name: 'Bard of Blood',
    },
    {
      img: 'assets/imgs/image8.jpeg',
      name: 'Panipat',
    },
    {
      img: 'assets/imgs/image9.jpeg',
      name: 'Raees',
    },
    {
      img: 'assets/imgs/image10.jpeg',
      name: 'Bahubali',
    },
    {
      img: 'assets/imgs/image11.jpeg',
      name: 'Another Life',
    },
    {
      img: 'assets/imgs/image12.jpeg',
      name: 'THE 100',
    },
    {
      img: 'assets/imgs/image13.jpeg',
      name: 'The Vampire Diaries',
    },
    {
      img: 'assets/imgs/image14.jpeg',
      name: 'Titans',
    },
    {
      img: 'assets/imgs/image15.jpeg',
      name: 'Lock & Key',
    },
    {
      img: 'assets/imgs/image16.jpeg',
      name: 'The Witcher',
    },
  ];
  dummy = {
    _id: "playlist123",
    name: "Top 10",
    showHorizontal: false,
    showName: true,
    numberOfCardsForApp: 3,
    numberOfCardsForWeb:6,
    status: 1,
    videos: [
      {
        _id: "videos12",
        status: 1,
        horizontalThumbnail: "",
        verticalThumbnail: "",
        title: "Sanam Re",
        thumbnails: [
          "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-2.png",
          "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-5.png"
      ],
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos13",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos15",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos16",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos17",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos18",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos19",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos20",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos21",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos22",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      }
    ]
  };
  d1 = new Date('11/23/2021, 6:31:15 PM');
  d2 = new Date('11/22/2021, 6:31:16 PM');
  d3 = new Date('11/21/2021, 6:31:16 PM');
  d4 = new Date('11/21/2021, 6:31:16 PM');
  d5 = new Date('11/21/2021, 6:31:16 PM');

  watchHistory = [
    {
        "formats": [
            240,
            480,
            720
        ],
        "thumbnails": [
            "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-2.png",
            "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-5.png"
        ],
        "tags": [
            "top singer 1",
            "vertical banner"
        ],
        "bannerIndex": 4,
        "enhance": true,
        "layered": false,
        "timelineThumbs": false,
        "thumbnailTimestamps": [
            2000,
            5000
        ],
        "accessType": 0,
        "_id": "60361ed95c9d4712b5423271",
        "watchedDate": this.d1,
        "appId": "5f1ee37e8f2cc36a7ca3313d",
        "sourceUrl": "https://mogi-mdt-test.s3.ap-south-1.amazonaws.com/5f1ee37e8f2cc36a7ca3313d/NaL2_3gWcrE_output.mp4",
        "status": "success",
        "fileType": "video",
        "description": "Flowers Top Singer is a renowned Music Reality Show for young Malayali singers under the age of 15 from all around the world. Legendary musicians in the Malayalam film industry, like M.G. Sreekumar, M. Jayachandran, Deepak Dev, Sithara, Anuradha Sriram, Madhu Balakrishnan, etc make the show a fun entertaining musical extravaganza. Famous performers of Flowers Top Singer season 1 include Seethalakshmi, Ananya, Aditi, Rithuraj, Krishnadiya, etc. ",
        "title": "Top Singer 1 | Ep# 484 ( Part - C)",
        "type": "hls",
        "createdAt": "2021-02-24T09:39:37.675Z",
        "accessUrl": "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/playlist.m3u8",
        "bitRate": 3443204,
        "duration": 952.871,
        "size": 410116206,
        "timeTake": 368.394,
        "contentType": "free",
        "horizontalThumbnail": "https://image2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/q80,ptrue,ttrue/ts_484_C_h.webp",
        "verticalThumbnail": "https://image2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/q80,ptrue,ttrue/ts_484_C_v.webp"
    },
    {
      "formats": [
          240,
          480,
          720
      ],
      "thumbnails": [
          "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-2.png",
          "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-5.png"
      ],
      "tags": [
          "top singer 1",
          "vertical banner"
      ],
      "bannerIndex": 4,
      "enhance": true,
      "layered": false,
      "timelineThumbs": false,
      "thumbnailTimestamps": [
          2000,
          5000
      ],
      "accessType": 0,
      "_id": "60361ed95c9d4712b5423271",
      "watchedDate": this.d4,
      "appId": "5f1ee37e8f2cc36a7ca3313d",
      "sourceUrl": "https://mogi-mdt-test.s3.ap-south-1.amazonaws.com/5f1ee37e8f2cc36a7ca3313d/NaL2_3gWcrE_output.mp4",
      "status": "success",
      "fileType": "video",
      "description": "Flowers Top Singer is a renowned Music Reality Show for young Malayali singers under the age of 15 from all around the world. Legendary musicians in the Malayalam film industry, like M.G. Sreekumar, M. Jayachandran, Deepak Dev, Sithara, Anuradha Sriram, Madhu Balakrishnan, etc make the show a fun entertaining musical extravaganza. Famous performers of Flowers Top Singer season 1 include Seethalakshmi, Ananya, Aditi, Rithuraj, Krishnadiya, etc. ",
      "title": "Top Singer 4 | Ep# 484 ( Part - C)",
      "type": "hls",
      "createdAt": "2021-02-24T09:39:37.675Z",
      "accessUrl": "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/playlist.m3u8",
      "bitRate": 3443204,
      "duration": 952.871,
      "size": 410116206,
      "timeTake": 368.394,
      "contentType": "free",
      "horizontalThumbnail": "https://image2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/q80,ptrue,ttrue/ts_484_C_h.webp",
      "verticalThumbnail": "https://image2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/q80,ptrue,ttrue/ts_484_C_v.webp"
  },
    {
      "formats": [
          240,
          480,
          720
      ],
      "thumbnails": [
          "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-2.png",
          "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-5.png"
      ],
      "tags": [
          "top singer 1",
          "vertical banner"
      ],
      "bannerIndex": 4,
      "enhance": true,
      "layered": false,
      "timelineThumbs": false,
      "thumbnailTimestamps": [
          2000,
          5000
      ],
      "accessType": 0,
      "_id": "60361ed95c9d4712b5423271",
      "watchedDate": this.d2,
      "appId": "5f1ee37e8f2cc36a7ca3313d",
      "sourceUrl": "https://mogi-mdt-test.s3.ap-south-1.amazonaws.com/5f1ee37e8f2cc36a7ca3313d/NaL2_3gWcrE_output.mp4",
      "status": "success",
      "fileType": "video",
      "description": "Flowers Top Singer is a renowned Music Reality Show for young Malayali singers under the age of 15 from all around the world. Legendary musicians in the Malayalam film industry, like M.G. Sreekumar, M. Jayachandran, Deepak Dev, Sithara, Anuradha Sriram, Madhu Balakrishnan, etc make the show a fun entertaining musical extravaganza. Famous performers of Flowers Top Singer season 1 include Seethalakshmi, Ananya, Aditi, Rithuraj, Krishnadiya, etc. ",
      "title": "Top Singer 2 | Ep# 484 ( Part - C)",
      "type": "hls",
      "createdAt": "2021-02-24T09:39:37.675Z",
      "accessUrl": "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/playlist.m3u8",
      "bitRate": 3443204,
      "duration": 952.871,
      "size": 410116206,
      "timeTake": 368.394,
      "contentType": "free",
      "horizontalThumbnail": "https://image2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/q80,ptrue,ttrue/ts_484_C_h.webp",
      "verticalThumbnail": "https://image2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/q80,ptrue,ttrue/ts_484_C_v.webp"
  },
  {
    "formats": [
        240,
        480,
        720
    ],
    "thumbnails": [
        "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-2.png",
        "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-5.png"
    ],
    "tags": [
        "top singer 1",
        "vertical banner"
    ],
    "bannerIndex": 4,
    "enhance": true,
    "layered": false,
    "timelineThumbs": false,
    "thumbnailTimestamps": [
        2000,
        5000
    ],
    "accessType": 0,
    "_id": "60361ed95c9d4712b5423271",
    "watchedDate": this.d5,
    "appId": "5f1ee37e8f2cc36a7ca3313d",
    "sourceUrl": "https://mogi-mdt-test.s3.ap-south-1.amazonaws.com/5f1ee37e8f2cc36a7ca3313d/NaL2_3gWcrE_output.mp4",
    "status": "success",
    "fileType": "video",
    "description": "Flowers Top Singer is a renowned Music Reality Show for young Malayali singers under the age of 15 from all around the world. Legendary musicians in the Malayalam film industry, like M.G. Sreekumar, M. Jayachandran, Deepak Dev, Sithara, Anuradha Sriram, Madhu Balakrishnan, etc make the show a fun entertaining musical extravaganza. Famous performers of Flowers Top Singer season 1 include Seethalakshmi, Ananya, Aditi, Rithuraj, Krishnadiya, etc. ",
    "title": "Top Singer 5 | Ep# 484 ( Part - C)",
    "type": "hls",
    "createdAt": "2021-02-24T09:39:37.675Z",
    "accessUrl": "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/playlist.m3u8",
    "bitRate": 3443204,
    "duration": 952.871,
    "size": 410116206,
    "timeTake": 368.394,
    "contentType": "free",
    "horizontalThumbnail": "https://image2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/q80,ptrue,ttrue/ts_484_C_h.webp",
    "verticalThumbnail": "https://image2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/q80,ptrue,ttrue/ts_484_C_v.webp"
},
  {
    "formats": [
        240,
        480,
        720
    ],
    "thumbnails": [
        "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-2.png",
        "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/thumbnails/tn-5.png"
    ],
    "tags": [
        "top singer 1",
        "vertical banner"
    ],
    "bannerIndex": 4,
    "enhance": true,
    "layered": false,
    "timelineThumbs": false,
    "thumbnailTimestamps": [
        2000,
        5000
    ],
    "accessType": 0,
    "_id": "60361ed95c9d4712b5423271",
    "watchedDate": this.d3,
    "appId": "5f1ee37e8f2cc36a7ca3313d",
    "sourceUrl": "https://mogi-mdt-test.s3.ap-south-1.amazonaws.com/5f1ee37e8f2cc36a7ca3313d/NaL2_3gWcrE_output.mp4",
    "status": "success",
    "fileType": "video",
    "description": "Flowers Top Singer is a renowned Music Reality Show for young Malayali singers under the age of 15 from all around the world. Legendary musicians in the Malayalam film industry, like M.G. Sreekumar, M. Jayachandran, Deepak Dev, Sithara, Anuradha Sriram, Madhu Balakrishnan, etc make the show a fun entertaining musical extravaganza. Famous performers of Flowers Top Singer season 1 include Seethalakshmi, Ananya, Aditi, Rithuraj, Krishnadiya, etc. ",
    "title": "Top Singer 3 | Ep# 484 ( Part - C)",
    "type": "hls",
    "createdAt": "2021-02-24T09:39:37.675Z",
    "accessUrl": "https://cdn2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/1614159577678_/playlist.m3u8",
    "bitRate": 3443204,
    "duration": 952.871,
    "size": 410116206,
    "timeTake": 368.394,
    "contentType": "free",
    "horizontalThumbnail": "https://image2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/q80,ptrue,ttrue/ts_484_C_h.webp",
    "verticalThumbnail": "https://image2.mogiio.com/5f1ee37e8f2cc36a7ca3313d/q80,ptrue,ttrue/ts_484_C_v.webp"
}
];

  comingSoon = {
    _id: "playlist123",
    name: "Top 10",
    showHorizontal: true,
    showName: true,
    numberOfCardsForApp: 2,
    numberOfCardsForWeb:6,
    status: 1,
    videos: [
      {
        _id: "videos12",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos13",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      }
    ]
  }

  popular = {
    _id: "playlist123",
    name: "Top 10",
    showHorizontal: false,
    showName: true,
    numberOfCardsForApp: 3,
    numberOfCardsForWeb:6,
    status: 1,
    videos: [
      {
        _id: "videos12",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos13",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos12",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos13",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      }
    ]
  }

  trending = {
    _id: "playlist123",
    name: "Top 10",
    showHorizontal: false,
    showName: true,
    numberOfCardsForApp: 3,
    numberOfCardsForWeb:6,
    status: 1,
    videos: [
      {
        _id: "videos12",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos13",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos12",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos13",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      },
      {
        _id: "videos14",
        status: 1,
        horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
        verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
        title: "Sanam Re",
        duration: 345,
        description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
      }
    ]
  }

  slider = {
    backgroundImage : "assets/imgs/back.jpg",
    slides: [
      {
        img: "assets/imgs/Android-Or-iOS.jpg",
        title: "Watch on any device",
        des: "Stream on your phone & tablet. Android/iOS"
      },
      {
        img: "assets/imgs/slide_2.png",
        title: "Download and ready of steam",
        des: "Save your data by world class compression, multi bitrate support"
      },
      {
        img: "assets/imgs/slide_3.png",
        title: "No annoying contact",
        des: "Stream on your phone & tablet. Android/iOS"
      },
      {
        img: "",
        title: "Unlimited entertaiment, on low price",
        des: "Stream as much as you want, no extra fees."
      }
    ]
  }

  channels = [
    {
      _id: "abc1234",
      name: "Movies",
    },
    {
      _id: "abc1235",
      name: "songs",
    },
    {
      _id: "abc1236",
      name: "SciFi",
    }
  ]

  searchVideos = [
    {
      _id: "videos12",
      status: 1,
      horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
      verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
      title: "Sanam Re",
      duration: 345,
      description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
    },
    {
      _id: "videos13",
      status: 1,
      horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
      verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
      title: "Sanam Re",
      duration: 345,
      description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
    },
    {
      _id: "videos14",
      status: 1,
      horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
      verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
      title: "Sanam Re",
      duration: 345,
      description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
    }
  ]

  playlists = [
    {
      _id: "playlist123",
      name: "Vertical Banners",
      showHorizontal: false,
      showName: false,
      autoMove: true,
      numberOfCardsForApp: 1,
      numberOfCardsForWeb:1,
      status: 1,
      videos: [
        {
          _id: "videos12",
          status: 1,
          thumbnails: [],
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos13",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos14",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        }
      ]
    },
    {
      _id: "playlist123",
      name: "Top 10",
      showHorizontal: false,
      showName: true,
      numberOfCardsForApp: 3,
      numberOfCardsForWeb:6,
      status: 1,
      videos: [
        {
          _id: "videos12",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos13",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos14",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos12",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos13",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos14",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos12",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos13",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos14",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        }
      ]
    },
    {
      _id: "playlist124",
      name: "Romantic",
      showHorizontal: true,
      showName: true,
      numberOfCardsForApp: 2,
      numberOfCardsForWeb:3,
      status: 1,
      videos: [
        {
          _id: "videos12",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos13",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        },
        {
          _id: "videos14",
          status: 1,
          horizontalThumbnail: "https://i.ytimg.com/vi/fvQZkpnb764/maxresdefault.jpg",
          verticalThumbnail: "https://upload.wikimedia.org/wikipedia/en/9/9f/Sanam_Re_movie_poster.jpg",
          title: "Sanam Re",
          duration: 345,
          description: "Akash and Shruti, two classmates in love, are separated due to unforeseen circumstances. Years later, they get a second chance at their relationship when they meet in Canada."
        }
      ]
    }
  ]

  packages = [
    {
      _id: "pkg123",
      name: "Standard",
      status: 1,
      others: {
        img: "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832__480.jpg",
        price: 0,
        currency: "INR",
        days: 60
      }
    },
    {
      _id: "pkg124",
      name: "Premium",
      status: 1,
      others: {
        img: "https://cdn.pixabay.com/photo/2018/01/14/23/12/nature-3082832__480.jpg",
        price: 50,
        currency: "INR",
        days: 60
      }
    }
  ]
  

  constructor() { }
}
