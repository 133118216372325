<!-- Skeleton screen for Home-->
<div
  [ngClass]="platform.is('ios') ? 'ios-main-div' : ''"
  *ngIf="common.tab =='home'"
>
  <ion-list>
    <ion-item lines="none">
      <ion-avatar slot="start" *ngIf="platform.width()<900">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label style="margin-left: 10px" *ngFor="let _ of [].constructor(3)">
        <h3>
          <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
        </h3>
      </ion-label>
    </ion-item>

    <ion-item lines="none">
      <ion-thumbnail slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>

    <ion-item lines="none">
      <ion-thumbnail class="secondCard" slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
      <ion-thumbnail class="secondCard" slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>

    <ion-item lines="none">
      <ion-label>
        <ion-thumbnail *ngFor="let _ of [].constructor(3)" slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-thumbnail>
      </ion-label>
    </ion-item>
  </ion-list>
</div>

<!-- //Skeleton for Player Page  -->
<div *ngIf="common.tab =='player'">
  <ion-list>
    <ion-item *ngIf="platform.width()<900" lines="none">
      <ion-thumbnail
        [ngClass]="this.platform.is('ios')?'player-screen-ios': 'player-screen'"
        slot="start"
      >
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>

    <ion-item *ngIf="platform.width()>900" lines="none">
      <ion-thumbnail class="player-screen-web" slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>

    <ion-item lines="none">
      <ion-thumbnail
        *ngFor="let _ of [].constructor(3)"
        class="search-screen"
        slot="start"
      >
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>

    <ion-item lines="none">
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
        </h3>
      </ion-label>
    </ion-item>

    <ion-item lines="none">
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
        </h3>
      </ion-label>
    </ion-item>

    <ion-item lines="none">
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </h3>
      </ion-label>
    </ion-item>

    <ion-item lines="none">
      <ion-thumbnail
        *ngFor="let _ of [].constructor(3)"
        class="search-screen"
        slot="start"
      >
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>

    <ion-item lines="none">
      <ion-thumbnail
        *ngFor="let _ of [].constructor(2)"
        class="playerAd-screen"
        slot="start"
      >
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>

    <ion-item *ngFor="let _ of [].constructor(2)" lines="none">
      <ion-thumbnail
        *ngFor="let _ of [].constructor(3)"
        class="search-screen"
        slot="start"
      >
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>
  </ion-list>
</div>

<!-- //Skeleton for MyList  -->
<div *ngIf="common.tab == 'mylist'">
  <div *ngFor="let _ of [].constructor(5)">
    <ion-list *ngIf="platform.width()<900">
      <ion-item lines="none">
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </h3>
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-thumbnail
          *ngFor="let _ of [].constructor(3)"
          class="myList-screen"
          slot="start"
        >
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-thumbnail>
      </ion-item>
    </ion-list>

    <!-- for web App  -->
    <ion-list *ngIf="platform.width()>900">
      <ion-item lines="none">
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </h3>
        </ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-thumbnail
          *ngFor="let _ of [].constructor(8)"
          class="mylist-screen-web"
          slot="start"
        >
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-thumbnail>
      </ion-item>
    </ion-list>
  </div>
</div>

<!-- //Skeleton for watchHistory  -->
<div *ngIf="common.tab == 'watchHistory'">
  <ion-list *ngFor="let _ of [].constructor(6)">
    <ion-item lines="none">
      <ion-thumbnail class="watchHistory-screen" slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>
  </ion-list>
</div>

<!-- //Skeleton for PackagePage  -->
<div *ngIf="common.tab == 'packages'">
  <ion-list>
    <ion-item lines="none">
      <ion-thumbnail class="packages-screen" slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>
  </ion-list>
</div>

<!-- //Skeleton for orders Screen  -->
<div *ngIf="common.tab == 'orders'">
  <ion-list *ngFor="let _ of [].constructor(10)">
    <ion-item lines="none">
      <ion-thumbnail class="orders-screen" slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>
  </ion-list>
</div>

<!-- //Skeleton for Search page  -->
<div *ngIf="common.tab == 'search'">
  <ion-list *ngFor="let _ of [].constructor(10)">
    <ion-item lines="none">
      <ion-thumbnail class="search-screen-new" slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
    </ion-item>
  </ion-list>
</div>
