import {Injectable} from '@angular/core';
import {HttpProvider} from '../http.service';
// import { NativeStorage } from '@ionic-native/native-storage/ngx';
import {NativeStorage} from '@awesome-cordova-plugins/native-storage/ngx';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {WacConfigService} from '../wac-config/wac-config.service';
import OneSignal from 'onesignal-cordova-plugin';
import {Router} from '@angular/router';
import {NavController} from '@ionic/angular';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userLoggedIn = new Subject<void>();

  user: any = {};
  appId;
  tempUser = {};
  currentPkgId: any[];
  uploadedImages: any[];
  _http: any;

  constructor(
    private httpClient: HttpClient,
    private wacConfigService: WacConfigService,
    private nativeStorage: NativeStorage,
    public http: HttpClient,
    private navCtrl: NavController,
    private router: Router,
    public wacConfig: WacConfigService,
    private httpProvider: HttpProvider
  ) {
    this._http = new HttpProvider(httpClient, wacConfigService);
  }

  create(data) {
    return new Promise((resolve, reject) => {
      this._http.post('/ott/users', data).subscribe((res) => {
        console.log(res['status'].code);
        console.log('register..........', data);
        if (res['status'].code == 200) {
          this.saveUser(res['data']);
          this.user = res['data'];
        }
        resolve(res['status']);
      });
    });
  }

  login(data) {
    return new Promise((resolve, reject) => {
      this._http.post('/ott/users/login', data).subscribe((res) => {
        console.log(res['status'].code);
        if (res['status'].code == 200) {
          this.saveUser(res['data']);
          this.user = res['data'];
          this.userLoggedIn.next();
        }
        resolve(res['status']);
      });
    });
  }

  encryptedLogin(encryptedStr) {
    return new Promise((resolve, reject) => {
      this._http.post('/ott/users/login/v2?t=' + encryptedStr, {t: encryptedStr}).subscribe((res) => {
          console.log('\n\n\n\n\n\nspecial login \n\n\n\n\n\n', res);
          if (res['status'].code == 200) {
            this.saveUser(res['data']);
            this.user = res['data'];
          }
          resolve(res['status']);
        },
        err => {
          console.log('\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n', err)
        });
    });
  }

  regOrLogin(username) {
    let data = {
      username: username,
    };
    return new Promise((resolve, reject) => {
      this._http.post('/ott/users/v2/auth', data).subscribe((res) => {
        if (res['status'].code == 200 || res['status'].code == 201) {
          // this.saveUser(res["data"]);
          // if(username.includes("@")) {
          //     this.user = res["data"];
          // }
          // else {
          this.tempUser = res['data'];
          this.user = res['data'];
          // this.user['_id'] = res["data"]._id ;
          this.saveUser(this.user);
          // }
        }
        resolve(res['status']);
      });
    });
  }

  updateUserInfo(data) {
    return new Promise((resolve, reject) => {
      this._http.put('/ott/users/auth', data).subscribe(
        (res) => {
          console.log(res['status'].code);
          if (res['status'].code == 200 || res['status'].code == 201) {
            this.saveUser(res['data']);
            this.user = res['data'];
            console.log(this.user);
          }
          resolve(res['status']);
        },
        (err) => reject(err)
      );
    });
  }

  async resendauth(username, alt=false) {
    if (!localStorage) return;

    let data = {
      username: username,
    };
    let user = JSON.parse(localStorage.getItem('user'));
    let url = '/ott/users/auth/' + user['_id'] + '/resendOtp';
    if(alt) url += '?alternate=true';
    return new Promise((resolve, reject) => {
      this._http
        .post(url, data)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  updatePassword(oldPwd, newPed) {
    let data = {oldPassword: oldPwd, newPassword: newPed};
    return new Promise((resolve, reject) => {
      this._http
        .put('/ott/users/' + this.user['_id'] + '?password=true', data)
        .subscribe((res) => {
          console.log(res['status'].code);
          resolve(res);
        });
    });
  }

  getLocalUser() {
    try {
      if (this.user['_id'] && this.user['verified']) return this.user;
      if (localStorage.getItem('user')) {
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user) {
          console.log(
            'this.user ::',
            this.user['_id'],
            this.user['verified'],
            this.user['type']
          );
          return this.user;
        }
      }
      return null;
    } catch (error) {
      console.log('our', error);
      return null;
    }
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.user = null;
    this.tempUser = null;
    setTimeout(() => localStorage.removeItem('user'), 2000);
    return;
  }

  saveUser(user) {
    console.log('saving user :: ', user);
    if (user && user.token) {
      localStorage.setItem('token', user.token);
    }
    return localStorage.setItem('user', JSON.stringify(user));
  }

  async resendOTP() {
    let userObj = await this.getLocalUser();
    if (userObj) {
      let data = {
        _id: this.user['_id'],
        email: userObj['email'],
      };
      return new Promise((resolve, reject) => {
        this._http
          .post('/ott/users/' + this.user['_id'] + '/resendOtp', data)
          .subscribe((res) => {
            resolve(res);
          });
      });
    }
  }

  async verify(data) {
    let user = await this.getLocalUser();
    if (user) {
      return new Promise((resolve, reject) => {
        this._http
          .post('/ott/users/' + user['_id'] + '/verifyOtp', data)
          .subscribe((res) => {
            if (res['status'].code == 200 || res['status'].code == 201) {
              this.user = res['data'];
              this.user['verified'] = true;
              this.saveUser(this.user);
              // try {
              // OneSignal.setExternalUserId(this.user['_id']);

              // } catch (error) {
              //     console.log("our error ",error)
              // }
            }
            resolve(res);
          });
      });
    }
  }

  setUserPackage(packages) {
    try {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        return new Promise((resolve, reject) => {
          this._http
            .post('/ott/users/' + user['_id'] + '/packages', {
              packages: packages,
            })
            .subscribe((res) => {
              console.log('res', res['status'].code, res);
              resolve(res['status'].code);
              this.userPackages = [];
            });
        });
      }
    } catch (error) {
      console.log('our', error);
    }
  }

  userPackages = [];

  getUserValidPackages() {
    return new Promise((resolve, reject) => {
      try {
        // if (this.userPackages.length > 0) resolve(this.userPackages);

        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user['_id']) {
          this._http
            .get('/ott/users/' + user['_id'] + '/packages')
            .subscribe((res) => {
              if(!(res && res?.data?.length)) resolve([]);
              if(!res.data[0]?.packages) resolve([]);
              let d = res.data[0]?.packages || [];

              d = d.filter(a => new Date(a?.expiresAt) >= new Date());
              this.userPackages = [...d];
              resolve(d);

            });

        } else {
          reject('user not available');
        }
        // console.log("respackage", this.userPackages);


      } catch (error) {
        reject(error);
      }
    });
  }

  async getUserPackages() {
    if (!localStorage) return;
    let user = JSON.parse(localStorage.getItem('user'));
    if (user)
      return new Promise((resolve, reject) => {
        this._http
          .get('/ott/users/' + user['_id'] + '/packages')
          .subscribe((res) => {
            console.log(res['status'].code);
            resolve(res['data']);
          });
      });
  }

  async giveRating(ratingValue, id) {
    if (!localStorage)
      return;
    let localUser = this.getLocalUser();

    if (
      localUser &&
      (typeof localUser['verified'] != 'boolean' ||
        localUser['verified'] == false)
    ) {
      console.log("routing",)
      return this.navCtrl.navigateRoot(['/slider' + this.router.url]);

    }
    console.log("routings",)


    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      let userlocalrating;
      if (localStorage.getItem(user['_id'] + '_' + id)) {
        userlocalrating = JSON.parse(
          localStorage.getItem(user['_id'] + '_' + id)
        );
        ratingValue = 5 - userlocalrating + ratingValue;
      }
      let data = {rating: ratingValue, userId: user['_id']};
      localStorage.setItem(user['_id'] + '_' + id, ratingValue);
      return new Promise((resolve, reject) => {
        this._http.put('/drives/' + id + '/rating', data).subscribe((res) => {
          console.log(res['status'].code);
          resolve(res);
        });
      });
    }
  }

  async updateUserName(updatedName) {
    let data = {
      name: updatedName,
    };
    return new Promise((resolve, reject) => {
      this._http
        .put('/ott/users/' + this.user['_id'], data)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  async uploadUserImg() {
    let data = {
      img: this.uploadedImages[0],
    };
    let user = await this.getLocalUser();
    if (user) {
      return new Promise((resolve, reject) => {
        this._http
          .put('/ott/users/' + this.user['_id'] + '?img=true', data)
          .subscribe((res) => {
            resolve(res);
          });
      });
    }
  }

  generateCode(data) {
    return new Promise((resolve, reject) => {
      if (typeof data.username == 'string') {
        this._http
          .post('/ott/users/resetPswd?resetPassCode=true', data)
          .subscribe((res) => {
            resolve(res);
          });
      } else {
        this._http
          .post(
            '/ott/users/resetPswd?resetPassCode=true&verifyByMobile=true',
            data
          )
          .subscribe((res) => {
            resolve(res);
          });
      }
    });
  }

  matchCode(data) {
    return new Promise((resolve, reject) => {
      this._http
        .post('/ott/users/resetPswd?verifyCode=true', data)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  changePassword(data) {
    return new Promise((resolve, reject) => {
      this._http
        .post('/ott/users/resetPswd?resetPassword=true', data)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  public async isAuthorized(data, type): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .post(`/ott/users/v2/auth/?type=${type}`, data)
        .subscribe((res) => {
          console.log(res);
          if (res['status']['code'] === 200 || res['status'].code === 201) {
            this.tempUser = res['data'];
            this.user = res['data'];
            this.saveUser(this.user);
          }
          resolve(res['status']);
        });
    });
  }

  async generateQrCode() {
    return new Promise(async (resolve, reject) => {
      try {
        this._http.post('/ott/users/auth/qrcode', {}).subscribe((res) => {
          // if (res["status"].code == 200 || res["status"].code == 201) {
          resolve(res['data']);
          // }
          // else resolve({});
        });
      } catch (err) {
        console.log('ours generateQrCode : ', err);
        reject(err);
      }
    });
  }

  async verifyQrCode(qrCode, data) {
    return new Promise(async (resolve, reject) => {
      try {
        this._http
          .put('/ott/users/v2/auth/qrcode/' + qrCode, data)
          .subscribe((res) => {
            // if (res["status"].code == 200 || res["status"].code == 201) {
            resolve(res['data']);
            // }
            // else resolve({});
          });
      } catch (err) {
        console.log('ours generateQrCode : ', err);
        reject(err);
      }
    });
  }

  // To get client paid or not start------>
  async isUserPaid(): Promise<boolean> {
    console.log(environment['baseUrl'], "appId")
    return new Promise((resolve) => {
      try {
        this.appId = this.wacConfig.configuration._id;
        this._http
          .get('/wac-config/getPkgInfo/' + this.appId)
          .subscribe((res: any) => {
            // console.log("respm", res)
            let isUserPaid = false;

            if (res && res.data) {
              const location = Intl.DateTimeFormat().resolvedOptions().timeZone
              // if (location == 'Asia/Calcutta')
              //   console.log("respm", res)
              if (res.data.info.price.India.sellingPrice > 0)
                isUserPaid = true;

              if (res.data.info.price.others.sellingPrice > 0)
                isUserPaid = true;
            }
            resolve(isUserPaid);
          });
      } catch (error) {
        resolve(false);
        console.log('Error while getting package information');
        console.log(error);
      }
    });
  }

  // To get client paid or not END----->


  async isAuthrizedQrCode(qrCode) {
    return new Promise(async (resolve, reject) => {

        this._http
          .get('/ott/users/v2/auth/qrcode/' + qrCode)
          .subscribe((res) => {
            if (res['status'].code == 200 && res['data'] && res['data'].token) {
              this.user = res['data'];
              this.user['verified'] = true;

              if (!this.user['others']) this.user['others'] = {};

              this.user['others']['verified'] = true;

              this.saveUser(this.user);
              resolve(res['data']);
            } else reject({});
            // else resolve({});
          },()=>reject({}));

    });
  }

  //update active device
  async updateActiveDevice(data) {
    try {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {

        return new Promise((resolve, reject) => {
          this._http
            .put('/ott/users/v2/auth/' + user['_id'] + '/updateActiveDevice', data)
            .subscribe((res) => {
              if (res['status'].code == 200 && res['data']) {
                let User = localStorage.getItem("user");
                if (User) {
                  localStorage.removeItem("user");
                }
                localStorage.setItem('user', JSON.stringify(res['data']));
                resolve(res['data']);
                console.log("ress", res['data'])

              }
              resolve(res);
            });
        });
      }
    } catch (error) {
      console.log('Error while getting package information');
      console.log(error);
    }
  }
}
