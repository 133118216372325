import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes,
  ExtraOptions,
} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./nav-tabs/nav-tabs.module').then((m) => m.NavTabsPageModule),
  },
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  // },
  // {
  //   path: 'tabs',
  //   loadChildren: () =>
  //     import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  // },
  {
    path: 'login',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/slider/slider.module').then(
            (m) => m.SliderPageModule
          ),
      },
      {
        path: '**',
        loadChildren: () =>
          import('./pages/slider/slider.module').then(
            (m) => m.SliderPageModule
          ),
      },
    ],
  },
  {
    path: 'ads-slider',
    loadChildren: () =>
      import('./pages/ads-slider/ads-slider.module').then((m) => m.AdsSliderModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./pages/search/search.module').then((m) => m.SearchPageModule),
  },
  {
    path: 'mylist',
    loadChildren: () =>
      import('./pages/hot/hot.module').then((m) => m.HotPageModule),
  },
  {
    path: 'more',
    loadChildren: () =>
      import('./pages/more/more.module').then((m) => m.MorePageModule),
  },
  // {
  //   path: 'playlist',
  //   loadChildren: () =>
  //     import('./pages/playlist/playlist.module').then((m) => m.PlaylistPageModule),
  // },
  {
    path: 'playlist',
    children: [
      {
        path: ':title',
        loadChildren: () =>
        import('./pages/playlist/playlist.module').then((m) => m.PlaylistPageModule),
      },
      {
        path: '',
        loadChildren: () =>
        import('./pages/playlist/playlist.module').then((m) => m.PlaylistPageModule),
      },
      {
        path: '**',
        loadChildren: () =>
        import('./pages/playlist/playlist.module').then((m) => m.PlaylistPageModule),
      },
    ],
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
  },
  // {
  //   path: 'register',
  //   loadChildren: () =>
  //     import('./pages/register/register.module').then(
  //       (m) => m.RegisterPageModule
  //     ),
  // },
  {
    path: 'ott',
    children: [
      {
        path: ':title',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: ':a/:b/:c',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: ':a/:b/:c/:d',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: ':a/:b',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: '**',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
    ],
  },
  {
    path: 'player',
    children: [
      {
        path: ':title',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: '**',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
    ],
  },
  {
    path: 'news',
    children: [
      {
        path: ':title',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: ':a/:b/:c/:d',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: ':a/:b/:c',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: ':a/:b',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
      {
        path: '**',
        loadChildren: () =>
          import('./pages/player/player.module').then(
            (m) => m.PlayerPageModule
          ),
      },
    ],
  },
  {
    path: 'app-settings',
    loadChildren: () =>
      import('./pages/app-settings/app-settings.module').then(
        (m) => m.AppSettingsPageModule
      ),
  },
  {
    path: 'subscriptions',
    loadChildren: () =>
      import('./pages/packages/packages.module').then(
        (m) => m.PackagesPageModule
      ),
  },
  {
    path: 'transactions',
    loadChildren: () =>
      import('./pages/orders/orders.module').then(
        (m) => m.OrdersModule
      ),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./pages/payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: 'iframeloader',
    loadChildren: () =>
      import('./modal-components/iframeloader/iframeloader.module').then(
        (m) => m.IframeloaderPageModule
      ),
  },
  {
    path: 'renderer',
    loadChildren: () =>
      import(
        './modal-components/html-string-renderer/html-string-renderer.module'
      ).then((m) => m.HtmlStringRendererPageModule),
  },
  {
    path: 'watch-history',
    loadChildren: () =>
      import('./pages/watch-history/watch-history.module').then(
        (m) => m.WatchHistoryModule
      ),
  },
  // {
  //   path: 'user-account',
  //   loadChildren: () => import('./pages/user-account/user-account.module').then(m => m.UserAccountPageModule)
  // },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  // {
  //   path: 'splash',
  //   loadChildren: () =>
  //     import('./splash/splash.module').then((m) => m.SplashPageModule),
  // },
  {
    path: 'update-profile',
    loadChildren: () =>
      import('./pages/update-profile/update-profile.module').then(
        (m) => m.UpdateProfilePageModule
      ),
  },
  {
    path: 'term-condition',
    loadChildren: () =>
      import('./pages/term-condition/term-condition.module').then(
        (m) => m.TermConditionModule
      ),
  },
  {
    path: 'refund-policy',
    loadChildren: () =>
      import('./pages/refund-policy/refund-policy.module').then(
        (m) => m.TermConditionModule
      ),
  },
  // {
  //   path: 'help',
  //   loadChildren: () =>
  //     import('./pages/help/help.module').then((m) => m.HelpModule),
  // },
    {
    path: 'contact-us',
    loadChildren: () =>
      import('./pages/help-page/help-page.module').then((m) => m.HelpPagePageModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/content-policy/content-policy.module').then((m) => m.ContentPolicyModule),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule),
  },

  {
    path: 'link-device',
    loadChildren: () => import('./pages/link-device/link-device.module').then(m => m.LinkDevicePageModule)
  },
  {
    path: '**',
    redirectTo: '',
  },
  {
    path: 'help-page',
    loadChildren: () => import('./pages/help-page/help-page.module').then( m => m.HelpPagePageModule)
  },
  {
    path: 'nav-tabs',
    loadChildren: () => import('./nav-tabs/nav-tabs.module').then( m => m.NavTabsPageModule)
  },
  {
    path: 'play',
    loadChildren: () => import('./pages/play/play.module').then( m => m.PlayPageModule)
  },
  // {
  //   path: 'fab-tabs',
  //   loadChildren: () => import('./fab-tabs/fab-tabs.module').then( m => m.FabTabsPageModule)
  // }
  // ,
  // {
  //   path: 'home-skeleton',
  //   loadChildren: () => import('./pages/skeleton/home-skeleton/home-skeleton.module').then( m => m.HomeSkeletonPageModule)
  // },
];
const options: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  initialNavigation: 'enabled',
};
@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
