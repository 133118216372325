import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpProvider } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class SeedService {
  constructor(private http: HttpProvider, private platform: Platform) {}

  packages = [];
  getPackages() {
    return new Promise(async (resolve, reject) => {
      try {
        const queryParams = this.platform.is('ios')
        ? 'source=ios'
        : this.platform.is('android')
        ? 'source=android'
        : '';
        if (this.packages.length > 0) resolve(this.packages);
        let data = await this.getByType('packages', queryParams);
        let d;
        d = data;
        this.packages = [];
        for (let i = 0; i < d.length; i++) {
          if (d[i].status == 1) this.packages.push(d[i]);
        }
        resolve(this.packages);
        setTimeout(() => (this.packages = []), 5 * 60 * 1000);
      } catch (error) {
        console.error('getPackages => ', error);
        reject(error);
      }
    });
  }
  getByType(type, queryParams?) {
    console.log('logging the params :::', queryParams);
    return new Promise((resolve, reject) => {
      this.http
        .get('/' + type + (queryParams ? '?' + queryParams : ''))
        .subscribe((res) => {
          if (res?.['data']) return resolve(res?.['data']);
          if (res) return resolve(res);
          else resolve([]);
        });
    });
  }
}
