export const environment = {
  fileType: 'stag',
  production: true,
  mobileApp: false,
  sentaryLogs: true,
  sentryUrl : 'https://5a031deab1dd42b1b5c35b07e3c7e23f@o4504518546489344.ingest.sentry.io/4504525049823232',

  playlistsUrl: 'https://stag-apis.mogiio.com/playlists/',
  onesignal: {
    appId: '74a15980-c75a-4155-ba4b-e6761c5f39e1',
    googleProjectNumber: '340660990914',
  },
  comingSoonVideos: 'coming soon',
  trendingVideos: 'trending',
  companyName: 'DNL',
  appId: '61a08e625fcfdd2152d764a2',
  // appKey: "e9hV1ZAs6sdecYvUCij9zVtxsVICplEo",
  baseUrl: 'https://stag-apis.mogiio.com',
  // baseURl: process.env.DOMAIN,
  // baseUrl: "https://tc.mogiapp.com",
  appLink: '',
  userImgDefault: 'assets/imgs/profile.jpg',
  horizontalDefaultImg: 'assets/imgs/horizontal.png',
  verticalDefaultImg: 'assets/imgs/vertical.png',
  companyUrl: '',
  limit: 10,
  sound: 'assets/icon/Message notification.mp3',
  companyDescription: '',
};
