import { Component, Input, OnInit } from '@angular/core';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { WacConfigService } from '../services/wac-config/wac-config.service';
import { SplashService } from '../services/splash/splash.service';
// import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.page.html',
  styleUrls: ['./splash.page.scss'],
})
export class SplashPage {

  @Input() src = encodeURI('./assets/videos/video.mp4');
  @Input() poster = encodeURI('./assets/imgs/poster.png');
  splashBackground;
  constructor(
    private platform: Platform,
    private modalCtrl: ModalController,
    public wacConfig: WacConfigService,
    private router: Router,
    private splashService: SplashService,
  ) {
    console.log("poster:: ", this.poster);
    console.log('LOADING THE SPLASH PAGE');
    // this.splashBackground = this.wacConfig.configuration.splashVideoBackgroundColor;
  }
  ionViewDidEnter() {

    if (this.platform.is('android') || this.platform.is('ios')) {
      console.log('testing:- ', this.wacConfig.configuration);
      // this.splashBackground = this.wacConfig.configuration.splashVideoBackgroundColor;
    }
    else {
      this.dismiss();
    }

    setTimeout(() => {
      // if(this.platform.is('ios'))
      // SplashScreen.hide();
      this.splashService.spalshVideoStarted.next();
    }, 1500);

    setTimeout(() => {
      console.log("Src :: ", this.src);
      this.modelDismissed = true;
      this.wacConfig.splashVideoCompleted = true;
      if (!this.modelDismissed)
        this.modalCtrl.dismiss();
    }, 7000);

  }
  videoPlayed() {
    // SplashScreen.hide();

  }
  modelDismissed = false;
  dismiss() {
    console.log('testing:- dismiss');
    this.modelDismissed = true;
    this.modalCtrl.dismiss();
    this.wacConfig.splashVideoCompleted = true;
  }

  navigateToTabsPage() {
    this.wacConfig.splashVideoCompleted = true;
    this.router.navigate(['']);
  }

  public splashVideoStarted(): void {
    console.log('TESTING VIDEO PLAY STARTED');
    this.splashService.spalshVideoStarted.next();
  }

}
