import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.page.html',
  styleUrls: ['./payment.page.scss'],
})
export class PaymentPage implements OnInit {
  @ViewChild('iframe', { static: false }) iframe: ElementRef

  orderId = "";
  hrId = "";
  prodinfo = "";
  src;
  url: any;
  constructor(public navParams: NavParams,
    private userService: UserService,
    public modalCtrl: ModalController) {
    this.initContent();
  }

  initContent() {
    console.log("Inside Payment Page");
    this.orderId = this.navParams.get('orderId') || '5ed243140220e07ee38e452d';
    this.hrId = this.navParams.get('hrId');
    this.prodinfo = this.navParams.get('prodinfo') || 'app-order';
    this.url = this.navParams.get('url') || "/payment/hdfc/generate_checksum?orderId=";
    this.src = environment['baseUrl']+ this.url;
    this.src += this.orderId;
    this.src += "&cId=" + this.userService.user['_id'] +
      "&email=" + this.userService.user['email'] +
      "&mobile=" + this.userService.user['mobile'] +
      "&name=" + this.userService.user['name'] +
      "&prodinfo=" + this.prodinfo +
      "&hrId=" + this.hrId;
  }

  ngOnInit() {
    var eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === "attachEvent"
      ? "onmessage"
      : "message";
    eventer(messageEvent, (e) => {
      if (e.data === "myevent" || e.message === "myevent") {
        this.modalCtrl.dismiss({
          'dismissed': true
        });
      }
    });

    setTimeout(() => {
      this.iframe.nativeElement.setAttribute('src', this.src);
    }, 1000);
  }

}
