import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { PaymentPage } from 'src/app/pages/payment/payment.page';
import { AlertService } from '../alert/alert.service';
import { CommonService } from '../common/common.service';
import { environment } from 'src/environments/environment';
import { OrderService } from '../order/order.service';
import { WacConfigService } from '../wac-config/wac-config.service';
import { HttpProvider } from '../http.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    public modalController: ModalController,
    public alertService: AlertService,
    public orderService: OrderService,
    public http: HttpProvider,
    public platform: Platform,
    public wac: WacConfigService
  ) {}

  async processPayment(createdOrder, packages?) {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('Opening payment', createdOrder);
        const modal = await this.modalController.create({
          component: PaymentPage,
          componentProps: {
            orderId: createdOrder['data']._id,
            hrId: createdOrder['data'].hrId,
            prodinfo: createdOrder['data'].receiverId,
            url: createdOrder['data'].paymentUrl,
          },
          cssClass: 'fullScreen',
        });
        modal.onDidDismiss().then(async (data) => {
          let fetchOrder = await this.orderService.getOrder(
            createdOrder['data']._id
          );
          console.log(
            'fetchedData:: ',
            fetchOrder,
            fetchOrder['data'],
            fetchOrder['data']['status']
          );
          if (fetchOrder['data']['status'] == 'payment_verified') {
            if (packages) resolve('setPackage');
            else resolve('play');
          }
          reject();
        });
        await modal.present();
      } catch (error) {
        console.log(error);
      }
    });
  }

  // Func for google play payment verification and for ios make verification call to apple server

  verifyInApp(orderObj, hrId,videoId?) {
    return new Promise((resolve, reject) => {
      this.http
        .post('/payment/in-app-purchase', {
          receipt: this.platform.is('ios')
            ? orderObj.transaction.appStoreReceipt
            : orderObj.transaction.receipt,
          platform: this.platform.is('android') ? 'android' : 'ios',
          wacConfig: this.wac.appId,
          hrId,
          videoId,
        })
        .subscribe(
          (res: any) => {
            console.log('logging the response in verifyinapp', res, orderObj);
            if (res && res.status && res.status.code == 200) {
              resolve({ status: 'payment_verified' });
            } else {
              resolve({ status: 'payment_not_verified' });
            }
            // eslint-disable-next-line arrow-body-style
          },
          (error) => {
            reject({ status: 'failed' });
          }
        );
    });
  }
}
