import { Injectable } from '@angular/core';
import { HttpProvider } from '../http.service';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { HttpClient } from '@angular/common/http';
import { WacConfigService } from '../wac-config/wac-config.service';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { rejects } from 'assert';

@Injectable({
  providedIn: 'root',
})
export class UserDeviceService {
  user = {};
  appId;
  tempUser = {};
  currentPkgId: any[];
  uploadedImages: any[];
  _http: any;
  constructor(
    private httpClient: HttpClient,
    private wacConfigService: WacConfigService,
    public http: HttpClient,
    private navCtrl: NavController,
    private router: Router,
    public wacConfig: WacConfigService,
    private httpProvider: HttpProvider
  ) {
    this._http = new HttpProvider(httpClient, wacConfigService);
  }

  async getUserDevices() {
    if (!localStorage) return;
    let user = JSON.parse(localStorage.getItem('user'));
    if (user)
      return new Promise((resolve, reject) => {
        this._http
          .get('/ott/users/' + user['_id'] + '/userDevices')
          .subscribe((res) => {
            console.log(res['status'].code, res['data']);
            resolve(res['data']);
          });
      });
  }

  logOutDevice(userDeviceId) {
    try {
    return new Promise((resolve, reject) => {
      if (!localStorage) return;
      let user = JSON.parse(localStorage.getItem('user'));
      if (user)
      this._http.post('/ott/users/' + user['_id'] + '/userDevices/' + userDeviceId).subscribe((res) => {
        resolve(res['data']);

      });
    });
  } catch (error) {
    console.log("error logout device", error)
  }
  }

  addNewDevice(data) {
    try {
      
      return new Promise((resolve, reject) => {
        if (!localStorage) return;
        let user = JSON.parse(localStorage.getItem('user'));
        if (user)
        this._http.post('/ott/users/' + user['_id'] + '/userDevices', data).subscribe((res) => {
          if(res['data']){
            this.saveUserDevice(res['data']);
          }
          resolve(res['data']);
  
          });
      });
    } catch (error) {
      console.log("error add new device", error);
    }
  }

  saveUserDevice(userDevice) {
    console.log('saving user device:: ', userDevice);
    localStorage.setItem('userDevice', JSON.stringify(userDevice));
  }
  
  getUserDevice() {
    let userDevice = JSON.parse(localStorage.getItem('userDevice'));
    if(!userDevice) return;
    return userDevice;

  }
}



  