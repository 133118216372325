<div
  [class]="
    isLandscape
      ? 'mob-iframe-container-landscape iframe-container'
      : 'mob-iframe-container iframe-container'
  "
>
  <iframe
    #iframe
    class="iFrame"
    [ngStyle]="{
      height: '100%',
      padding: '0px',
      border: '0px',
      margin: '0px',
      'background-image': 'url(' + thumbnail + ')',
      'background-position': 'center',
      'background-repeat': 'no-repeat',
      'background-size': 'cover'
    }"
    allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
    allowfullscreen
    playsinline
    webkit-playsinline
  >
  </iframe>
  <div
    *ngIf="showCustomFullscreenBtn"
    class="fullscreen-custom-btn"
    (click)="customFullscreen()"
  ></div>
</div>

<!-- this.platform.width() < 900 && setVideoFrameWidth > 720
? {
    height: '750px',
    padding: '0px',
    border: '0px',
    margin: '0px',
    'background-image': 'url(' + thumbnail + ')',
    'background-position': 'center',
    'background-repeat': 'no-repeat',
    'background-size': 'cover'
  }: -->
