import { Component, OnInit } from '@angular/core';
import { IonTabs, Platform } from '@ionic/angular';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-home-skeleton',
  templateUrl: './home-skeleton.page.html',
  styleUrls: ['./home-skeleton.page.scss'],
})
export class HomeSkeletonPage implements OnInit {

  activeTab: HTMLElement;

  constructor(

    public platform: Platform,
    public common: CommonService

  ) { }

  ngOnInit() {
  }

  tabChange(eve, tabsRef: IonTabs) {
    console.log('tabsRef', tabsRef)
    this.common.tab = eve.tab ? eve.tab : 'home';
    console.log("eve.tab", eve.tab, tabsRef, tabsRef.outlet);
    if (tabsRef)
      this.activeTab = tabsRef.outlet.activatedView.element;
  }

}
