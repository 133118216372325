import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Status } from 'src/app/models/status.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { MultiRegionHandler } from './zone-constroller';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  cache: { [key: string]: HttpEvent<unknown> } = {};
  static currentAppVersion: any = null;

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private appVersion: AppVersion,
    @Optional() @Inject('X_FORWARDED_HOST') private host: any
  ) {
    this.getAppVersion();
  }

  getAppVersion = async () => {
    try {
      AuthInterceptor.currentAppVersion = await this.appVersion.getVersionNumber();
    }catch(e) {
      console.log('\n\n\n Error in fetching App Version');
      AuthInterceptor.currentAppVersion = 'web-app'
    }
  }

  /* getting use timezone */
  timeZoneInstancesSwitcher = (domain: string) => {
    return new MultiRegionHandler(domain).timeZoneInstancesSwitcher() ?? domain;
  }
  
  tranformDomain(apiDomain) {


    let domain: string;
    try {
      domain = window.location.hostname;
    } catch (error) {
      domain = this.host;
    }
    console.log('tranformDomain 1', domain);

    if (domain != 'localhost' && !apiDomain.includes('mogiio.com'))
      return apiDomain;
    if (
      (this.platform.is('android') || this.platform.is('ios')) &&
      !this.platform.is('mobileweb')
    )
      return apiDomain;

    const prefix = domain == 'localhost' ? 'localhost' : domain.split('.')?.[1];
    console.log('tranformDomain prefix', prefix);
    switch (prefix) {
      case 'dev':
        return this.timeZoneInstancesSwitcher(`dev-apis.mogiio.com`);
      case 'qa':
        return `qa-apis.mogiio.com`;
      case 'stag':
        return `stag-apis.mogiio.com`;
      case 'feature':
        return `feature-apis.mogiio.com`;
      case 'localhost':
        return this.timeZoneInstancesSwitcher(`dev-apis.mogiio.com`);
      default:
        return this.timeZoneInstancesSwitcher(`apis.mogiio.com`);
    }
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem('token');
    // const start = performance.now();

    let config = {};
    // config = { url: 'mynewurl.com' };
    if (token) {
      config = {
        headers: request.headers.set('Authorization', token),
      };
    }

    var url;
    const getAppId = (request) => {
      try {
        
        console.log("request.headers['app-id']",request.headers.get('app-id'));
        return request.headers.get('app-id')||null;
      } catch (error) {
        return null
      }
    }
    if((this.platform.is('android') || this.platform.is('ios')) && 
      (request.method === 'PUT' || request.method === 'POST')) {
      if(AuthInterceptor.currentAppVersion) {
        if(request.url.includes('?')) url = new URL(request.url + '&appversion=' + AuthInterceptor.currentAppVersion);
        else url = new URL(request.url + '?appversion=' + AuthInterceptor.currentAppVersion);
      }else url = new URL(request.url);
    }else
      url = new URL(request.url);
    
    url.searchParams.append("appId", getAppId(request));

    url.hostname = this.tranformDomain(url.hostname);
    console.log('url.hostname', url.hostname,)
    config = { ...config, url: url.href };
    console.log('request.url', request.url, url);
    // request.url = "https://mogiio.com";
    // const dupReq = request.clone({ url: 'mynewurl.com' });
    if (
      request.method === 'GET' &&
      request.urlWithParams.search('mogiio') > 0 &&
      request.urlWithParams.search('watch-later') < 0 &&
      request.urlWithParams.search('qrcode') < 0 &&
      request.urlWithParams.search('continue-watching') < 0 &&
      request.urlWithParams.search('orders/video') < 0 &&
      request.urlWithParams.search('/ott/watch-history') < 0 &&
      request.urlWithParams.search('/packages') < 0 &&
      request.urlWithParams.search('/coupons') < 0 &&
      request.urlWithParams.search('/orders')
    ) {
      // console.log('serving from cache', request.urlWithParams);
      const cachedResponse: HttpEvent<unknown> =
        this.cache[request.urlWithParams];
      if (cachedResponse) {
        return of(cachedResponse);
      }
    }

    const authRequest = request.clone(config);

    return next.handle(authRequest).pipe(
      map((res) => {
        // const time = performance.now() - start;
        const networkInfo = AuthInterceptor.getNetworkInfo();
        try {
          if (res instanceof HttpResponse) {
            // console.log(
            //   'request.urlWithParams',
            //   request.urlWithParams,
            //   request.urlWithParams.search('qrcode') < 0
            // );
            const status = +res.status;
            if (request.url.search('boss') > 0) return res;
            if (
              request.method === 'GET' &&
              request.urlWithParams.search('mogiio') > 0 &&
              request.urlWithParams.search('watch-later') < 0 &&
              request.urlWithParams.search('qrcode') < 0 &&
              request.urlWithParams.search('continue-watching') < 0 &&
              request.urlWithParams.search('orders/video') < 0 &&
              request.urlWithParams.search('/ott/watch-history') < 0 &&
              request.urlWithParams.search('/packages') < 0 &&
              request.urlWithParams.search('/coupons') < 0 &&
              request.urlWithParams.search('/orders')
            ) {
              this.cache[request.urlWithParams] = res;
            }

            // try {
            //   if (
            //     time >= networkInfo[0] ||
            //     status < 200 ||
            //     status > 299
            //   ) {
            //     const _status: Status = {
            //       code: status,
            //       message: res?.body?.status ? res?.body?.status?.message : '',
            //     };
            //     this.reportToBOSS(_status, request.url, time, networkInfo[1]).then();
            //   }
            // } catch (error) {
            //   console.log('error while reporting to boss:- ', error);
            // }
          }
          return res;
        } catch (error) {
          console.log(error);
        }
      })
    );
  }

  askBoss(res, request, start) {
    // const {status} = res.body;
    const status = +res.status;
    // const time = performance.now() - start;
    // if (request.url.search('boss') > 0) return res;
    // if (
    //   time >= 1000 ||
    //   status < 200 ||
    //   status > 299
    // ) {
    //   this.reportToBOSS(request?.body?.status, request.url, time).then();
    // }
  }

  private async reportToBOSS(
    status: Status,
    url: string,
    time: number,
    network: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.http
          .post(
            environment.baseUrl + '/boss/endpoints',
            {
              url,
              responseStatus: status,
              responseTime: time,
              networkType: network,
              status: 1,
            },
            {
              headers: {
                'content-type': 'application/json',
                'app-id': '61cac223a0286ccc5ff0643c',
              },
            }
          )
          .subscribe(
            (response) => resolve(response),
            (error) => reject(error)
          );
      } catch (error) {
        console.log(error);
      }
    });
  }
  /**
   * @description - gets the threshold response time and network type
   */
  private static getNetworkInfo(): [number, string] {
    try {
      if (!window) return;
      const connection = window.navigator['connection'];
      let connectionType = 'unknown';
      let thresholdResponseTime = 2000;
      if (connection) {
        connectionType = connection['effectiveType'];
        switch (connectionType) {
          case '4g':
            thresholdResponseTime = 2000;
            break;
          case '3g':
            thresholdResponseTime = 3000;
            break;
          case '2g':
            thresholdResponseTime = 4500;
            break;
          default:
            thresholdResponseTime = 2000;
        }
      }
      return [thresholdResponseTime, connectionType];
    } catch (error) {}
  }
}
