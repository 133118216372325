import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PlayerPageRoutingModule } from './player-routing.module';

import { PlayerPage } from './player.page';
import { IframeComponent } from './iframe/iframe.component';
import { AdsSliderModule } from '../ads-slider/ads-slider.module';
import { HomeSkeletonPageModule } from '../skeleton/home-skeleton/home-skeleton.module';
import { TvodOptionsComponent } from './tvod-options.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PlayerPageRoutingModule,
    AdsSliderModule,
    HomeSkeletonPageModule
  ],
  declarations: [PlayerPage, IframeComponent, TvodOptionsComponent],
  providers: [TvodOptionsComponent]
})
export class PlayerPageModule {}
