import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PlayerPageModule } from './pages/player/player.module';
import { SplashPageModule } from './splash/splash.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PaymentPageModule } from './pages/payment/payment.module';

// import { StatusBar } from '@ionic-native/status-bar/ngx';

import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';

// import { SplashScreen } from '@ionic-native/splash-screen/ngx';

// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
// import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';

// import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

// import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';

// import { Crop } from '@ionic-native/crop/ngx';
// import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';

// import { Device } from '@ionic-native/device/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';

// import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
// import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
// import { Geolocation } from '@ionic-native/geolocation/ngx';
// import { FCM } from '@ionic-native/fcm/ngx';
// import { FCM } from '@awesome-cordova-plugins/fcm/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';

import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';

import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

import { Network } from '@awesome-cordova-plugins/network/ngx';

import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SwiperModule } from 'swiper/angular';
import { AuthInterceptor } from './services/auth-interceptor/auth.interceptor';
// import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
// import { Keyboard } from '@ionic-native/keyboard';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';

import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    HttpClientModule,

    AppRoutingModule,
    PlayerPageModule,
    SplashPageModule,
    PaymentPageModule,
    SwiperModule,
  ],
  providers: [
    StatusBar,
    OneSignal,
    NativeStorage,
    ScreenOrientation,
    LocalNotifications,

    SocialSharing,
    AndroidFullScreen,
    // SpeechRecognition,
    // Crop,
    // File,
    Insomnia,
    Device,
    // Keyboard,
    AppVersion,
    Network,
    BarcodeScanner,
    Deeplinks,
    SplashScreen,
    InAppPurchase2,
    // Keyboard,
    // AndroidPermissions,
    // Geolocation,
    // FCM,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      // useValue: SentryAngular.createErrorHandler(),
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
  public static injector: Injector;

  constructor(injector: Injector) {
    AppModule.injector = injector;
    this.setUpSentry();
  }

  setUpSentry() {
    try {
      if (environment.sentaryLogs) {
        Sentry.init(
          {
            dsn: environment.sentryUrl,

            // Set your release version, such as "getsentry@1.0.0"
            release: 'my-project-name@<release-name>',
            // Set your dist version, such as "1"
            dist: '<dist>',
          },
          // Forward the init method from @sentry/angular
          SentryAngular.init
        );
      }
    } catch (error) {
      if (error.name === 'RequestRateLimited') {
        console.error('Too many request!!!!');
      }
    }
  }
}
