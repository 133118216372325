import {forwardRef, Injectable} from '@angular/core';
import {HttpProvider} from '../http.service';
// import { NativeStorage } from '@ionic-native/native-storage/ngx';
import {NativeStorage} from '@awesome-cordova-plugins/native-storage/ngx';
import {Router} from '@angular/router';
import {NavController, Platform} from '@ionic/angular';
import {WacConfigService} from '../wac-config/wac-config.service';
import {Location} from '@angular/common';
import {PlaylistService} from '../playlist/playlist.service';
import {Subject, Observable} from 'rxjs';

// import FingerprintJS from "@fingerprintjs/fingerprintjs";

declare var Fingerprint2: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {


  private headerHomeListener = new Subject<any>();

  public iframeFullscreenListner = new Subject<boolean>();

  isButtonDisabled = false;
  user = {};
  tab;
  // fabTab;
  menuHide: boolean = false;
  showOptions = false;
  showLogin: boolean = false;
  deviceType = '';
  lastScrollTop = 10;
  web = false;
  activeChannelId = '';
  channelIndex = 0;
  hash;
  showModal: boolean = false;
  deviceComponent;
  loaded: boolean = false;
  blockMultipleClicks: boolean = false;

  // channelFirstId = '';

  constructor(
    private router: Router,
    private http: HttpProvider,
    private nativeStorage: NativeStorage,
    public nav: NavController,
    public wacConfig: WacConfigService,
    private location: Location,
    public playlistService: PlaylistService,
    public platform: Platform) {

    this.setDeviceType();
    // this.getVisitorID();

  }

  goToFirstChannel(){
    // console.log("62 common")
    this.headerHomeListener.next();
  }

  getHeaderHomeListener():Observable<any>{
    return this.headerHomeListener.asObservable();
 }


  getHumanReadableDate(date) {
    let dd = new Date(date).getDate();
    let mm = new Date(date).getMonth();
    let yyyy = new Date(date).getFullYear();
    return dd + "-" + mm + "-" + yyyy;
  }

  setSeoUrl(param) {

    return (`${(param.genres || "genre")}` +
      `${param.seriesName ? ('/' + param.seriesName) : ''}` +
      `${param.seasonName ? ('/' + param.seasonName) : ''}` +
      `/${(param.title || "title")}`).replace(/[^a-zA-Z0-9\/ ]/g, "").replace(/ /g, '-').toLowerCase() +
      (((param.seriesId ? "-" + param.seriesId : '') + "-" + param._id + "-" + (param.playlistId ? param.playlistId : (param.playlistName ? param.playlistName.replace(/ /g, "").toLowerCase() : 'playlist'))));
  }

  goToPage(page, param?) {
    try {
      console.log("Navigatiing :::::: ", page, param, this.wacConfig.configuration._id);

      setTimeout(() => {
        this.loaded = true;
      }, 1000);

      if (page == "player") {
        if (this.wacConfig.configuration._id == "61b0a28fac072f13fadbaa14") {
          page = "news";
        } else {
          page = "ott";
        }

        param = this.setSeoUrl(param);

        console.log('testing: route: ', "/" + page + "/" + param);
        this.nav.navigateRoot(["/" + page + "/" + param]);

      } else if (page == "playlist") {

        if (param.playlists)
          this.playlistService.playlist = param.playlists;
        if (param.playListId) {
          this.playlistService.playListId = param.playListId;

        }
        let params = `${((this.playlistService.playListId && this.playlistService.playListId != 'noId') ? this.playlistService.playListId : this.playlistService.playlist.name).replace(/[^a-zA-Z0-9\/ ]/g, "").replace(/ /g, '').toLowerCase()}`;
        console.log("params", page + "/" + params);
        this.nav.navigateRoot(param ? [page + "/" + params] : [page + "/" + params]);

      } else {
        this.nav.navigateRoot(param ? [page + "/" + param] : [page]);
      }

    } catch (error) {
      console.log("Error in navigating ::: ", error)
    }

  }

  async fetchLocation() {
    return new Promise((resolve, reject) => {
      fetch('https://ipapi.co/json/')
        .then(res => res.json())
        .then(response => {
          if (response)
            resolve(response ? response : "");
        })
        .catch((err) => {
          console.log('Request failed', err);
        })
    });
  }

  async fetchContent(type) {
    return new Promise((resolve, reject) => {
      try {
        this.http.get("/settings?type=" + type).subscribe(res => {
          resolve(res['data']);
        });
      } catch (error) {
        resolve({});
      }

    });
  }

  setOpts(playlist, view, exception?) {
    if (!playlist) return {};

    const opt:any = {
      autoplay: false,
      slidesPerView: this.getSlidesPerView(view, playlist),
      spaceBetween: 0
    };

    if(playlist['autoMove'] == true && this.deviceType != "androidTv")
      opt.autoplay  = { delay: (playlist['delay'] * 1000) || 2000 }

    if ( playlist['type'] != "hero")
      opt.spaceBetween = -12;

    // console.log('\n\nReturn opt::', opt);
    return opt;
  }

  getSlidesPerView(view, playlist) {
    if (view == "web") {
      if(playlist['type'] == "hero") return 1;

      if (playlist['numberOfCardsForWeb']) return playlist['numberOfCardsForWeb'] + 0.2

      return 6.2;
    }

    if(playlist['type'] == "hero" ) return 1;

    if (playlist['numberOfCardsForApp']) return playlist['numberOfCardsForApp'] + 0.1;

    return 2.1;
  }

  formatStr(title, acceptedLength = 20) {
    try {
      if(!title && title.length) return title;
      if (title.length > acceptedLength){
        return title.slice(0, acceptedLength) + '...';
      }
      return title;

    }catch(e) {return title}
  }

  addToWatchHistory(video) {
    let watchHistoryVideos = JSON.parse(localStorage.getItem('watchHistory'));
    let newList = [];
    if (!watchHistoryVideos) watchHistoryVideos = [];
    watchHistoryVideos.forEach(element => {
      if (element._id != video._id)
        newList.push(element);
    });
    video['date'] = new Date();
    newList.push(video);
    console.log("WatchHistory Video :: ", video);
    localStorage.setItem('watchHistory', JSON.stringify(newList));
  }

  addToLaterList(video) {
    let savedVideos = JSON.parse(localStorage.getItem('savedVideosList'));
    let newList = [];
    if (!savedVideos) savedVideos = [];
    savedVideos.forEach(element => {
      if (element._id != video._id)
        newList.push(element);
    });
    video['date'] = new Date();
    newList.push(video);
    console.log("Watch later Video :: ", video);
    localStorage.setItem('savedVideosList', JSON.stringify(newList));
  }

  removeToLaterList(video) {
    let savedVideos = JSON.parse(localStorage.getItem('savedVideosList'));
    let newList = [];
    if (!savedVideos) savedVideos = [];
    savedVideos.forEach(element => {
      if (element._id != video._id)
        newList.push(element);
    });
    console.log("Watch later Video Remove:: ", newList);
    localStorage.setItem('savedVideosList', JSON.stringify(newList));
  }

  /**
   *
   * @decleration Animation on scroll
   */
  async scrollFunction(event?: any) {
    if (event) {
      let scrollElement = await event.target.scrollTop;
      //console.log("scrollElement", scrollElement)

      if (scrollElement < this.lastScrollTop || scrollElement <= 50 || scrollElement <= 0) {
        this.menuHide = false;
        // downscroll code
      } else {
        this.menuHide = true;
        // upscroll code
      }
      this.lastScrollTop = scrollElement;

    }

    this.showOptions = false;
  }

  hideMenu() {
    this.showOptions = false;
  }

  setDeviceType() {
    if (this.platform.is('android') && this.platform.width() > 900) {
      this.deviceType = "androidTv";
    }
    // this.deviceType = "androidTv";
  }

  removeSpecialChar(str) {
    if (str)
      str = str.replace(/[^a-zA-Z ]/g, "");
    else
      str = '';
    return str;

  }

  checkPlatform() {
    if (this.platform.is('mobileweb') || this.platform.is('desktop') || this.deviceType == "androidTv" ) {
      return this.web = true;
    }
  }

  goBack() {
    this.location.back();
  }


  getIframeFullscreenListner() {
    return this.iframeFullscreenListner.asObservable();
  }

  //get Vistor Id
  public getVisitorID() {
    return new Promise((resolve, reject) => {
      try {
        console.log("vistorId")
        resolve({});
        new Fingerprint2()?.get((result, components) => {
          this.hash = result;
          this.deviceComponent = components;
          console.log("dummyid", result); //a hash, representing your device fingerprint
          console.log("dummy....", components); // an array of FP components
          resolve(result);
        });
      } catch (error) {
        console.log('our getVisitorID', error);
        this.hash = 'asdf';
        return resolve('asdf');
      }
    });
  }

// This is use to block multiple Click On slides on Home Page
  functionBlockMultipleClicks() {
    this.blockMultipleClicks = true;
    // console.log("common278", this.blockMultipleClicks);
  }
}
