import { Injectable, OnInit } from '@angular/core';
import { WacConfigService } from '../wac-config/wac-config.service';

import { Checkout } from '@mogiio/capacitor-razorpay';
import { UserService } from '../user/user.service';
// import 'capacitor-razorpay';

// const { RazorpayCheckout } = Plugins;
 

// declare var RazorpayCheckout;
@Injectable({
  providedIn: 'root'
})
export class GatewayService implements OnInit {

  constructor(
    private wacConfig: WacConfigService,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  setOptions(order, razorPayKey) {
    const user: any = this.userService.getLocalUser();

    let returnVaue = {
      key: razorPayKey,
      order_id: order['transaction']['payment']['orderId'],
      currency: order['transaction']['payment']['currency'],
      amount: order['amount'] * 100,
      name:"",
      description: "",
      image:"",
      "prefill": {
        "name": user.name?user.name:'',
        "email": user.email?user.email:'',
        "contact": (user.mobile && user.mobile.length === 10)?'+91' + user.mobile:''
      },
    }

    if (order['products'] && order['products'].length > 0) {
      returnVaue['name'] = order['products'][0]['name'],
        returnVaue['description'] = order['products'][0]['description']
      returnVaue['image'] = order['products'][0]['imageurl']
    }

    return returnVaue;
  }

  razorCheckout(order, orderId, options) {
    return new Promise(async (resolve, reject) => {
      // RazorpayCheckout.on('payment.success', async (success) => { //Razorpay payment success callback
      //   let payload = {
      //                   appId: this.wacConfig.configuration._id,
      //                   orderId: success.razorpay_order_id,
      //                   paymentId: success.razorpay_payment_id,
      //                   paymentSignature: success.razorpay_signature,
      //                   others: {
      //                     orgLogo: success.org_logo ? success.org_logo : '',
      //                     orgName: success.org_name ? success.org_name : '',
      //                     checkoutLogo: success.checkout_logo ? success.checkout_logo : '',
      //                     customBranding: success.custom_branding ? success.custom_branding : false
      //                   }
      //                 };
      //   if (order.videoId) {
      //     payload["videoId"] = order.videoId;
      //     payload["senderId"] = order.senderId;
      //   }
      //   console.log('Success payload ::: ', payload);
      //   resolve({ status: true, payload: payload });
      // });
      // RazorpayCheckout.on('payment.cancel', async (error) => { //Razorpay payment cancel callback
      //   let payload = { orderId: orderId, code: error.code, description: error.description, appId: this.wacConfig.configuration._id };
      //   console.log('Error payload ::: ', payload);
      //   resolve({ status: false, payload: payload });
      // });
      // RazorpayCheckout.on('payment.error', async (error) => { //Razorpay payment cancel callback
      //   let payload = { orderId: orderId, code: error.code, description: error.description, appId: this.wacConfig.configuration._id };
      //   console.log('Error payload ::: ', payload);
      //   resolve({ status: false, payload: payload });
      // });
      try {
        let data = (await Checkout.open(options));
        console.log(data);
        resolve(data);
      } catch (error) {
        reject(error);
      }

    });
  }
}
