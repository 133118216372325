<app-home-skeleton *ngIf="!loaded"></app-home-skeleton>

<ion-content
  [hidden]="!loaded"
  [scrollEvents]="false"
  [ngClass]="getIonContentClasses()"
>
  <ion-header id="player_header">
    <div *ngIf="common.deviceType != 'androidTv' " class="header_div">
      <ion-icon
        *ngIf="platform.width()<900 && portrait && !platform.is('mobileweb')"
        class="back"
        name="arrow-back"
        (click)="videoPlaying = false; commonService.goBack()"
      >
      </ion-icon>
    </div>
  </ion-header>
  <div>
    <ion-grid class="ion-no-margin ion-no-padding">
      <ion-row style="cursor: pointer" class="ion-no-margin ion-no-padding">
        <ion-col class="ion-no-margin ion-no-padding">
          <app-iframe
            (someEvent)="hideSpinner($event)"
            (newVideoEvent)="loadVideoMeta($event)"
            (newVideoUrls)="settingNewVideoUrls($event)"
            (portrait)="changeOrientation($event)"
            [hidden]="!videoPlaying || loaderGif"
            [setVideoFrameHeight]="setVideoFrameHeight"
            [setVideoFrameWidth]="setVideoFrameWidth"
          >
          </app-iframe>

          <div
            class="mobile"
            *ngIf="!videoPlaying"
            style="width: 100vw; height: 30vh"
          >
            <button (click)="playVideo(playingVideo)" [disabled]="isClicked">
              <ion-img
                [src]="playingVideo?.horizontalThumbnail ? playingVideo?.horizontalThumbnail : environment['horizontalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', '')"
                class="playing_section"
              >
              </ion-img>
            </button>
            <button (click)="playVideo(playingVideo)" [disabled]="isClicked">
              <div [ngClass]="'video_div'">
                <ion-icon color="light" name="play-circle-outline"></ion-icon>
              </div>
            </button>
          </div>

          <!-- TODO: video tag starts here -->
          <div
            class="web"
            *ngIf="!videoPlaying"
            style="width: 100vw; height: 100vh"
          >
            <button (click)="playVideo(playingVideo)" [disabled]="isClicked">
              <ion-img
                [id]="playingVideo?._id"
                [src]="playingVideo?.horizontalThumbnail ? w400Replace(playingVideo?.horizontalThumbnail) : environment['horizontalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', '')"
                class="playing_section"
              ></ion-img>
            </button>
            <button (click)="playVideo(playingVideo)" [disabled]="isClicked">
              <div [ngClass]="'video_div'">
                <ion-icon color="light" name="play-circle-outline"></ion-icon>
              </div>
            </button>
          </div>
          <!-- TODO: video tag end here -->

          <div class="spinners" *ngIf="loaderGif">
            <ion-spinner name="bubbles"></ion-spinner>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid *ngIf="playingVideo" class="ion-desc">
      <ion-row
        *ngIf="commonService.deviceType != 'androidTv' "
        style="max-width: 100%"
      >
        <ion-col>
          <ion-row class="player-tabs">
            <ion-col
              class="ion-text-center"
              *ngIf="!playingVideo.watchLaterCheck"
            >
              <ion-row
                style="display: inline-flex"
                (click)="playingVideo.watchLaterCheck = !playingVideo.watchLaterCheck; onSaveVideo(playingVideo);"
              >
                <span title="Save Video">
                  <!-- <svg
                    class="icon icon-save sprite-icon-plus"
                    style="cursor: pointer"
                  >
                    <use xlink:href="assets/tabs/sprite.svg#icon-save"></use>
                  </svg> -->
                  <ion-icon name="add"></ion-icon>
                </span>
              </ion-row>
              <ion-row style="display: flow-root">
                <ion-label
                  class="player-icon-name"
                  style="cursor: text !important"
                  >Save</ion-label
                >
              </ion-row>
            </ion-col>

            <ion-col
              class="ion-text-center"
              *ngIf="playingVideo.watchLaterCheck"
            >
              <ion-row
                style="display: inline-flex"
                (click)="playingVideo.watchLaterCheck = !playingVideo.watchLaterCheck; onRemoveSavedVideo(playingVideo);"
              >
                <span title="Video Saved">
                  <!-- <svg
                    class="icon icon-save sprite-icon-plus"
                    style="cursor: pointer"
                  >
                    <use xlink:href="assets/tabs/sprite.svg#icon-saved"></use>
                  </svg> -->
                  <ion-icon name="checkmark"></ion-icon>
                </span>
              </ion-row>
              <ion-row style="display: flow-root">
                <ion-label
                  class="player-icon-name"
                  style="cursor: text !important"
                  >Saved</ion-label
                >
              </ion-row>
            </ion-col>

            <!-- To Show ageRating on Player page Start ------>
            <ion-col
              class="ion-text-center"
              *ngIf="playingVideo && playingVideo?.ageRating"
            >
              <ion-row style="display: flow-root; margin-bottom: 4px">
                <ion-text>{{playingVideo ?.ageRating}}<br /></ion-text>
              </ion-row>
              <ion-row style="display: flow-root; width: 102%">
                <ion-label style="color: #808080; font-size: 14px"
                  >Age Rating</ion-label
                >
              </ion-row>
            </ion-col>
            <!-- To Show ageRating on Player page Start ------>

            <!-- <ion-col class="ion-text-center" (click)="openRateBox($event)">
              <ion-row style="display: inline-flex">
                <ion-text
                  style="font-weight: 800; font-size: 18px; cursor: pointer"
                >
                  {{ ((playingVideo?.totalRating && playingVideo?.userCount) ?
                  (playingVideo?.totalRating/(playingVideo?.userCount +
                  0.0000001)) : (playingVideo?.currentRating ?
                  playingVideo.currentRating : 0)) |number: '1.1-2'}}
                </ion-text>
              </ion-row>
              <ion-row style="display: flow-root">
                <ion-label class="player-icon-name">Rate</ion-label>
              </ion-row>
            </ion-col> -->

            <!-- Social Share for Mobile  -->
            <ion-col
              [hidden]="(this.platform.is('mobileweb')) || (this.platform.is('desktop'))"
            >
              <ion-row style="display: inline-flex" (click)="openSocial()">
                <ion-icon name="share-social"></ion-icon>
              </ion-row>
              <ion-row style="display: flow-root">
                <ion-label class="player-icon-name">Share</ion-label>
              </ion-row>
            </ion-col>

            <!-- Social Share for Web + MobileWeb  -->
            <!-- shareVideo(playingVideo?.title,playingVideo?.description,playingVideo?.horizontalThumbnail) -->
            <!-- *ngIf="(this.platform.is('desktop')) || (this.platform.is('mobileweb'))" -->
            <ion-col
              *ngIf="(this.platform.is('desktop')) || (this.platform.is('mobileweb'))"
            >
              <ion-row
                style="display: inline-flex"
                (click)="$event.preventDefault();
              $event.stopPropagation(); giveRating=false; showDesktopSocial=true"
              >
                <ion-icon name="share-social"></ion-icon>
              </ion-row>
              <ion-row style="display: flow-root">
                <ion-label
                  class="player-icon-name"
                  style="cursor: text !important"
                  >Share</ion-label
                >
              </ion-row>
            </ion-col>

            <ion-col
              [hidden]="(this.platform.is('mobileweb'))"
              *ngIf="showCastButton && this.platform.is('android') && (+this.device.version < 12)"
              class="ion-text-center"
              (click)="cast()"
            >
              <ion-row style="display: inline-flex">
                <svg class="icon icon-save sprite-icon-plus">
                  <use xlink:href="assets/tabs/sprite.svg#icon-ccast"></use>
                </svg>
              </ion-row>
              <ion-row style="display: flow-root">
                <ion-label class="player-icon-name">Cast</ion-label>
              </ion-row>
            </ion-col>
            <!-- Trailer -->

            <ion-col
              *ngIf="playingVideo && playingVideo?.trailerUrl && !trailerPlay"
              class="ion-text-center"
              (click)="watchTrailer(playingVideo, true)"
            >
              <ion-row style="display: inline-flex">
                <ion-icon
                  style="font-size: 20px"
                  name="play-circle-outline"
                ></ion-icon>
              </ion-row>
              <ion-row style="display: flow-root">
                <ion-label class="player-icon-name">Trailer</ion-label>
              </ion-row>
              <!-- <ion-button
                class="btn-trailer"
                (click)="watchTrailer(playingVideo, true)"
                >Watch Trailer</ion-button
              > -->
            </ion-col>
            <ion-col
              *ngIf="playingVideo?.trailerUrl && trailerPlay"
              class="ion-text-center"
              (click)="watchTrailer(playingVideo, false)"
            >
              <ion-row style="display: inline-flex">
                <ion-icon
                  style="font-size: 20px"
                  name="play-circle-outline"
                ></ion-icon>
              </ion-row>
              <ion-row style="display: flow-root">
                <ion-label class="player-icon-name">Video</ion-label>
              </ion-row>
              <!-- <ion-button
                class="btn-trailer"
                (click)="watchTrailer(playingVideo, false)"
                >Watch Video</ion-button
              > -->
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col class="ion-padding video-title">
          {{setCapatalizeName(playingVideo?.title)}}
        </ion-col>
      </ion-row>

      <ion-row style="max-width: 800px">
        <ion-col
          style="
            font-size: 13.5px;
            font-style: italic;
            padding: 0px 20px 3px 20px;
            color: grey;
          "
        >
          {{releaseDate ? releaseDate : currentYear}}
        </ion-col>
      </ion-row>
      <div>
        <div
          *ngIf="playingVideo && playingVideo.description"
          class="player-des"
        >
          {{playingVideo.description.length < 251 ? playingVideo.description :
          playingVideo.description.substring(0,250) }}{{ (more &&
          playingVideo.description.length> 250) ?
          playingVideo.description.substring(250) : '...' }}
          <a
            *ngIf="playingVideo?.description.length > 250"
            style="font-weight: bold"
            (click)="more=!more"
            >{{more?'\nless':'more'}}</a
          >
        </div>
        <div
          style="
            font-weight: 500;
            text-transform: capitalize;
            padding-left: 20px;
          "
        >
          <span class="playerTag" *ngFor="let tag of playingVideo.tags">
            {{tag}}
          </span>
        </div>
      </div>

      <ion-button
        *ngIf="buyPackageMessage"
        (click)="commonService.goToPage('subscriptions', buyPackageId)"
        expand="block"
        class="ionbtnPremiumUnlock"
      >
        {{buyPackageMessage}}
      </ion-button>

      <!-- To show Cast  -->
      <ion-row
        style="margin-left: -10px; margin-top: 10px"
        *ngIf="playingVideo && playingVideo.casts?.length > 0"
      >
        <ion-col>
          <ion-card>
            <ion-card-header>
              <ion-card-title>Cast</ion-card-title>
            </ion-card-header>
            <ion-card-content id="castModal">
              <ion-slides
                scrollbar
                #slidesCon
                [options]="showCrewCastSlider()"
                style="height: 100%; margin-left: -40px"
              >
                <ion-slide
                  *ngFor="let castDes of playingVideo.casts"
                  (click)="getMoreFromCast(castDes.name)"
                >
                  <ion-tab-button>
                    <ion-icon
                      class="cast-icon"
                      *ngIf="!castDes.imageLoaded"
                      name="person-outline"
                    ></ion-icon>
                    <img
                      [hidden]="!castDes.imageLoaded"
                      (load)="castDes.imageLoaded=true"
                      [alt]="castDes.name ? common.removeSpecialChar(castDes.name. substring(0,20)) : ''"
                      class="shadow cast-image"
                      [src]="castDes.image"
                      style="cursor: pointer; align-items: center"
                    />
                    <div class="cast-title-designation">
                      <ion-title
                        [ngClass]="this.platform.is('ios') ?'ios-cast-sub-title':'cast-sub-title'"
                      >
                        {{castDes.name}}</ion-title
                      >
                      <div class="cast-designation">
                        <ion-label>{{castDes.designation}}</ion-label>
                      </div>
                    </div>
                  </ion-tab-button>
                </ion-slide>
              </ion-slides>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>

      <!-- show modal for cast -->
      <ion-modal trigger="castModal">
        <ng-template>
          <div class="cast-popup-header">
            <h3>Watch More Videos Of {{this.resp[0]}}</h3>
            <ion-button *ngIf="platform.width()>900" (click)="close()"
              >Close</ion-button
            >
          </div>
          <ion-content style="width: 100%; margin: 100px">
            <!-- [ngClass]="platform.width()<900 ? 'phone' : ''" -->
            <div class="cast-vidoes">
              <div
                *ngFor="let video of resp[1]"
                [ngClass]="platform.width()<900?'mobileVideo':'video'"
              >
                <!-- TO ShowTop10 Badges -->
                <ion-badge
                  *ngIf="isTop10Video(video._id) || true"
                  color="warning"
                  style="position: absolute; right: 4px; top: 4px; z-index: 10"
                  >Top10</ion-badge
                >

                <!-- Show bag Badges on package Video   -->
                <span
                  *ngIf="video?.packageIds?.length"
                  style="position: absolute; left: 2px; top: 2px; z-index: 10"
                >
                  <ion-icon
                    style="font-size: 24px"
                    color="warning"
                    name="bag-handle"
                  ></ion-icon>
                </span>

                <!-- Show Crown Badges on TVOD videos Video   -->
                <span
                  *ngIf="(!video?.packageIds?.length && video?.TVOD?.price)"
                  style="position: absolute; left: 2px; top: 2px; z-index: 10"
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 -64 640 640"
                    fill="#FFD700"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"
                    />
                  </svg>
                </span>
                <img
                  (click)="details(video, video._id, resp)"
                  [hidden]="!video.imageLoaded"
                  (load)="video.imageLoaded=true"
                  [src]="video.showHorizontal ? (video.horizontalThumbnail ? w400Replace(video.horizontalThumbnail) : environment['horizontalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', '')) : (video.verticalThumbnail ? w400Replace(video.verticalThumbnail) : environment['verticalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', ''))"
                />
                <ion-label>
                  {{ video.isSeries ? video.name : (video.title ? video.title :
                  '') }}
                </ion-label>
              </div>
            </div>
          </ion-content>
          <ion-button *ngIf="platform.width()<900" (click)="close()"
            >Close</ion-button
          >
        </ng-template>
      </ion-modal>

      <!-- More From -->
      <ion-row
        *ngIf="wacConfig.configuration.defaultPlaylists?.moreLikeThis?.status == 1 && playlistVideos?.name != 'Continue Watching'"
      >
        <ion-label
          *ngIf="showPlaylistLable && seriesId.length != 24"
          class="head_lbl"
        >
          More From {{(playlistVideos.type == 'coming soon') ?
          playlistVideos.type:setCapatalizeName(playlistVideos.name) ||
          playlistVideos.type }}
        </ion-label>
        <!-- playlistVideos.name || playlistVideos.type -->
        <ion-col *ngIf="seriesId.length == 24">
          <div class="main_btn_div">
            <ion-button
              fill="clear"
              size="small"
              *ngFor="let season of seriesVideos; let k=index;"
              (click)="loadContent(season, $event)"
              [id]="season._id+k"
            >
              <!-- [id]="season._id+k" -->
              <span
                *ngIf="season._id != activeId"
                style="color: var(--ion-color-step-650)"
                >{{season.name}}</span
              >
              <span *ngIf="season._id == activeId">{{season.name}}</span>
            </ion-button>
          </div>
        </ion-col>
      </ion-row>

      <!-- defaultPlaylists playlistVideos -->
      <ion-row
        *ngIf="wacConfig.configuration.defaultPlaylists?.moreLikeThis?.status == 1 && (playlistVideos && playlistVideos?.name && playlistVideos?.name != 'Continue Watching')"
      >
        <ion-col>
          <ion-row
            *ngIf="playlistVideos && playlistVideos.videos && playlistVideos.videos.length > 0 "
          >
            <ion-col>
              <div class="mobile">
                <ion-slides #slidesCon [options]="setOptsApp" class="videos">
                  <ion-slide
                    *ngFor="let item of playlistVideos.videos;let i = index;"
                    (click)="seriesId.length == 24  ?
                    details(item, playlistVideos._id, playlistVideos, i,$event) : details(item, playlistVideos._id, playlistVideos,$event)"
                  >
                    <ion-grid>
                      <ion-row>
                        <ion-col>
                          <!-- TO ShowTop10 Badges -->
                          <ion-badge
                            *ngIf="isTop10Video(item._id)"
                            color="warning"
                            class="top10Icon"
                            >Top10</ion-badge
                          >

                          <!-- Show bag Badges on package Video   -->
                          <span
                            *ngIf="item?.packageIds?.length"
                            class="packageIcon"
                          >
                            <ion-icon
                              style="font-size: 24px"
                              color="warning"
                              name="bag-handle"
                            ></ion-icon>
                          </span>

                          <!-- Show Crown Badges on TVOD videos Video   -->
                          <span
                            *ngIf="(!item?.packageIds?.length && item?.TVOD?.price)"
                            class="tvodIcon"
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 -64 640 640"
                              fill="#FFD700"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"
                              />
                            </svg>
                          </span>

                          <!-- Show prograssive loader if image not loaded more from playlists in mobile -->
                          <div>
                            <ion-thumbnail
                              *ngIf="!wacConfig.configuration.defaultPlaylists.imageLoaded"
                              slot="start"
                            >
                              <ion-skeleton-text animated></ion-skeleton-text>
                            </ion-thumbnail>
                          </div>

                          <img
                            [hidden]="!wacConfig.configuration.defaultPlaylists.imageLoaded"
                            (load)="wacConfig.configuration.defaultPlaylists.imageLoaded=true"
                            [alt]="item.title ? common.removeSpecialChar(item.title. substring(0,20)) : ''"
                            class="shadow"
                            [src]="wacConfig.configuration.defaultPlaylists?.moreLikeThis?.showHorizontal ? (item.horizontalThumbnail ? item.horizontalThumbnail : environment['horizontalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', '')) : (item.verticalThumbnail ? item.verticalThumbnail : environment['verticalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', ''))"
                          />
                          <!-- </img> -->
                          <div
                            *ngIf="wacConfig.configuration.defaultPlaylists?.moreLikeThis?.showName"
                            class="bottom-right"
                          >
                            <ion-icon
                              name="play"
                              style="color: #ffffff"
                            ></ion-icon
                            ><span class="title-vid" style="color: #ffffff"
                              >{{item?.title}}</span
                            >
                          </div>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-slide>
                </ion-slides>
              </div>
              <div class="web" style="position: relative">
                <div
                  *ngIf="common.deviceType != 'androidTv' && playlistVideos?.videos.length > setOptsWeb.slidesPerView"
                  class="arrows"
                  style="width: 100%"
                >
                  <ion-icon
                    name="chevron-back"
                    style="
                      cursor: pointer;
                      transform: scale(2);

                      z-index: 999;
                      background-color: rgba(0, 0, 0, 0.479);
                      border-radius: 0 50% 50% 0;
                    "
                    (click)="slideNext.slidePrev()"
                    size="large"
                  ></ion-icon>
                  <ion-icon
                    name="chevron-forward"
                    style="
                      cursor: pointer;
                      transform: scale(2);

                      z-index: 999;
                      background-color: rgba(0, 0, 0, 0.479);
                      border-radius: 50% 0 0 50%;
                    "
                    (click)="slideNext.slideNext(); (playlistVideos && playlistVideos['videos']  && playlistVideos['videos'].length >= environment['limit']) ? loadNext(slideNext.isEnd(), playlistVideos._id) : ''"
                    size="large"
                  ></ion-icon>
                </div>
                <ion-slides #slideNext [options]="setOptsWeb" class="videos">
                  <ion-slide
                    [id]="item._id+i"
                    *ngFor="let item of playlistVideos?.videos;let i = index;"
                    (click)="seriesId.length == 24 ?
                      details(item, playlistVideos._id, playlistVideos,$event) :
                      details(item, playlistVideos._id, playlistVideos,$event)"
                  >
                    <ion-grid>
                      <ion-row>
                        <ion-col class="container">
                          <!-- TO ShowTop10 Badges -->
                          <ion-badge
                            *ngIf="isTop10Video(item._id)"
                            color="warning"
                            class="top10Icon"
                            >Top10</ion-badge
                          >
                          <!-- TO Series Badges in MobileApp  -->
                          <ion-badge
                            *ngIf="!item.top10 && !item.type"
                            color="warning"
                            class="seriesIcon"
                            >Series
                          </ion-badge>

                          <!-- Show bag Badges on package Video   -->
                          <span
                            *ngIf="item?.packageIds?.length"
                            class="packageIcon"
                          >
                            <ion-icon
                              style="font-size: 24px"
                              color="warning"
                              name="bag-handle"
                            ></ion-icon>
                          </span>

                          <!-- Show Crown Badges on TVOD videos Video   -->
                          <span
                            *ngIf="(!item?.packageIds?.length && item?.TVOD?.price)"
                            class="tvodIcon"
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 -64 640 640"
                              fill="#FFD700"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"
                              />
                            </svg>
                          </span>
                          <!-- Show prograssive loader if image not loaded more from playlists in web -->
                          <div>
                            <ion-thumbnail
                              *ngIf="!wacConfig.configuration.defaultPlaylists.imageLoaded"
                              slot="start"
                            >
                              <ion-skeleton-text animated></ion-skeleton-text>
                            </ion-thumbnail>
                          </div>

                          <img
                            [hidden]="!wacConfig.configuration.defaultPlaylists.imageLoaded"
                            (load)="wacConfig.configuration.defaultPlaylists.imageLoaded=true"
                            [alt]="item.title ? common.removeSpecialChar(item.title. substring(0,20)) : ''"
                            class="shadow"
                            [src]="wacConfig.configuration.defaultPlaylists?.moreLikeThis?.showHorizontal ? (item.horizontalThumbnail ? item.horizontalThumbnail : environment['horizontalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', '')) : (item.verticalThumbnail ? item.verticalThumbnail : environment['verticalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', ''))"
                          />
                          <!-- </img> -->
                          <div class="overlay">
                            <div class="text">
                              <h4
                                *ngIf="wacConfig.configuration.defaultPlaylists?.moreLikeThis?.showHorizontal"
                                style="
                                  font-size: 10px;
                                  font-weight: 600;
                                  margin: 0;
                                "
                              >
                                {{item.title}}
                              </h4>
                              <p
                                *ngIf="wacConfig.configuration.defaultPlaylists?.moreLikeThis?.showHorizontal"
                                style="
                                  font-size: 10px;
                                  font-weight: 600;
                                  margin: 0;
                                "
                              >
                                {{item.description?.substring(0,20)}}
                              </p>
                              <h4
                                *ngIf="!wacConfig.configuration.defaultPlaylists?.moreLikeThis?.showHorizontal"
                                style="font-size: 12px; font-weight: 600"
                              >
                                {{item.title}}
                              </h4>
                              <p
                                *ngIf="!wacConfig.configuration.defaultPlaylists?.moreLikeThis?.showHorizontal"
                                style="font-size: 12px; font-weight: 600"
                              >
                                {{item.description?.substring(0,50)}}
                              </p>
                              <div style="display: inline-grid">
                                <div class="hover-bottom">
                                  <ion-icon
                                    name="play"
                                    style="color: #ffffff"
                                  ></ion-icon>
                                  <span class="title-vid" style="color: #ffffff"
                                    >Watch Now</span
                                  >
                                </div>
                                <div
                                  *ngIf="item.watchLaterCheck"
                                  (click)="$event.preventDefault();
                                  $event.stopPropagation(); item.watchLaterCheck=!item.watchLaterCheck;onRemoveSavedVideo(item);"
                                  class="hover-watch-bottom"
                                >
                                  <svg class="icon icon-save sprite-icon-pluss">
                                    <use
                                      xlink:href="assets/tabs/sprite.svg#icon-saved"
                                    ></use>
                                  </svg>

                                  <span class="title-vid" style="color: #ffffff"
                                    >Watch Later</span
                                  >
                                </div>
                                <div
                                  *ngIf="!item.watchLaterCheck"
                                  (click)="$event.preventDefault();
                                  $event.stopPropagation(); item.watchLaterCheck=!item.watchLaterCheck;onSaveVideo(item);"
                                  class="hover-watch-bottom"
                                >
                                  <svg class="icon icon-save sprite-icon-pluss">
                                    <use
                                      xlink:href="assets/tabs/sprite.svg#icon-save"
                                    ></use>
                                  </svg>

                                  <span class="title-vid" style="color: #ffffff"
                                    >Watch Later</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            *ngIf="wacConfig.configuration.defaultPlaylists?.moreLikeThis?.showName"
                            class="bottom-right"
                          >
                            <ion-icon
                              name="play"
                              style="color: #ffffff"
                            ></ion-icon
                            ><span class="title-vid" style="color: #ffffff"
                              >{{item?.title}}</span
                            >
                          </div>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-slide>
                </ion-slides>
              </div>
              <!-- <div *ngFor="let video of playlistVideos">
              <ion-item (click)="toPlay(video)" style="margin-bottom: 3px;">
                <ion-row>
                  <ion-col size="3">
                    <ion-img [src]="video.horizontalThumbnail"></ion-img>
                  </ion-col>
                  <ion-col size="7" class="ion-padding">
                    <ion-label style="font-size: 16px;">
                      {{video.title}}
                    </ion-label>
                  </ion-col>
                  <ion-col size="1" class="ion-padding">
                    <ion-icon color="light" name="play"></ion-icon>
                  </ion-col>
                </ion-row>
              </ion-item>

            </div> -->
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <!-- To Show third party advertisements -->
      <div>
        <app-ads-slider></app-ads-slider>
      </div>

      <!-- continue watching title -->
      <ion-row
        *ngIf="wacConfig.configuration.defaultPlaylists?.continueWatching?.status == 1 && continueWatching?.length > 0"
      >
        <ion-label
          *ngIf="continueWatching && continueWatching?.length > 1"
          class="head_lbl"
          style="text-transform: capitalize"
          >Continue Watching</ion-label
        >
      </ion-row>
      <!-- continue watching -->
      <ion-row
        *ngIf="wacConfig.configuration.defaultPlaylists?.continueWatching?.status == 1 && continueWatching?.length > 0"
      >
        <ion-col>
          <ion-row>
            <ion-col>
              <div class="mobile">
                <ion-slides #slidesCon [options]="setOptsApp" class="videos">
                  <ion-slide
                    *ngFor="let item of continueWatching;let i = index"
                    [hidden]="item._id == playingVideo._id"
                  >
                    <!-- [id]="item._id+i" -->
                    <ion-grid *ngIf="item._id != playingVideo._id">
                      <ion-row>
                        <ion-col (click)="details(item, item._id, {},$event)">
                          <!-- TO ShowTop10 Badges  -->
                          <ion-badge
                            *ngIf="isTop10Video(item._id)"
                            color="warning"
                            class="top10Icon"
                            >Top10</ion-badge
                          >
                          <!-- TO Series Badges in MobileApp  -->
                          <ion-badge
                            *ngIf="!item.top10 && !item.type"
                            color="warning"
                            class="seriesIcon"
                            >Series
                          </ion-badge>

                          <!-- Show bag Badges on package Video   -->
                          <span
                            *ngIf="item?.packageIds?.length"
                            class="packageIcon"
                          >
                            <ion-icon
                              style="font-size: 24px"
                              color="warning"
                              name="bag-handle"
                            ></ion-icon>
                          </span>

                          <!-- Show Crown Badges on TVOD videos Video   -->
                          <span
                            *ngIf="(!item?.packageIds?.length && item?.TVOD?.price)"
                            class="tvodIcon"
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 -64 640 640"
                              fill="#FFD700"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"
                              />
                            </svg>
                          </span>
                          <!-- Show prograssive loader if image not loaded in mobileApp-->
                          <div>
                            <ion-thumbnail
                              *ngIf="!wacConfig.configuration.defaultPlaylists.imageLoaded"
                              slot="start"
                            >
                              <ion-skeleton-text animated></ion-skeleton-text>
                            </ion-thumbnail>
                          </div>

                          <img
                            [hidden]="!wacConfig.configuration.defaultPlaylists.imageLoaded"
                            (load)="wacConfig.configuration.defaultPlaylists.imageLoaded=true"
                            [alt]="item.title ? common.removeSpecialChar(item.title. substring(0,20)) : ''"
                            class="shadow"
                            [src]="wacConfig.configuration.defaultPlaylists?.continueWatching?.showHorizontal ? (item.horizontalThumbnail ? item.horizontalThumbnail : environment['horizontalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', '')) : (item.verticalThumbnail ? item.verticalThumbnail : environment['verticalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', ''))"
                          />
                          <!-- </img> -->
                          <div
                            class="bottom-right"
                            *ngIf="wacConfig.configuration.defaultPlaylists?.continueWatching?.showName"
                          >
                            <ion-icon
                              name="play"
                              style="color: #ffffff"
                            ></ion-icon>
                            <span class="title-vid" style="color: #ffffff"
                              >{{item.title ? item.title.substring(0,9) :
                              ''}}</span
                            >
                          </div>
                          <ion-progress-bar
                            color="primary"
                            [value]="item.progressBar"
                          ></ion-progress-bar>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-slide>
                </ion-slides>
              </div>

              <div class="web" style="position: relative">
                <div
                  *ngIf=" common.deviceType != 'androidTv' && continueWatching?.length > setOptsWeb.slidesPerView"
                  class="arrows"
                  style="width: 100%"
                >
                  <ion-icon
                    name="chevron-back"
                    style="
                      cursor: pointer;
                      transform: scale(2);

                      z-index: 999;
                      background-color: rgba(0, 0, 0, 0.479);
                      border-radius: 0 50% 50% 0;
                    "
                    (click)="slideNext.slidePrev()"
                    size="large"
                  ></ion-icon>
                  <ion-icon
                    name="chevron-forward"
                    style="
                      cursor: pointer;
                      transform: scale(2);

                      z-index: 999;
                      background-color: rgba(0, 0, 0, 0.479);
                      border-radius: 50% 0 0 50%;
                    "
                    (click)="slideNext.slideNext();"
                    size="large"
                  ></ion-icon>
                </div>
                <ion-slides #slideNext [options]="setOptsWeb" class="videos">
                  <ion-slide
                    *ngFor="let item of continueWatching;let i = index"
                    [hidden]="item._id == playingVideo._id"
                  >
                    <ion-grid>
                      <ion-row>
                        <ion-col
                          class="container"
                          [id]="item._id+i+'_continueWatching'"
                          (click)="details(item, item._id, {})"
                        >
                          <!-- TO ShowTop10 Badges -->
                          <ion-badge
                            *ngIf="isTop10Video(item._id)"
                            color="warning"
                            class="top10Icon"
                            >Top10</ion-badge
                          >
                          <!-- TO Series Badges in MobileApp  -->
                          <ion-badge
                            *ngIf="!item.top10 && !item.type"
                            color="warning"
                            class="seriesIcon"
                            >Series
                          </ion-badge>

                          <!-- Show bag Badges on package Video   -->
                          <span
                            *ngIf="item?.packageIds?.length"
                            class="packageIcon"
                          >
                            <ion-icon
                              style="font-size: 24px"
                              color="warning"
                              name="bag-handle"
                            ></ion-icon>
                          </span>

                          <!-- Show Crown Badges on TVOD videos Video   -->
                          <span
                            *ngIf="(!item?.packageIds?.length && item?.TVOD?.price)"
                            class="tvodIcon"
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 -64 640 640"
                              fill="#FFD700"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"
                              />
                            </svg>
                          </span>
                          <!-- Show prograssive loader if image not loaded in webApp-->
                          <div>
                            <ion-thumbnail
                              *ngIf="!wacConfig.configuration.defaultPlaylists.imageLoaded"
                              slot="start"
                            >
                              <ion-skeleton-text animated></ion-skeleton-text>
                            </ion-thumbnail>
                          </div>

                          <img
                            [hidden]="!wacConfig.configuration.defaultPlaylists.imageLoaded"
                            (load)="wacConfig.configuration.defaultPlaylists.imageLoaded=true"
                            [alt]="item.title ? common.removeSpecialChar(item.title. substring(0,20)) : ''"
                            class="shadow"
                            [src]="wacConfig.configuration.defaultPlaylists?.continueWatching?.showHorizontal ? (item.horizontalThumbnail ? w400Replace(item.horizontalThumbnail) : environment['horizontalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', '')) : (item.verticalThumbnail ? w400Replace(item.verticalThumbnail) : environment['verticalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', ''))"
                          />
                          <!-- </img> -->
                          <div class="overlay">
                            <div class="text">
                              <h4
                                *ngIf="wacConfig.configuration.defaultPlaylists?.continueWatching?.showHorizontal"
                                style="
                                  font-size: 10px;
                                  font-weight: 600;
                                  margin: 0px;
                                "
                              >
                                {{item.title}}
                              </h4>
                              <p
                                *ngIf="wacConfig.configuration.defaultPlaylists?.continueWatching?.showHorizontal"
                                style="
                                  font-size: 10px;
                                  font-weight: 600;
                                  margin: 0px;
                                "
                              >
                                {{item.description?.substring(0,20)}}
                              </p>
                              <h4
                                *ngIf="!wacConfig.configuration.defaultPlaylists?.continueWatching?.showHorizontal"
                                style="
                                  font-size: 10px;
                                  font-weight: 600;
                                  margin: 0px;
                                "
                              >
                                {{item.title}}
                              </h4>
                              <p
                                *ngIf="!wacConfig.configuration.defaultPlaylists?.continueWatching?.showHorizontal"
                                style="
                                  font-size: 10px;
                                  font-weight: 600;
                                  margin: 0px;
                                "
                              >
                                {{item.description?.substring(0,50)}}
                              </p>
                              <div style="display: inline-grid">
                                <div
                                  class="hover-bottom"
                                  (click)="details(item, item._id, {},$event)"
                                >
                                  <ion-icon
                                    name="play"
                                    style="color: #ffffff"
                                  ></ion-icon>
                                  <span class="title-vid" style="color: #ffffff"
                                    >Watch Now</span
                                  >
                                </div>

                                <div
                                  *ngIf="item.watchLaterCheck"
                                  (click)="$event.preventDefault();
                                  $event.stopPropagation(); item.watchLaterCheck=!item.watchLaterCheck;onRemoveSavedVideo(item);"
                                  class="hover-watch-bottom"
                                >
                                  <svg class="icon icon-save sprite-icon-pluss">
                                    <use
                                      xlink:href="assets/tabs/sprite.svg#icon-saved"
                                    ></use>
                                  </svg>

                                  <span class="title-vid" style="color: #ffffff"
                                    >Watch Later</span
                                  >
                                </div>
                                <div
                                  *ngIf="!item.watchLaterCheck"
                                  (click)="$event.preventDefault();
                                  $event.stopPropagation(); item.watchLaterCheck=!item.watchLaterCheck;onSaveVideo(item);"
                                  class="hover-watch-bottom"
                                >
                                  <svg class="icon icon-save sprite-icon-pluss">
                                    <use
                                      xlink:href="assets/tabs/sprite.svg#icon-save"
                                    ></use>
                                  </svg>

                                  <span class="title-vid" style="color: #ffffff"
                                    >Watch Later</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="bottom-right"
                            *ngIf="wacConfig.configuration.defaultPlaylists?.continueWatching?.showName"
                          >
                            <ion-icon
                              name="play"
                              style="color: #ffffff"
                            ></ion-icon>
                            <span class="title-vid" style="color: #ffffff"
                              >{{item.title}}</span
                            >
                          </div>
                          <ion-progress-bar
                            color="primary"
                            value="0.5"
                          ></ion-progress-bar>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-slide>
                </ion-slides>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <!-- Watch later title -->
      <ion-row
        *ngIf="watchLaterplaylistss?.status == 1 && watchLaterplaylistss?.videos?.length > 0"
      >
        <ion-label
          *ngIf="watchLaterplaylistss && watchLaterplaylistss?.videos?.length > 0"
          class="head_lbl"
          style="text-transform: capitalize"
          >{{watchLaterplaylistss.name}}</ion-label
        >
      </ion-row>
      <!-- Watch later Data -->
      <ion-row
        *ngIf="watchLaterplaylistss?.status == 1 && watchLaterplaylistss?.videos?.length > 0"
      >
        <ion-col>
          <ion-row>
            <ion-col>
              <div class="mobile">
                <ion-slides
                  #slidelater
                  [options]="common.setOpts(watchLaterplaylistss, 'mob')"
                  class="videos"
                >
                  <ion-slide
                    *ngFor="let item of watchLaterplaylistss.videos;let i = index"
                    (click)="details(item, item._id, {},$event)"
                  >
                    <ion-grid>
                      <ion-row>
                        <ion-col>
                          <!-- TO ShowTop10 Badges -->
                          <ion-badge
                            *ngIf="isTop10Video(item._id)"
                            color="warning"
                            class="top10Icon"
                            >Top10</ion-badge
                          >
                          <!-- TO Series Badges in MobileApp  -->
                          <ion-badge
                            *ngIf="!item.top10 && !item.type"
                            color="warning"
                            class="seriesIcon"
                            >Series
                          </ion-badge>

                          <!-- Show bag Badges on package Video   -->
                          <span
                            *ngIf="item?.packageIds?.length"
                            class="packageIcon"
                          >
                            <ion-icon
                              style="font-size: 24px"
                              color="warning"
                              name="bag-handle"
                            ></ion-icon>
                          </span>

                          <!-- Show Crown Badges on TVOD videos Video   -->
                          <span
                            *ngIf="(!item?.packageIds?.length && item?.TVOD?.price)"
                            class="tvodIcon"
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 -64 640 640"
                              fill="#FFD700"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"
                              />
                            </svg>
                          </span>
                          <!-- Show prograssive loader if image not loaded in MobileApp-->
                          <div>
                            <ion-thumbnail
                              *ngIf="!watchLaterplaylistss.imageLoaded"
                              slot="start"
                            >
                              <ion-skeleton-text animated></ion-skeleton-text>
                            </ion-thumbnail>
                          </div>

                          <img
                            [hidden]="!watchLaterplaylistss.imageLoaded"
                            (load)="watchLaterplaylistss.imageLoaded=true"
                            [alt]="item.seriesName ?
                          item.seriesName.substring(0,9) : (item.title ? common.removeSpecialChar(item.title. substring(0,20)) : '')"
                            class="shadow"
                            [src]="watchLaterplaylistss.showHorizontal ? (item.horizontalThumbnail ? item.horizontalThumbnail : environment['horizontalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', '')) : (item.verticalThumbnail ? w400Replace(item.verticalThumbnail) : environment['verticalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', ''))"
                          />
                          <!-- </img> -->
                          <div
                            class="bottom-right"
                            *ngIf="watchLaterplaylistss?.showName"
                          >
                            <ion-icon
                              name="play"
                              style="color: #ffffff"
                            ></ion-icon>
                            <span class="title-vid" style="color: #ffffff"
                              >{{item.seriesName ?
                              item.seriesName.substring(0,9) :
                              item.title.substring(0,9)}}</span
                            >
                          </div>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-slide>
                </ion-slides>
              </div>
              <div class="web" style="position: relative">
                <div
                  *ngIf=" common.deviceType !='androidTv' && watchLaterplaylistss?.videos.length > setOptsWeb?.slidesPerView"
                  class="arrows"
                  style="width: 100%"
                >
                  <ion-icon
                    name="chevron-back"
                    style="
                      cursor: pointer;
                      transform: scale(2);

                      z-index: 999;
                      background-color: rgba(0, 0, 0, 0.479);
                      border-radius: 0 50% 50% 0;
                    "
                    (click)="slideNext.slidePrev()"
                    size="large"
                  ></ion-icon>
                  <ion-icon
                    name="chevron-forward"
                    style="
                      cursor: pointer;
                      transform: scale(2);

                      z-index: 999;
                      background-color: rgba(0, 0, 0, 0.479);
                      border-radius: 50% 0 0 50%;
                    "
                    (click)="slideNext.slideNext();"
                    size="large"
                  ></ion-icon>
                </div>
                <ion-slides
                  #slideNext
                  [options]="common.setOpts(watchLaterplaylistss, 'web')"
                  class="videos"
                >
                  <ion-slide
                    *ngFor="let item of watchLaterplaylistss.videos; let i = index"
                  >
                    <ion-grid>
                      <ion-row>
                        <ion-col
                          class="container"
                          (click)="details(item, item._id, {})"
                          [id]="item._id+i+'_watchLater' "
                        >
                          <!-- TO ShowTop10 Badges web  -->
                          <ion-badge
                            *ngIf="isTop10Video(item._id)"
                            color="warning"
                            class="top10Icon"
                            >Top10</ion-badge
                          >
                          <!-- TO Series Badges in MobileApp  -->
                          <ion-badge
                            *ngIf="!item.top10 && !item.type"
                            color="warning"
                            class="seriesIcon"
                            >Series
                          </ion-badge>

                          <!-- Show bag Badges on package Video   -->
                          <span
                            *ngIf="item?.packageIds?.length"
                            class="packageIcon"
                          >
                            <ion-icon
                              style="font-size: 24px"
                              color="warning"
                              name="bag-handle"
                            ></ion-icon>
                          </span>

                          <!-- Show Crown Badges on TVOD videos Video   -->
                          <span
                            *ngIf="(!item?.packageIds?.length && item?.TVOD?.price)"
                            class="tvodIcon"
                          >
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 -64 640 640"
                              fill="#FFD700"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"
                              />
                            </svg>
                          </span>
                          <!-- Show prograssive loader if image not loaded in webApp-->
                          <div>
                            <ion-thumbnail
                              *ngIf="!watchLaterplaylistss.imageLoaded"
                              slot="start"
                            >
                              <ion-skeleton-text animated></ion-skeleton-text>
                            </ion-thumbnail>
                          </div>

                          <img
                            [hidden]="!watchLaterplaylistss.imageLoaded"
                            (load)="watchLaterplaylistss.imageLoaded=true"
                            [alt]="item.seriesName ?
                          item.seriesName.substring(0,9) : (item.title ? common.removeSpecialChar(item.title. substring(0,20)) : '')"
                            class="shadow"
                            [src]="!watchLaterplaylistss.showHorizontal ? (item.verticalThumbnail ? w400Replace(item.verticalThumbnail) : environment['verticalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', '')) : (item.horizontalThumbnail ? w400Replace(item.horizontalThumbnail) : environment['horizontalDefaultImg'].replace('https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/', ''))"
                          />
                          <!-- </img> -->
                          <div class="overlay">
                            <div class="text">
                              <h4
                                *ngIf="wacConfig.configuration.defaultPlaylists?.watchLater?.showHorizontal"
                                style="
                                  font-size: 10px;
                                  font-weight: 600;
                                  margin: 0px;
                                "
                              >
                                {{item.seriesName ?
                                item.seriesName.substring(0,9) :
                                item.title.substring(0,9)}}
                              </h4>
                              <p
                                *ngIf="wacConfig.configuration.defaultPlaylists?.watchLater?.showHorizontal"
                                style="
                                  font-size: 10px;
                                  font-weight: 600;
                                  margin: 0px;
                                "
                              >
                                {{item.description?.substring(0,20)}}
                              </p>
                              <h4
                                *ngIf="!wacConfig.configuration.defaultPlaylists?.watchLater?.showHorizontal"
                                style="font-size: 12px; font-weight: 600"
                              >
                                {{item.title}}
                              </h4>
                              <p
                                *ngIf="!wacConfig.configuration.defaultPlaylists?.watchLater?.showHorizontal"
                                style="font-size: 12px; font-weight: 600"
                              >
                                {{item.description?.substring(0,50)}}
                              </p>

                              <div style="display: inline-grid">
                                <div
                                  class="hover-bottom"
                                  (click)="details(item, item._id, {},$event)"
                                >
                                  <ion-icon
                                    name="play"
                                    style="color: #ffffff"
                                  ></ion-icon>
                                  <span class="title-vid" style="color: #ffffff"
                                    >Watch Now</span
                                  >
                                </div>

                                <div
                                  *ngIf="item.watchLaterCheck"
                                  (click)="$event.preventDefault();
                                  $event.stopPropagation(); item.watchLaterCheck=!item.watchLaterCheck;onRemoveSavedVideo(item);"
                                  class="hover-watch-bottom"
                                >
                                  <svg class="icon icon-save sprite-icon-pluss">
                                    <use
                                      xlink:href="assets/tabs/sprite.svg#icon-saved"
                                    ></use>
                                  </svg>

                                  <span class="title-vid" style="color: #ffffff"
                                    >Watch Later</span
                                  >
                                </div>
                                <div
                                  *ngIf="!item.watchLaterCheck"
                                  (click)="$event.preventDefault();
                                  $event.stopPropagation(); item.watchLaterCheck=!item.watchLaterCheck;onSaveVideo(item);"
                                  class="hover-watch-bottom"
                                >
                                  <svg class="icon icon-save sprite-icon-pluss">
                                    <use
                                      xlink:href="assets/tabs/sprite.svg#icon-save"
                                    ></use>
                                  </svg>

                                  <span class="title-vid" style="color: #ffffff"
                                    >Watch Later</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="bottom-right"
                            *ngIf="watchLaterplaylistss.showName"
                          >
                            <ion-icon
                              name="play"
                              style="color: #ffffff"
                            ></ion-icon>
                            <span class="title-vid" style="color: #ffffff"
                              >{{item.title}}</span
                            >
                          </div>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-slide>
                </ion-slides>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
    <!-- // Share buttons for desktops & mobile web -->
    <div *ngIf="showDesktopSocial" class="showExpiry">
      <ion-card
        button="true"
        class="ion-padding"
        [ngClass]="platform.width()<900 ? 'mobileview_dialog' : 'desktopview_dialog'"
      >
        <ion-item style="margin-left: -20px">
          <ion-icon
            [ngClass]="platform.width()<900 ? 'mobileShareCloseIcon' : 'desktopShareClose'"
            name="close-circle"
            (click)="showDesktopSocial=false"
          ></ion-icon>
        </ion-item>
        <ion-grid>
          <ion-row class="ion-padding">
            <ion-col class="ion-padding">
              <ion-icon
                (click)="shareOnDesktop('facebook', playingVideo)"
                style="font-size: 3em; cursor: pointer"
                name="logo-facebook"
              ></ion-icon
              ><br />
              <ion-label style="margin-top: 9%">Facebook</ion-label>
            </ion-col>

            <ion-col class="ion-padding">
              <ion-icon
                (click)="shareOnDesktop('twitter', playingVideo)"
                style="font-size: 3em; cursor: pointer"
                name="logo-twitter"
              ></ion-icon
              ><br />
              <ion-label style="margin-top: 9%">Twitter</ion-label>
            </ion-col>

            <ion-col class="ion-padding">
              <ion-icon
                (click)="shareOnDesktop('whatsapp', playingVideo)"
                style="font-size: 3em; cursor: pointer"
                name="logo-whatsapp"
              ></ion-icon
              ><br />
              <ion-label style="margin-top: 9%">Whatsapp</ion-label>
            </ion-col>

            <ion-col class="ion-padding">
              <ion-icon
                (click)="shareOnDesktop('mail', playingVideo)"
                style="font-size: 3em; cursor: pointer"
                name="mail-unread"
              ></ion-icon
              ><br />
              <ion-label style="margin-top: 9%">Mail</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- <share-buttons [theme]="'modern-dark'"
            [include]="['facebook','twitter','whatsapp','email']"
            [show]="4"
            [size]="4"
            [url]="{{config.appLink ? config.appLink : ('https://ott.mogiio.com/share/' + config.appId)}}"
            [title]="{{videoService.videos[videoService.index].title.replaceAll('','')}}"
            [description]="{{formatStr(videoService.videos[videoService.index].description)}}"
            [image]="{{selectImg(videoService.videos[videoService.index].horizontalThumbnail, videoService.videos[videoService.index].thumbnails[0])}}"
            [autoSetMeta]="false"
        ></share-buttons> -->
      </ion-card>
    </div>
    <!-- <div *ngIf="giveRating" class="rateBg">
      <ion-card class="rating-card">
        <p style="margin: 0px; text-align: center">
          <ion-icon
            (click)="ratingValue=1;"
            style="font-size: 36px"
            [name]="ratingValue >= 1 ? 'star' : 'star-outline' "
            (click)="rating( $event)"
          >
          </ion-icon>
          <ion-icon
            (click)="ratingValue=2;"
            style="font-size: 36px"
            [name]="ratingValue >= 2 ? 'star' : 'star-outline' "
            (click)="rating( $event)"
          >
          </ion-icon>
          <ion-icon
            (click)="ratingValue=3;"
            style="font-size: 36px"
            [name]="ratingValue >= 3 ? 'star' : 'star-outline' "
            (click)="rating( $event)"
          >
          </ion-icon>
          <ion-icon
            (click)="ratingValue=4;"
            style="font-size: 36px"
            [name]="ratingValue >= 4 ? 'star' : 'star-outline' "
            (click)="rating( $event)"
          >
          </ion-icon>
          <ion-icon
            (click)="ratingValue=5;"
            style="font-size: 36px"
            [name]="ratingValue >= 5 ? 'star' : 'star-outline' "
            (click)="rating( $event)"
          >
          </ion-icon>
        </p>
      </ion-card>
    </div> -->
  </div>
</ion-content>
