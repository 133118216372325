import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AppTimeService {
  constructor(private http: HttpClient) {}

  interval: any;
  firstVideoPlayed: boolean = false;

  get(url: string): Promise<any> {
    return new Promise((resolve, reject) =>
      this.http.get(url).subscribe(resolve, reject)
    );
  }

  async sendAppTime({ startTime, endTime, user }) {
    const data = await this.get(
      `${environment.baseUrl}/misc/appTime?startTime=${startTime}&endTime=${endTime}` +
        `${user?.appId ? '&orgId=' + user.appId : ''}` +
        `${user?._id ? '&userId=' + user._id : ''}`
    );
    return data;
  }

  async sendVideoPlayed({ delay, user }) {
    const data = await this.get(
      `${environment.baseUrl}/misc/appTime/played?delay=${delay}` +
        `${user?.appId ? '&orgId=' + user.appId : ''}` +
        `${user?._id ? '&userId=' + user._id : ''}`
    );
    return data;
  }

  getUser() {
    return localStorage.getItem('user');
  }
  previousAppTime() {
    return localStorage.getItem('appTime');
  }
  async sendPreviousAppTime() {
    let appTime: any = this.previousAppTime();
    if (appTime) appTime = JSON.parse(appTime);
    // await this.playedVideo();
    if (appTime?.startTime && appTime?.endTime) {
      const startTime = new Date(appTime.startTime).getTime();
      const endTime = new Date(appTime.endTime).getTime();
      let user: any = this.getUser();
      if (user) user = JSON.parse(user);
      const data = await this.sendAppTime({
        startTime,
        endTime,
        user,
      });
    }
  }

  newAppTime() {
    const startTime = Date.now();
    const endTime = Date.now();
    localStorage.setItem(
      'appTime',
      JSON.stringify({
        startTime,
        endTime,
      })
    );
  }

  async playedVideo() {
    try {
      if (!this.firstVideoPlayed) {
        const appTime = JSON.parse(this.previousAppTime());
        let user = this.getUser();
        if (user) user = JSON.parse(user);
        const { startTime } = appTime;
        const delay = Date.now() - +startTime;
        const data = await this.sendVideoPlayed({
          delay,
          user,
        });
        this.firstVideoPlayed = true;
      }
    } catch (err) {
      console.error(err);
    }
  }

  newEndTime() {
    try {
      const appTime = this.previousAppTime();
      if (appTime) {
        let { startTime, endTime } = JSON.parse(appTime);
        endTime = Date.now();
        localStorage.setItem(
          'appTime',
          JSON.stringify({
            startTime,
            endTime,
          })
        );
      }
    } catch (error) {
      console.error('No previous app time found.', error);
    }
  }

  async appOpened() {
    try {
      await this.sendPreviousAppTime();
      this.newAppTime();
      clearInterval(this.interval);
      this.interval = setInterval(() => this.newEndTime(), 30000);
    } catch (err) {
      console.error(err);
    }
  }

  clearMonitor() {
    clearInterval(this.interval);
  }
}
