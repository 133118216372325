import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';


import { HomeSkeletonPage } from './home-skeleton.page';

@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [HomeSkeletonPage],
  exports: [HomeSkeletonPage]
})
export class HomeSkeletonPageModule {}
