<ion-header
  class="ion-header"
  [ngClass]="platform.is('ios') ? 'ion-padding-ios' : 'ion-padding'"
>
  <h3>Buy now to watch this Video</h3>
</ion-header>
<ion-content class="ion-padding">
  <div style="height: 100%; display: flex; flex-direction: column">
    <!-- justify-content: space-between; -->

    <div *ngIf="!platform.is('ios')" class="input-block">
      <ion-input
        style="background: var(--ion-color-step-100)"
        type="text"
        #coupon
        (keyup)="setCouponBtnStr()"
        minlength="5"
        maxlength="20"
        required
        fieldSize="large"
        placeholder="Coupon Code (Optional)"
      ></ion-input>
      &nbsp;&nbsp;<span
        class="apply-Button"
        style="cursor: pointer"
        [class]="couponBtnString.css"
        (click)="applyCoupon(coupon.value)"
        >{{ couponBtnString.text }}</span
      >
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      "
    >
      <div>
        <div class="button-next">
          <div style="display: flex; align-items: center; gap: 5px">
            <h3
              *ngIf="
                !couponDiscount && price && couponDiscount !== 0 && India !== null
              "
            >
              <span> Price:</span>
              {{ India ? "&#8377;" : "$" }}{{ price }}
            </h3>
            <h3 *ngIf="couponDiscount || couponDiscount === 0">
              <span>Discounted Price: </span>
              <del id="real-price">{{ India ? "&#8377;" : "$" }}{{ price }}</del>
              <span id="selling-price"
                >{{ India ? "&#8377;" : "$" }}{{ couponDiscount }}</span
              >
            </h3>
          </div>
          <ion-button id="nextBtn" (click)="done()">
            {{ buttonString }}</ion-button
          >
        </div>
        <h3 *ngIf="validity">Validity: {{validity}}</h3>
      </div>
      <ion-button class="mobClose" (click)="close()" expand="full"
        >Close</ion-button
      >
    </div>
  </div>
</ion-content>
