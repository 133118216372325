import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { resolve } from 'dns';
import { HttpProvider } from '../http.service';
import { WacConfigService } from '../wac-config/wac-config.service';

@Injectable({
  providedIn: 'root',
})
export class TrendingService {
  public top10Videos = [];

  constructor(
    private http: HttpProvider,
    private waconfigService: WacConfigService
  ) {}

  public async getTrendingVideo(
    videoAppId: string = this.waconfigService.configuration['videoAppId']
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`/mott/trendings/?videoAppId=${videoAppId}&limit=15`)
        .subscribe(
          (res) => {
            let videos = res['data'] ? res['data'] : [];
            if (videos.legnth) {
              if (videos.length <= 10) {
                videos = videos.map((video) => (video['top10'] = true));
              } else {
                videos = videos.forEach((video, index) => {
                  if (index < 10) {
                    video['top10'] = true;
                  }
                  return video;
                });
                console.log(videos);
              }
            }
            resolve(videos);
          },
          (error) => {
            console.log('logging the error');
            console.log(error);
          }
        );
    });
  }

  public async getTop10Video(
    videoAppId: string = this.waconfigService.configuration['videoAppId']
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.top10Videos.length) {
        resolve(this.top10Videos);
      } else {
        this.http
          .get(`/mott/trendings/?videoAppId=${videoAppId}&limit=10`)
          .subscribe(
            (response) => {
              resolve(response['data'] ? response['data'] : []);
              if (response['data'].length > 10) {
                this.top10Videos = response['data'].slice(0, 10);
              } else {
                this.top10Videos = response['data'];
              }
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          );
      }
    });
  }

  isTop10Video(id: string): boolean {
    return !!this.top10Videos.find((video) => video._id === id);
  }
}
