import { Injectable } from '@angular/core';
import { HttpProvider } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class RecommendService {

  constructor(private http: HttpProvider) { }

  getRecommendVideo(limit?, delta?) {
    return new Promise((resolve, reject) => {
      this.http.get("/recommend?limit=" + limit + "&delta=" + delta).subscribe(res => {
        resolve(res['data']);
        console.log("recommend", res['data']);
      });
    });
  }
}
