import { Injectable, OnDestroy, Inject } from '@angular/core';
// import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { ContinueWatchingService } from '../continue-watching/continue-watching.service';
import { HttpProvider } from '../http.service';
import { UserService } from '../user/user.service';
import { WacConfigService } from '../wac-config/wac-config.service';
import { WatchHistoryService } from '../watch-history/watch-history.service';
import { WatchLaterService } from '../watch-later/watch-later.service';
import { Subscription } from 'rxjs';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PlaylistService implements OnDestroy {
  public trendingVideos = [];
  private top10Videos = [];
  private watchLaterVideos: any = [];
  private channels = new Map<string, any>();
  private _playlists = new Map<string, any>();
  private primaryChannel: string;
  private userLoggedInSubs$: Subscription;

  currentPlaylist: any;
  playingVideo: any;
  currentPlayingvideo: any;
  playlist: any;
  currentPlaylistId: any;
  currentSeries: any;
  playListId: any;
  play;
  ind: any;
  seriesIndex = 0;
  playlists: any;

  private isBrowser: boolean;

  constructor(
    private http: HttpProvider,
    private nativeStorage: NativeStorage,
    private userService: UserService,
    public continueWatchingService: ContinueWatchingService,
    public watchHistoryService: WatchHistoryService,
    public watchLaterService: WatchLaterService,
    public wacConfigService: WacConfigService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.refreshPrimaryChannelPlaylists().then();
  }

  /**
   * @description - gets the channel's Playlist with content
   * @param {string} channelId -
   * @param {boolean} primary -
   * @param {number} limit -
   * @param {number} delta -
   */
  public getChannelPlaylists(
    channelId: string,
    limit = 10,
    delta = 0,
    primary = false
  ): Promise<any[]> {
    return new Promise((resolve, reject) => {
      try {
        console.log('testing:- channels', this.channels.has(channelId));
        if (this.channels.has(channelId)) {
          const playlistsChunk = this.channels
            .get(channelId)
            .slice(limit * delta, limit * delta + limit);
          // resolve(playlistsChunk);
          resolve(this.channels.get(channelId));
        } else {
          this.http
            .get(`/channels/${channelId}?limit=${limit}&delta=${delta}`)
            .subscribe(async (res) => {
              const playlists =
                res['data'] && res['data']['playlists']
                  ? res['data']['playlists']
                  : [];

              // console.log('playlists =>', res['data'], playlists);
              this.channels.set(channelId, playlists);
              if (primary) {
                this.primaryChannel = channelId;
               await this.appendCustomPlaylists(channelId);
              }
              // if (this.isBrowser) {
                await this.getWatchLaterVideos();
                await this.checkForWatchLaterContent(playlists);
                this.checkForTop10Content(playlists);
              // }

              this.channels.set(channelId, playlists);
              const playlistsChunk = playlists.slice(
                limit * delta,
                limit * delta + limit
              );
              // resolve(playlistsChunk);
              resolve(this.channels.get(channelId));
            });
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  unsetChannels() {
    this.channels = new Map<string, any>();
  }

  /**
   * @description - append trending and continue watching to playlists response
   * @param {string} channelId -
   */
  continueWatching;
  public async appendCustomPlaylists(channelId: string): Promise<void> {
    if (this.channels.has(channelId)) {
      const trending = await this.constructTrendingPlaylist();
      this.continueWatching = await this.constructContinueWatchingPlaylist();
      this.channels
        .get(channelId)
        .splice(1, 0, this.continueWatching, trending);
      this.channels.set(channelId, this.channels.get(channelId));
    }
  }

  /**
   * @description - gets trending videos
   */
  public getTrendingVideos(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (!this.trendingVideos.length) {
          const { videoAppId } = this.wacConfigService.configuration;
          this.http
            .get(`/mott/trendings/?videoAppId=${videoAppId}&limit=15`)
            .subscribe(
              (res) => {
                this.trendingVideos = res['data'] ? res['data'] : [];
                this.trendingVideos = [
                  ...new Map(
                    this.trendingVideos.map((item) => [item['_id'], item])
                  ).values(),
                ];
                resolve(this.trendingVideos);
                this.saveTop10Videos();
              },
              (error) => {
                console.log(error);
                resolve([]);
              }
            );
        } else {
          this.trendingVideos = [
            ...new Map(
              this.trendingVideos.map((item) => [item['_id'], item])
            ).values(),
          ];
          resolve(this.trendingVideos);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * @description - gets continue watching videos
   */
  public getContinueWatchingVideos(): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.http.get(`/ott/continue-watching/${user['_id']}`).subscribe(
          (res) => {
            let processedRes = [],
              t;
            if (res) {
              t = res;
              t.forEach((element) => {
                processedRes.push(element.video);
              });
            }

            resolve(processedRes);
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        resolve([]);
      }
    });
  }

  /**
   * @description - gets continue watching videos
   */
  public getWatchHistoryVideos(): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.http.get(`/ott/watch-history/${user['_id']}`).subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        resolve([]);
      }
    });
  }

  /**
   * @description - gets watch later videos
   */
  /**
   * @description - gets continue watching videos
   */
  public getWatchLaterVideos(): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        if (!this.watchLaterVideos.length) {
          this.http.get(`/ott/watch-later/${user['_id']}`).subscribe(
            (res) => {
              this.watchLaterVideos = res;
              resolve(res);
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          resolve(this.watchLaterVideos);
        }
      } else {
        resolve([]);
      }
    });
  }

  /**
   * @description - gets trending videos
   */
  public constructTrendingPlaylist(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { trending } =
          this.wacConfigService.configuration.defaultPlaylists;
        trending['name'] = 'Trending';
        trending['content'] = trending['videos'] =
          await this.getTrendingVideos();
        if (trending && trending.status) {
          trending['content'].forEach(
            (content) => (content['top10'] = this.isTop10(content._id))
          );
        } else {
          trending['content'] = [];
        }
        resolve(trending);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * @description - gets trending videos
   */
  public constructWatchLaterPlaylist(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { watchLater } =
          this.wacConfigService.configuration.defaultPlaylists;
        watchLater['name'] = 'Watch Later';
        const watchLaterResponse = await this.getWatchLaterVideos();

        watchLater['content'] = watchLater['videos'] =
          watchLaterResponse && watchLaterResponse.length
            ? watchLaterResponse.map((item) => item['video'])
            : [];

        watchLater['content'].forEach(
          (content) => (content['top10'] = this.isTop10(content._id))
        );
        resolve(watchLater);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * @description - gets trending videos
   */
  public constructWatchHistoryPlaylist(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { watchHistory } =
          this.wacConfigService.configuration.defaultPlaylists;
        watchHistory['name'] = 'Watch History';
        const watchHistoryResponse = await this.getWatchHistoryVideos();

        watchHistory['content'] = watchHistory['videos'] =
          watchHistoryResponse && watchHistoryResponse.length
            ? watchHistoryResponse.map((item) => item['video'])
            : [];

        watchHistory['content'].forEach(
          (content) => (content['top10'] = this.isTop10(content._id))
        );
        resolve(watchHistory);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * @description - gets trending videos
   */
  public constructContinueWatchingPlaylist(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { continueWatching } =
          this.wacConfigService.configuration.defaultPlaylists;
        continueWatching['name'] = 'Continue Watching';
        continueWatching['content'] = continueWatching['videos'] =
          await this.getContinueWatchingVideos();
        if (continueWatching && continueWatching.status) {
          continueWatching['content'].forEach(
            (content) => (content['top10'] = this.isTop10(content._id))
          );
        } else {
          continueWatching['content'] = [];
        }
        resolve(continueWatching);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * @description - save Top10 videos from the trending videos
   */
  public saveTop10Videos(): void {
    if (this.trendingVideos.length > 10) {
      this.top10Videos = this.trendingVideos.slice(0, 10);
    } else {
      this.top10Videos = this.trendingVideos;
    }
  }

  /**
   * @description - life cycle hook for clean up
   */
  public ngOnDestroy(): void {
    if (this.userLoggedInSubs$) this.userLoggedInSubs$.unsubscribe();
  }

  public getPlaylistById(id, limit = 20, delta = 0) {
    return new Promise((resolve, reject) => {
      try {
        this.http
          .get(`/playlists/${id}?limit=${limit}&delta=${delta}`)
          .subscribe((res) => {
            console.log('testing:- ', res['data']);
            resolve(res['data']);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * @description - save Top10 videos from the trending videos
   */
  private isTop10(videoId: string): boolean {
    if (!this.top10Videos) return false;
    return !!this.top10Videos.find((video) => video._id === videoId);
  }

  /**
   * @description - checks the content of playlists if it is top 10
   */
  private checkForTop10Content(playlists: any[]): void {
    playlists.forEach((playlist) => {
      playlist.content.forEach((content) => {
        content['top10'] = this.isTop10(content._id);
      });
    });
  }

  /**
   * @description - checks the content of playlists if it is top 10
   */
  private async checkForWatchLaterContent(playlists: any[]): Promise<void> {
    await this.getWatchLaterVideos();
    playlists.forEach((playlist) => {
      playlist.content.forEach((content) => {
        this.watchLaterVideos.forEach((watchedContent) => {
          content['watchLaterCheck'] = content._id === watchedContent._id;
        });
      });
    });
  }

  /**
   * @description - checks the content of playlists if it is top 10
   */
  private async refreshPrimaryChannelPlaylists(): Promise<void> {
    this.userLoggedInSubs$ = this.userService.userLoggedIn.subscribe(
      async () => {
        const continueWatching = await this.constructContinueWatchingPlaylist();
        if (this.channels.has(this.primaryChannel)) {
          this.channels.set(
            this.primaryChannel,
            this.channels
              .get(this.primaryChannel)
              .splice(1, 0, continueWatching)
          );
        }
      }
    );
  }

  getPlaylistVideo(id, limit?, delta?) {
    return new Promise((resolve, reject) => {
      // if(this.playlist) resolve(this.playlist);

      this.http
        .get('/playlists/' + id + '?limit=' + limit + '&delta=' + delta)
        .subscribe((res) => {
          resolve(res['data']);
        });
    });
  }

  getSeriesBySeriesName(seriesName) {
    return new Promise((resolve, reject) => {
      this.http.get('/series/?name=' + seriesName).subscribe((res) => {
        resolve(res['data']);
      });
    });
  }

  // getSeriesById(id): Promise<any[]> {
  //   return new Promise((resolve, reject) => {
  //     this.http.get("/series/" + id).subscribe(res => {
  //       console.log('testing:- got the response from api');
  //       console.log('testing:- ', res['data']);
  //       resolve(res['data']);
  //     });
  //   });
  // }

  async getSeriesById(id) {
    console.log('Devtest71');
    return new Promise((resolve, reject) => {
      this.http
        .get('/series/' + id + '?delta=0' + '&limit=50')
        .subscribe((res) => {
          console.log('testing:- got the response from api');
          console.log('testing:- ', res['data']);
          resolve(res['data']);
        });
    });
  }

  getSeasons(name, limit, delta) {
    return new Promise((resolve, reject) => {
      let url = '/playlists/?seriesName=' + name;
      if (limit) url += '?limit=' + limit;
      if (delta) url += '&delta=' + delta;
      this.http.get(url).subscribe((res) => {
        resolve(res['data']);
      });
    });
  }

  getVideoPosition(videoId) {
    return new Promise((resolve, reject) => {
      let user = this.userService.getLocalUser();
      // .then(user => {
      this.http
        .get('/ott/users/' + user['_id'] + '/videoTracker/' + videoId)
        .subscribe((res) => {
          console.log('resssss', res);
          if (res && res['data']) {
            resolve(res['data'].position);
          } else resolve(0);
        });
      // });
    });
  }

  getPlaylistByType(type, limit?, delta?): Promise<any[]> {
    return new Promise((resolve, reject) => {
      try {
        this.http
          .get(
            '/playlists?type=' + type + '&limit=' + limit + '&delta=' + delta
          )
          .subscribe((res) => {
            if (res['status'].code == 200 && res['data']) {
              resolve(res['data']);
            } else resolve([]);
          });
      } catch (error) {
        console.log(error);
      }
    });
  }

  getSearchResult(search) {
    return new Promise((resolve, reject) => {
      this.http
        .get('/drives/?search=' + search + '&consumer=app')
        .subscribe((res) => {
          console.log(res['data']);
          resolve(res['data']);
        });
    });
  }

  getVideoById(id) {
    return new Promise((resolve, reject) => {
      try {
        this.http.get(`/drives/${id}`).subscribe((res) => {
          console.log('video ===>>> res', res['status'], res['data']);
          if (res['data'] == null) {
            this.continueWatchingService.deleteContinueWatching({ _id: id });
            // this.watchHistoryService.deleteWatchHistory({_id: id});
            this.watchLaterService.deleteWatchLater({ _id: id });
            this.watchHistoryService.deleteSingleWatchHistory({ _id: id });
            // console.log("get element by id is null")
          }
          if (res['status'] && res['status'].code == 200) resolve(res['data']);
          else reject(res['status']);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  get currentPlayingVideo() {
    return this.playingVideo;
  }
}
