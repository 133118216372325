/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable no-underscore-dangle */
/* eslint-disable eqeqeq */
/* eslint-disable curly */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-trailing-spaces */
import {
  Component,
  ViewEncapsulation,
  HostListener,
  OnInit,
  Input,
} from '@angular/core';
import {
  NavController,
  ToastController,
  Platform,
  ModalController,
} from '@ionic/angular';
import { CouponService } from 'src/app/services/coupon/coupon.service';
import { LocationService } from 'src/app/services/location/location.service';

@Component({
  selector: 'app-tvod-options',
  templateUrl: './tvod-options.component.html',
  styleUrls: ['./tvod-options.component.scss'],

})
export class TvodOptionsComponent implements OnInit {
  @Input() video: any;

  validity;
  price = 0;
  currency: any = '';
  couponDiscount: any = null;
  coupon: any = null;
  India = null;

  buttonString = 'Buy Now';
  couponBtnString = {
    text: 'Apply',
    css: 'apply',
  };
  constructor(
    public location: LocationService,
    public couponService: CouponService,
    public toastController: ToastController,
    public modalController: ModalController,
    public platform: Platform,
  ) {}

  async ngOnInit() {
    // temp solution
    // this.pkgInfo['payments'] = [...this.arr];
    // console.log('pkg@ pkg Info::', this.pkgInfo);
    this.setPriceDetails();
    this.setValidityDetails();
    // console.log('skjdbvsldv;sdjklvbslkdvnklsdnvlksdnvlsvnlkn', this.video);
  }

  setValidityDetails() {
    this.validity = '';
    this.video.TVOD.validity = '' + this.video?.TVOD?.validity;
    const v: any = this.video?.TVOD?.validity.split('.');
      if (v[0] && (v[0] != '0')) this.validity += (v[0] == 1) ? v[0] + ' Day  ' : v[0] + ' Days  ';
      if (v[1] && (v[1] != '0')) this.validity += (v[1] == 1) ? v[1] + ' Hour  ' : v[1] + ' Hours  ';
      if (v[2] && (v[2] != '0')) this.validity += (v[2] == 1) ? v[2] + ' Min. ' : v[2] + ' Mins. ';
  }

  async setPriceDetails() {
    if (this.video?.TVOD?.price) this.price = this.video?.TVOD?.price;
    this.currency = await this.location.getCurrency();
    if (this.currency == 'INR') return this.India = true;
    this.India = false;
  }

  async applyCoupon(coupon: any) {
    if (!coupon.length) {
      this.presentToast('Enter Coupon Code!');
      return;
    }
    // console.log(coupon)

    try {
      const res: any = await this.couponService.getCouponByName(
        coupon.toString(),
        this.video?._id
      );

      if (res) {
        if (res.noOfUse && res.noOfUse > 0) {
          if (res.noOfUse <= res.noOfTimesUsed) {
            this.couponBtnString.text = 'EXPIRED!';
            this.couponBtnString.css = 'invalid';
            this.presentToast('Coupon Code Expired!');
            return;
          }
        }

        this.coupon = { ...res };
        this.applyCouponChanges();
      }
    } catch (e) {
      this.couponBtnString.text = 'INVALID!';
      this.couponBtnString.css = 'invalid';
      this.presentToast('Invalid Coupon Code!');
    }
  }

  applyCouponChanges() {
    // this.coupon = this.coupon;
    this.couponDiscount = this.getDiscountPrice();
    this.couponBtnString.text = 'APPLIED!';
    this.couponBtnString.css = 'applied';
    this.presentToast('Coupon Code Applied!');
    return;
  }

  getDiscountPrice() {
    if (this.coupon.type === 1) {
      if (this.currency == 'INR') {
        return this.price - this.coupon.discount.rupee < 1
          ? 0
          : this.price - this.coupon.discount.rupee;
      }
      return this.price - this.coupon.discount.dollar < 1
        ? 0
        : this.price - this.coupon.discount.dollar;
    } else {
      const d = Math.ceil((this.coupon.discount.percent / 100) * this.price);
      console.log(d, this.coupon.discount.percent, this.price);
      return this.price - d < 1 ? 0 : this.price - d;
    }
  }

  resetCoupon() {
    this.couponBtnString.text = 'APPLY';
    this.couponBtnString.css = 'apply';
    this.couponDiscount = null;
    this.coupon = null;
  }

  setCouponBtnStr() {
    this.couponDiscount = false;
    this.couponBtnString.text = 'Apply';
    this.couponBtnString.css = 'cursor: pointer;';
  }

  async presentToast(message) {
    // console.log(message);
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'bottom',
      cssClass: 'authTosterMessage',
      animated: true,
      buttons: [
        {
          side: 'end',
          icon: 'close-outline',
        },
      ],
    });
    toast.present();
  }

  done() {
    this.modalController?.dismiss(
      this.coupon
        ? {
            ...this.coupon,
            couponDiscount: this.couponDiscount,
            currency: this.currency,
          }
        : null,
      'confirm'
    );
  }

  close() {
    this.modalController?.dismiss();
  }
}
