import { Injectable } from '@angular/core';
import { resolve } from 'dns';
import { HttpProvider } from '../http.service';
import { WacConfigService } from '../wac-config/wac-config.service';

@Injectable({
  providedIn: 'root',
})
export class ContinueWatchingService {
  constructor(public wacConfig: WacConfigService, public _http: HttpProvider) {}
  async addCountinueWatching(countinueWatching) {
    if (!localStorage) return;
    let user = JSON.parse(localStorage.getItem('user'));
    countinueWatching['date'] = new Date();
    let data = {
      userId: user._id,
      video: countinueWatching,
      appId: this.wacConfig.configuration._id || '',
      videoId: countinueWatching._id,
      status: 1,
    };
    return new Promise((resolve, reject) => {
      this._http.post('/ott/continue-watching', data).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  deleteContinueWatching(video) {
    if (!localStorage || !video) return;
    let user = JSON.parse(localStorage.getItem('user'));
    let data = {
      status: -1,
    };
    if (user && user['_id']) {
      return new Promise((resolve, reject) => {
        this._http
          .put(
            '/ott/continue-watching/' + user['_id'] + '/video/' + video._id,
            data
          )
          .subscribe(
            (res) => {
              resolve(res);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
  }

  async getContinueWatching() {
    if (!localStorage) return;
    let user = JSON.parse(localStorage.getItem('user'));
    if (user)
      return new Promise((resolve, reject) => {
        this._http.get('/ott/continue-watching/' + user['_id']).subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
  }
  deleteContinueWatchingForPerticularUser(user) {
    return new Promise((resolve, reject) => {
      try {
        this._http.delete('/ott/continue-watching/' + user._id).subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      } catch (e) {
        console.log('logging the error form deletin');
      }
    });
  }
}
