import {
  Component,
  ElementRef,
  Output,
  OnInit,
  ViewChild,
  EventEmitter,
  Inject,
  OnDestroy,
  Input,
  NgZone,
} from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { PlaylistService } from 'src/app/services/playlist/playlist.service';
import { UserService } from 'src/app/services/user/user.service';
import { WacConfigService } from 'src/app/services/wac-config/wac-config.service';
// import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';

// import { element } from 'protractor';
// import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';
import { ContinueWatchingService } from 'src/app/services/continue-watching/continue-watching.service';
import { Location, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { RecommendService } from 'src/app/services/recommend/recommend.service';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IframeComponent implements OnInit, OnDestroy {
  @Input() setVideoFrameHeight: number;
  @Input() setVideoFrameWidth: number;
  @Output() someEvent = new EventEmitter<string>();
  @Output() newVideoEvent = new EventEmitter<string>();
  @Output() newVideoUrls = new EventEmitter<any>();
  @Output() portrait = new EventEmitter<any>();

  @ViewChild('iframe', { static: true }) iframe: ElementRef;
  id;
  src;
  thumbnail;
  position: any;
  duration: any;
  endTime: any;
  currenTime: any;
  stateFlag = false;
  video: any;
  location: number[];

  isVideoPause = true;

  playlistVideos = [];
  recommendation: any;

  isLandscape = false;

  showCustomFullscreenBtn = false;

  constructor(
    private playlistService: PlaylistService,
    private wacConfig: WacConfigService,
    private androidFullScreen: AndroidFullScreen,

    private userService: UserService,
    private screenOrientation: ScreenOrientation,
    public continueWatchingService: ContinueWatchingService,
    private common: CommonService,
    public platform: Platform,
    public recommendService: RecommendService,
    private ngZone: NgZone,

    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnChanges() {
    this.ngZone.run(() => {
      this.setVideoFrameHeight;
      this.setVideoFrameWidth;
    });
  }

  async ngOnInit() {
    this.common.getIframeFullscreenListner().subscribe((isLandscape) => {
      this.handleFullScreen(isLandscape);
    });
  }

  setVideo(video, trailer?) {
    this.setEventListner();
    this.video = video;
    this.video['currentTime'] = 0;

    this.stateFlag = false;
    if (trailer) {
      this.iframe.nativeElement.setAttribute('src', this.video.trailerUrl);
      this.src = this.video.trailerUrl;
    } else {
      this.iframe.nativeElement.setAttribute(
        'src',
        this.video.webhookResponse.playerUrl + `?autoplay=1&mute=1`
      );
      this.src = this.video.webhookResponse.playerUrl;
      // if(this.src.includes('youtube')) setTimeout(()=> this.showCustomFullscreenBtn = true, 3000);
      setTimeout(() => (this.showCustomFullscreenBtn = true), 2000);
    }
    // this.iframe.nativeElement.setAttribute('src', this.video.webhookResponse.playerUrl);
    //   this.src = this.video.webhookResponse.playerUrl;
  }

  customFullscreen() {
    if (
      this.platform.is('android') ||
      (this.platform.is('ios') && !this.platform.is('mobileweb'))
    ) {
      const iframe = this.iframe.nativeElement;
      // if (this.platform.width() > 900) return;
      // this.screenOrientation.unlock();
      if (
        !this.isLandscape &&
        this.screenOrientation.type.search('landscape') < 0
      ) {
        // if(this.screenOrientation.type.search("landscape") < 0 )
        this.screenOrientation.lock(
          this.screenOrientation.ORIENTATIONS.LANDSCAPE
        );
        // this.iframe.nativeElement.style.transform = "rotate(90deg)";
        this.isLandscape = true;
        this.portrait.next(!this.isLandscape);
        this.immersiveModeOn();
        return;
      }
      if (
        this.isLandscape ||
        this.screenOrientation.type.search('portrait') < 0
      ) {
        this.screenOrientation.lock(
          this.screenOrientation.ORIENTATIONS.PORTRAIT
        );
        // this.iframe.nativeElement.style.transform = "rotate(0deg)";
        this.isLandscape = false;
        this.portrait.next(!this.isLandscape);
        this.immersiveModeOff();
        return;
      }
    }

    if (this.platform.width() > 900) {
      const player = this.iframe.nativeElement;

      if (document.fullscreenElement === player) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (player.webkitExitFullscreen) {
          player.webkitExitFullscreen();
        } else if (player.mozCancelFullScreen) {
          player.mozCancelFullScreen();
        } else if (player.msExitFullscreen) {
          player.msExitFullscreen();
        }
      } else {
        player.requestFullscreen();
      }
      return;
    }
  }

  setPlaylistVideo(playlistVideos) {
    this.playlistVideos = playlistVideos || [];
    // if (this.iframe && this.iframe.nativeElement) {

    // this.iframe.nativeElement.contentWindow
    //       .postMessage({
    //         playlist: this.playlistVideos
    //       }, '*');
    //       return;

    // }
    // setTimeout(() => { this.setPlaylistVideo(playlistVideos); }, 100);
  }

  sendVideoSug() {
    this.iframe.nativeElement.contentWindow.postMessage(
      {
        playlist: this.playlistVideos,
      },
      '*'
    );
    return;
  }

  seekToTime(timeDuration) {
    this.iframe.nativeElement.contentWindow.postMessage(
      { seekTo: this.currenTime + timeDuration },
      '*'
    );
  }

  async playVideo() {
    try {
      if (this.iframe && this.iframe.nativeElement) {
        this.setEventListner();

        await this.seek();

        if (
          this.wacConfig.configuration &&
          this.wacConfig.configuration.onPlayLandscape &&
          (this.platform.is('android') || this.platform.is('ios'))
        ) {
          this.handleFullScreen(true);
        }
        this.isVideoPause = false;

        let user;
        user = await this.userService.getLocalUser();

        this.iframe.nativeElement.contentWindow.postMessage(
          {
            userId: user?._id,
            video: this.video,
          },
          '*'
        );

        if (
          this.wacConfig?.configuration &&
          this.wacConfig?.configuration?.qrForPlayer
        ) {
          this.iframe.nativeElement.contentWindow.postMessage(
            {
              qr: { ...this.wacConfig.configuration.qrForPlayer },
            },
            '*'
          );
        }

        this.sendVideoSug();

        return this.iframe.nativeElement.contentWindow.postMessage('play', '*');
      }
      setTimeout(() => {
        this.playVideo();
      }, 100);
    } catch (error) {
      console.error('our iframe playVideo()', error);
    }
  }

  setIframeUrl(url) {
    this.stateFlag = false;

    this.iframe.nativeElement.setAttribute('src', url);
    this.src = url;
  }

  setEventListner(trailer?) {
    try {
      var eventMethod = window.addEventListener
        ? 'addEventListener'
        : 'attachEvent';

      var eventer = window[eventMethod];

      var messageEvent =
        eventMethod === 'attachEvent' ? 'onmessage' : 'message';

      // Rahul: not looking right to me
      if (this.platform.is('ios')) {
        this.iframe.nativeElement.contentWindow.postMessage({ ios: true }, '*');
      }
      if (
        this.platform.is('android') ||
        (this.platform.is('ios') && !this.platform.is('mobileweb'))
      ) {
        window.addEventListener('unload', () => {
          this.screenOrientation.lock(
            this.screenOrientation.ORIENTATIONS.PORTRAIT
          );
        });
      }

      eventer(messageEvent, async (e) => {
        try {
          if (e.data.duration) this.duration = e.data.duration;

          if (e.data.currentTime) {
            this.currenTime = e.data.currentTime;
          }
          if (e.data.autoplayed) {
            this.newVideoEvent.next(e.data.video);
            this.video = e.data.video;
            this.seek();
            // this.video = e.data.video;
            // this.seekTo();
          }
          if (e.data.playReady) {
            this.someEvent.next('somePhone');
            // this.seekTo();
          }

          if (e.data?.userId && e.data?.userId == 'required') {
            let user = await this.userService.getLocalUser();
            if (user) {
              this.iframe.nativeElement.contentWindow.postMessage(
                {
                  userId: user?._id,
                },
                '*'
              );
            }
          }

          if (e.data.videoUrls) {
            this.newVideoUrls.next(e.data.videoUrls);

            // check if m3u8 available then push m3u8 only
            // if(trailer){
            //   this.newVideoUrls.next(e.data.videoUrls);

            // } else {
            //   this.newVideoUrls.next(e.data.videoUrls);

            // }
            // this.playlistService.playerUrl = e.data.videoUrls ;
          }
          // if(e.data){
          //   this.endTime=e.data.ended
          //   this.recommendation = this.recommendService.getRecommendVideo();
          // }

          let data = e.data;
          // this.currenTime = e.data;

          if (typeof data != 'string' && data && 'fullscreen' in data) {
            this.handleFullScreen(data.fullscreen);
          }
        } catch (e) {
          console.error('inside catch error: Eventer ' + e);
        }
      });
    } catch (error) {}
  }

  handleFullScreen(fullscreen) {
    if (
      this.platform.is('android') ||
      (this.platform.is('ios') && !this.platform.is('mobileweb'))
    ) {
      if (this.platform.width() > 900) return;
      // this.screenOrientation.unlock();
      if (fullscreen && this.screenOrientation.type.search('landscape') < 0) {
        // if(this.screenOrientation.type.search("landscape") < 0 )
        this.screenOrientation.lock(
          this.screenOrientation.ORIENTATIONS.LANDSCAPE
        );
        this.immersiveModeOn();
      } else if (
        !fullscreen ||
        this.screenOrientation.type.search('portrait') < 0
      ) {
        this.screenOrientation.lock(
          this.screenOrientation.ORIENTATIONS.PORTRAIT
        );
        this.immersiveModeOff();
      }
    }
  }

  immersiveModeOn() {
    this.androidFullScreen
      .isImmersiveModeSupported()
      .then(() => {
        this.androidFullScreen.immersiveMode();
        // this.androidFullScreen.showUnderSystemUI();
      })
      .catch((err) => console.error(err));
  }

  immersiveModeOff() {
    this.androidFullScreen
      .isImmersiveModeSupported()
      .then(() => {
        this.androidFullScreen.showSystemUI();
      })
      .catch((err) => console.error(err));
  }

  async seek() {
    try {
      if (!this.video) return;

      // let continueWatchingVideos = JSON.parse(localStorage.getItem('continueWatching'));
      let continueWatchingVideos: any =
        await this.continueWatchingService.getContinueWatching();

      continueWatchingVideos.forEach((element) => {
        if (element.video._id == this.video._id) {
          this.video['currentTime'] = element.video.currentTime;
          // this.onPlay(element.video.currentTime);
          this.iframe.nativeElement.contentWindow.postMessage(
            { seekTo: element.video.currentTime },
            '*'
          );
        }
      });
    } catch (error) {
      console.error('errror in seek', error);
    }
  }

  async setContinueWatching(position = this.currenTime) {
    try {
      if (
        !this.video &&
        !this.duration &&
        position <= 0 &&
        this.duration == position
      ) {
        return;
      }

      // Check if the difference between the current and new positions is less than 10 seconds
      if (
        this.video.currentTime &&
        Math.abs(position - this.video.currentTime) < 10
      ) {
        return;
      }

      const percentPosition = 0.2 * this.duration;
      const leftPosition = this.duration - position;

      if (
        percentPosition < 30
          ? position > 30
          : position > percentPosition && leftPosition >= 21
      ) {
        // Add the video to the continue watching list
        const progressBar = position / this.duration;

        // Update the video object with the new position and duration
        this.video.currentTime = position;
        this.video.duration = this.duration;
        this.video.progressBar = progressBar;

        // Call the continue watching service to add the video
        const continueWatchingVideo =
          await this.continueWatchingService.addCountinueWatching(this.video);

        // Update the playlist with the new continue watching list
        this.playlistService.continueWatching.content.unshift(this.video);
        this.playlistService.continueWatching.content = [
          ...new Map(
            this.playlistService.continueWatching.content.map((item) => [
              item._id,
              item,
            ])
          ).values(),
        ];
      }

      if (leftPosition <= 20) {
        // If the video is near the end, remove it from the continue watching list
        this.continueWatchingService.deleteContinueWatching(this.video);
      }
    } catch (error) {
      console.error('setContinueWatching', error);
    }
  }

  pauseVideo() {
    if (this.iframe && this.iframe.nativeElement) {
      this.isVideoPause = true;
      return this.iframe.nativeElement.contentWindow.postMessage('pause', '*');
    }
    setTimeout(() => {
      this.pauseVideo();
    }, 100);
  }

  pauseVideoTrailer() {
    this.isVideoPause = true;
    this.setEventListner();
    return this.iframe.nativeElement.contentWindow.postMessage('pause', '*');
  }

  playVideoEvent() {
    if (this.iframe && this.iframe.nativeElement) {
      this.isVideoPause = false;
      return this.iframe.nativeElement.contentWindow.postMessage('play', '*');
    }
    setTimeout(() => {
      this.playVideoEvent();
    }, 100);
  }

  setAdsUrl(adsUrls) {
    if (this.iframe && this.iframe.nativeElement) {
      return this.iframe.nativeElement.contentWindow.postMessage(
        { adsUrls: adsUrls },
        '*'
      );
    }
    setTimeout(() => {
      this.setAdsUrl(adsUrls);
    }, 100);
  }

  /*=========================== DEAD Code ===================*/

  getLocation() {
    let t = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        t.location = [position.coords.latitude, position.coords.longitude];
      });
    } else {
    }
    return t.location ? t.location.toString() : '';
  }

  // setIframe() {
  //   setTimeout(() => {
  //     if (this.iframe)
  //       this.iframe.nativeElement.setAttribute('src', this.src);
  //   }, 1000);
  // }

  // async loadLastSeekToo(id) {
  //   let t = null;
  //   t = await this.playlistService.getVideoPosition(id);
  //   // console.log("seeeeek", t);
  //   if (t >= 0)
  //     return t;
  //   else return 0;
  // }

  // onPlay(seekTo) {
  //   try {
  //     // console.log("geetaaaV", seekTo);
  //     if (!this.video) return;
  //     let user = JSON.parse(localStorage.getItem('user'));

  //     if (this.iframe && this.iframe.nativeElement) {

  //       this.iframe.nativeElement.contentWindow
  //         .postMessage({
  //           userId: user._id,
  //           seekTo: seekTo || 0,
  //           video: this.video,
  //           adsVASTURL: this.wacConfig.configuration.adsVASTURL,
  //           playlist: (this.playlistService.playlist && this.playlistService.playlist.videos.length > 0) ? this.playlistService.playlist.videos : []
  //         }, '*');
  //       this.isVideoPause = false;
  //       this.iframe.nativeElement.contentWindow.postMessage('play', '*');

  //     }
  //   }
  //   catch (e) { console.log("error in play", e); }
  // }

  // iframeLoaded() {
  //   // let playlists = this.playlistService.playlist.videos;
  //   // let videos = [];
  //   // let playingVideo = this.video;
  //   // if(playlists){
  //   //   playlists.forEach(playlist => {
  //   //   if(playingVideo._id != playlist._id){
  //   //     videos.push(playlist);
  //   //   }
  //   // });
  //   // }

  //   console.log("iframeLoaded here with video : ", this.video );
  //   if (this.iframe) {
  //     this.iframe.nativeElement.contentWindow.postMessage(JSON.stringify({ activate: true }), '*');
  //     let user = JSON.parse(localStorage.getItem('user'));
  //     // console.log("user setting iframe ::::: ", user);
  //     this.iframe.nativeElement.contentWindow
  //       .postMessage({
  //         userId: (user && user._id ? user._id : (this.userService.user['_id'] ? this.userService.user['_id'] : '')),
  //         video: this.video,
  //         adsVASTURL: this.wacConfig.configuration ? this.wacConfig.configuration.adsVASTURL : [],
  //         playlist: this.playlistService.playlist ? this.playlistService.playlist.videos : []
  //       }, '*');
  //   }
  //   // console.log("this.iframe", this.video, this.playlistService.playlist.videos, this.iframe);
  //   // setTimeout(() => {
  //   //   this.pauseVideo();
  //   // }, 600);
  // }

  showPlayerControls(flag: Boolean) {
    this.iframe.nativeElement.contentWindow.postMessage(
      { showPlayerControl: flag },
      '*'
    );
  }
  async ngOnDestroy() {
    await this.setContinueWatching(this.currenTime);
  }
}
