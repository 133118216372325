<div *ngIf="this.commonService.deviceType != 'androidTv'" class="ads-slider-container">

  <div class="ads_slider" *ngIf="mogiSlider && mogiSlider.length && platform.width()>900">
    <div class="arrow">
    <ion-icon 
            *ngIf="mogiSlider.length > 2"
            name="chevron-back"
            style="
              cursor: pointer;
              transform: scale(2);

              z-index: 999;
              background-color: rgba(0, 0, 0, 0.479);
              border-radius: 0 50% 50% 0;
            "
            (click)="advtAds.slidePrev()"
            size="large"
          ></ion-icon>
          <ion-icon
            *ngIf="mogiSlider.length > 2"
            name="chevron-forward"
            style="
              cursor: pointer;
              transform: scale(2);

              z-index: 999;
              background-color: rgba(0, 0, 0, 0.479);
              border-radius: 50% 0 0 50%;
            "
            (click)="advtAds.slideNext();"
            size="large"
          ></ion-icon>
        </div>
    <ion-slides #advtAds [options]="setOptsWeb">
      <ion-slide *ngFor="let advertisment of mogiSlider">
        <ion-card
        [ngClass]="mogiSlider.length === 1? 'showSingaleAds' : '' "
        style="margin-bottom: -1px;">

          <!-- Show prograssive loader if image not loaded in webApp-->
          <!-- <div class="advt-loader">
            <ion-thumbnail *ngIf="!advertisment.imageLoaded" slot="start">
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-thumbnail>
          </div> -->

          <img (click)="openInNewTab(advertisment.link)" target="_blank"
            [src]="advertisment.file ? advertisment.file : environment['horizontalDefaultImg']"
            [alt]="advertisment.title">
          <ion-badge color="warning" class="adIcon">Ad</ion-badge>
        </ion-card>
      </ion-slide>
    </ion-slides>
  </div>

  <!-- To Show Mogi Ad in App  -->
  <div *ngIf="platform.width()<900" class="advtApp">
    <swiper [effect]="'cards'" [grabCursor]="true" class="mySwiper" >
      <ng-template swiperSlide *ngFor="let advertisment of mogiSlider">
        <!-- <ion-card> -->
          <ion-card-content>

          <!-- <div class="advt-loader">
            <ion-thumbnail *ngIf="!advertisment.imageLoaded" slot="start">
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-thumbnail>
          </div> -->

          <img style="border-radius: 10px;" (click)="openInNewTab(advertisment.link)" target="_blank"
            [src]="advertisment.file ? advertisment.file : environment['horizontalDefaultImg']"
            [alt]="advertisment.title">
          <ion-badge color="warning" class="adIconMob">Ad</ion-badge>
        </ion-card-content>
        <!-- </ion-card> -->
        </ng-template
      >
    </swiper>
  </div>
  </div>
<!-- </div> -->
