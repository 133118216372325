export class MultiRegionHandler {
  USER_COUNTRY: Object;
  TIME_ZONES: Object;
  domain: string;

  MULTI_REGION_BASE_API = (domain: string) => ({
    Europe: { 'Europe/London': `eu-${domain}` },
    America: {
      'America/Los_Angeles': `us-lv-${domain}`,
      'America/Toronto': `ca-tr-${domain}`,
    },
  });
  
  userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  userCountry =
    Intl.DateTimeFormat().resolvedOptions().timeZone.split(`/`)?.[0] ??
    `NotCounty`;
    constructor(domain: string) {
      this.domain = domain;
    }
  
    getRandomTimeZone() {
      const randomIndex = Math.floor(
        Math.random() * Object.keys(this.USER_COUNTRY).length
      );
      return (
        this.USER_COUNTRY?.[Object.keys(this.USER_COUNTRY)?.[randomIndex] ?? 0] ??
        this.domain
      );
    }
  
    timeZoneInstancesSwitcher = () => {
      this.TIME_ZONES = this.MULTI_REGION_BASE_API(this.domain);
      this.USER_COUNTRY = this.TIME_ZONES[this.userCountry] ?? false;
      if (!this.USER_COUNTRY) return this.domain;
      return this.USER_COUNTRY?.[this.userTimeZone] ?? this.getRandomTimeZone();
    };
}