import { Injectable } from '@angular/core';
import { AdMob, AdOptions, BannerAdOptions, BannerAdSize, BannerAdPosition, RewardAdOptions } from '@capacitor-community/admob';
import { WacConfigService } from '../wac-config/wac-config.service';

@Injectable({
  providedIn: 'root'
})
export class AdmobService {
  constructor(
    public wacConfig: WacConfigService
  ) { }

  //ad Unit Start -------------->
  async initialize() {
    const { status } = await AdMob.trackingAuthorizationStatus();

    if (status === 'notDetermined') {
      console.log('imhere64');

    }

    AdMob.initialize({
      requestTrackingAuthorization: true,
      //  testingDevices: ['c0c16343-4b40-4fdd-8ce7-879a1e6f7fc5'],
      //  initializeForTesting: false,
    });
  }

  async interstitial() {
    try {
     if(!this.wacConfig.configuration) await this.wacConfig.setConfiguration();

      const { adMobs } = this.wacConfig.configuration?.adMobs ? this.wacConfig.configuration : { adMobs : [] };
      const interstialAds = adMobs.filter(ad => ad.type === 'interstitial');
      console.log(interstialAds);

      if (interstialAds && interstialAds.length) {
        const options: AdOptions = {
          // adId: 'ca-app-pub-8677002238987951/1894028763',
          adId: interstialAds[0]['adUnitId'],

          isTesting: false,
          // npa: true
        };
        await AdMob.prepareInterstitial(options);
        await AdMob.showInterstitial();
      }
    } catch (error) {
      console.log(error);
    }
  }


  async showBanner() {
    try {
      if(!this.wacConfig.configuration) await this.wacConfig.setConfiguration();

      const { adMobs } = this.wacConfig.configuration?.adMobs ? this.wacConfig.configuration : { adMobs : [] } ;
      const bannerAds = adMobs.filter(ad => ad.type === 'banner');

      console.log(bannerAds);

      if (bannerAds && bannerAds.length) {
        const options: BannerAdOptions = {
          //  adId: 'ca-app-pub-8677002238987951/4476955923',
          adId: bannerAds[0]['adUnitId'],
          adSize: BannerAdSize.BANNER,
          position: BannerAdPosition.BOTTOM_CENTER,
          margin: 0,
          isTesting: false,
          // npa: true
        };
        AdMob.showBanner(options);
      }
    } catch (error) {
      console.log(error);
    }
  }


  async showRewardVideo() {
    try {
      if(!this.wacConfig.configuration) await this.wacConfig.setConfiguration();

      const { adMobs } = this.wacConfig.configuration?.adMobs ? this.wacConfig.configuration : { adMobs : [] };
      const rewardedAds = adMobs.filter(ad => ad.type === 'rewarded');

      console.log(rewardedAds);

      if (rewardedAds && rewardedAds.length) {
        const options: RewardAdOptions = {
          adId: rewardedAds[0]['adUnitId'],
          isTesting: false,
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  //ad Unit END -------------->
  async hideBanner(){
    await AdMob.hideBanner();
    await AdMob.removeBanner();
  }
}


