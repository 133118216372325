import { Injectable, RendererFactory2, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';


@Injectable({
  providedIn: 'root'
})

export class SeoService {
  private _renderer2: Renderer2;
  imageWidth = null;

  constructor(
    private httpClient: HttpClient, private titleService: Title,
    private metaService: Meta,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document,
    @Optional() @Inject(REQUEST) private request: Request
  ) {
    this._renderer2 = rendererFactory.createRenderer(null, null);
    this.createLinkForCanonicalURL();
  }


  isMobile(userAgent) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(userAgent.substr(0, 4))) {
      return true;
    }
    else return false;
  }

  setImageWidth() {
    try {
      if (this.imageWidth) return this.imageWidth;

      this.imageWidth = this.isMobile(this.request.headers['user-agent']) ? 'w350' : 'w800';
      return this.imageWidth;
    }
    catch (err) {
      return 'w800';
    }
  }

  async setSEOTags(title, des, img, url, keywords) {
    // if(localStorage) return;
    try {
      if(!Object.keys(localStorage).length) {
        throw new Error('Running on SSR');
      }

      if (localStorage)
        JSON.parse(localStorage.getItem('user'));
    }
    catch (error) {
      console.log("inside setSEOTags catch :: ");
      this.titleService.setTitle(title);
      this.metaService.updateTag({ name: 'description', content: des });
      this.metaService.updateTag({ name: 'keywords', content: keywords });
      // Twitter
      this.metaService.updateTag({ property: 'twitter:card', content: 'summary_large_image' });
      this.metaService.updateTag({ property: 'twitter:domain', content: url });
      this.metaService.updateTag({ property: 'twitter:url', content: url });
      this.metaService.updateTag({ property: 'twitter:title', content: title });
      this.metaService.updateTag({ property: 'twitter:description', content: des });
      this.metaService.updateTag({ property: 'twitter:image', content: 'https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/' + img });
      // Facebook
      this.metaService.updateTag({ property: 'og:url', content: url });
      this.metaService.updateTag({ property: 'og:type', content: 'website' });
      this.metaService.updateTag({ property: 'og:description', content: des });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ property: 'og:image', content: 'https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w400/' + img });
    }

  }

  seoLdJson(configuration, pageName?, videoObj?) {
    // if(localStorage) return;
    try {
      if (localStorage)
        JSON.parse(localStorage.getItem('user'));
    } catch (error) {

      console.log("videoObj ::: ", videoObj, pageName);
      if(pageName && configuration.seoConfig && configuration.seoConfig[pageName] && configuration.seoConfig[pageName].schemaLdJson) {
        let schemaLdJson = configuration.seoConfig[pageName].schemaLdJson;

        for(let i=0; i<schemaLdJson.length; i++) {
          let script = this._renderer2.createElement('script');
          script.type = `application/ld+json`;

          schemaLdJson[i] = `${schemaLdJson[i].replace("<script type=\"application/ld+json\">", '').replace(/<\/script\>/g, '') }`;


          if(pageName == "player") {
            schemaLdJson[i] = schemaLdJson[i].replace(/\"thumbnailUrl\":\"\"/ , `"thumbnailUrl":"${videoObj.thumbnailUrl}"`)
            schemaLdJson[i] = schemaLdJson[i].replace(/\"duration\":\"\"/ , `"duration":"${videoObj.duration}"`)
            schemaLdJson[i] = schemaLdJson[i].replace(/\"uploadDate\":\"\"/ , `"uploadDate":"${videoObj.goLiveTime}"`)

          }

          script.text = schemaLdJson[i]

          this._renderer2.appendChild(this._document.body, script);

        }

      }

      else {
        let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `
                  {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "${configuration.appName}",
                    "url": "${configuration.appUrl}",
                    "logo": "https://mott-img.b-cdn.net/q80-efalse-ptrue-fauto-w50/${configuration.logoUrl}",
                    "sameAs": [ ]
                  }
                  `;

                  // sameAs : [ Social site links like fb/insta/twitter etc ]
        this._renderer2.appendChild(this._document.body, script);

        script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `
                  {
                      "@context": "https://schema.org/",
                      "@type": "WebSite",
                      "name": "${configuration.appName}",
                      "url": "${configuration.appUrl}",
                      "potentialAction": {
                          "@type": "SearchAction",
                          "target": "{search_term_string}",
                          "query-input": "required name=search_term_string"
                      }
                  }
                  `;

        this._renderer2.appendChild(this._document.body, script);
      }
    }

  }

  createLinkForCanonicalURL( ) {
    try{
      let link: HTMLLinkElement = this._document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this._document.head.appendChild(link);
      link.setAttribute('href', this._document.URL);
    }
    catch(err) {
      console.log("ours setting canonicalUrl : ", err);
    }
  }



}
