import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AdmobService } from 'src/app/services/admob/admob.service';
import { CommonService } from 'src/app/services/common/common.service';
import { UserService } from 'src/app/services/user/user.service';
import { WacConfigService } from 'src/app/services/wac-config/wac-config.service';
import { environment } from 'src/environments/environment';

// import Swiper core and required modules
import SwiperCore, { EffectCards } from 'swiper';

// install Swiper modules
SwiperCore.use([EffectCards]);

// SwiperCore.use([EffectCoverflow, Navigation, Pagination]);

@Component({
  selector: 'app-ads-slider',
  templateUrl: './ads-slider.component.html',
  styleUrls: ['./ads-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdsSliderComponent implements OnInit {
  public setOptsWeb: any = {
    slidesPerView: 2.05,
    spaceBetween: -12,
  };
  isUserPaid = false;

  // setOptsApp = {
  //   slidesPerView: 2,
  //   spaceBetween: -12
  // };
  // setOptsWeb = {
  //   slidesPerView: 2,
  //   spaceBetween: -12
  // };

  // Mogiio Branding Hard Coded Slider array
  mogiSlider = [];

  environment = environment;

  constructor(
    public wacConfig: WacConfigService,
    public commonService: CommonService,
    public userService: UserService,
    public platform: Platform,
    public adMobService: AdmobService,
    private changeDetectorRef: ChangeDetectorRef,

  ) {}

  ngOnInit() {
    const response = this.getAds();
    // this.getAdMobInterstitial();
  }

  // // To get AdMob Functions
  // public async getAdMobInterstitial(): Promise<void> {
  //   this.adMobService.initialize();
  //   await this.adMobService.interstitial();
  // }

  // adding mogiio advt. if client is not paid Start---->
  public async getAds(): Promise<void> {
    await this.checkForPaidUser();
    const thirdPartyLinks = [];
    if (
      this.wacConfig.configuration &&
      this.wacConfig.configuration.thirdPartyLinks &&
      this.wacConfig.configuration.thirdPartyLinks.length > 0
    ) {
      thirdPartyLinks.push(...this.wacConfig.configuration.thirdPartyLinks);
    }

    if (this.isUserPaid) {
      this.mogiSlider = thirdPartyLinks;
      // console.log('mogiSlider 57', this.mogiSlider);
    } else {
      this.mogiSlider.push({
        file: 'https://cdn6.mogiio.com/61a08e625fcfdd2152d764a2/2022/05/12/package-thumbnails/MogiOTT1.png',
        link: 'https://mogiio.com/',
        status: 1,
        title: "MogiI/O [India's Most Popular OTT SAAS Platform]",
        type: 'jpg',
      });
      this.mogiSlider.push(...thirdPartyLinks);
      // console.log('mogislider 58', this.mogiSlider);
    }
    // console.log('mogiSlider', this.mogiSlider);
    if (this.mogiSlider && this.mogiSlider.length === 1) {
      this.setOptsWeb['slidesPerView'] = 1;
    }
    setTimeout(() => {
    this.changeDetectorRef.detectChanges();

    }, 1000);

  }

  async checkForPaidUser() {
    try {
      let checkPaid = (this.isUserPaid = await this.userService.isUserPaid());
    } catch (e) {
      return (this.isUserPaid = false);
    }
  }
  // adding mogiio advt. if client is not paid END---->

  openInNewTab(link) {
    window.open(link, '_blank');
  }
}
