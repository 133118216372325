<ion-content *ngIf="src" style="width: 100vw; height: 100vh;">
  <video [ngStyle]="{'background-color': splashBackground || '353919017162-433me9icip917i3f8m2s5ram8ivt5vhu.apps.googleusercontent.com'}"
  (ended)="dismiss()"
  (onerror)="dismiss()"
  (onplay)="splashVideoStarted()"
  (play)="splashVideoStarted()"
  preload="metadata" autoplay="autoplay" [poster]="poster" webkit-playsinline="webkit-playsinline" playsinline style="width: 100vw; height: 100vh;">
   <source [src]="src" type="video/mp4" />
  </video>
</ion-content>
