/* eslint-disable curly */
import {
  Component,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AlertController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';
import { PlaylistService } from 'src/app/services/playlist/playlist.service';
import { WacConfigService } from 'src/app/services/wac-config/wac-config.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { CommonService } from 'src/app/services/common/common.service';
import { OrderService } from 'src/app/services/order/order.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { RecommendService } from 'src/app/services/recommend/recommend.service';
import { TrendingService } from 'src/app/services/trending/trending.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DummyService } from 'src/app/services/dummy/dummy.service';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/services/alert/alert.service';
import { IframeComponent } from './iframe/iframe.component';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { SeedService } from 'src/app/services/seed/seed.service';
import { SeoService } from 'src/app/services/seo/seo.service';
import { WatchHistoryService } from 'src/app/services/watch-history/watch-history.service';
import { WatchLaterService } from 'src/app/services/watch-later/watch-later.service';
import { ContinueWatchingService } from 'src/app/services/continue-watching/continue-watching.service';
import { DOCUMENT } from '@angular/common';
// import { DpadHandlerService } from 'src/app/services/dpadHandler/dpadHandler.service';
import { AdmobService } from 'src/app/services/admob/admob.service';
import { TvodOptionsComponent } from './tvod-options.component';
import { AdvtConfigsService } from './advt-configs/advt-configs.service';
import { Share } from '@capacitor/share';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { AppTimeService } from 'src/app/services/appTime/app-time.service';
import { HttpProvider } from 'src/app/services/http.service';
import { CouponService } from 'src/app/services/coupon/coupon.service';
import { TvodIapService } from 'src/app/services/in-app-purchase/inAapPurchase.service';
import { StripeService } from 'src/app/services/stripe/stripe.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.page.html',
  styleUrls: ['./player.page.scss'],
})
export class PlayerPage implements OnInit, OnDestroy {
  thumbnail = '';
  environment = environment;
  @ViewChild(IframeComponent, { static: false }) playerIframe: IframeComponent;
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  cjs;
  src;
  shareEnable: boolean = false;
  playingVideo;
  playlistVideos;
  ratingValue = 0;
  giveRating;
  recommendation: any;
  watchLaterplaylistss: any = {};
  showRating: string;
  iframeParam;
  watchLater = [];
  watchLaterCheck: boolean = false;
  releaseDate;
  more: boolean = false;
  loaderGif = false;
  trailerPlay = false;
  setOptsApp = {
    slidesPerView: 2.5,
    spaceBetween: -12,
  };
  seriesVideos = [];
  setOptsWeb = {
    slidesPerView: 6.5,
    spaceBetween: -12,
  };
  loaded: boolean = false;
  continueWatching = [];
  series;
  existUser: boolean = false;
  watchLaterplaylists = [];
  activeId;
  currentYear = new Date().toDateString().split(' ').slice(1).join(' ');

  screenHeight: number;
  screenWidth: number;
  showDesktopSocial = false;
  buyPackageMessage;
  buyPackageId;
  watchHistory = [];
  pastId;

  elementArr = [];

  elementArrI = -1;
  elementArrJ = -1;
  isSeasonSelected = false;
  selectedSeasonIndex = -1;
  trendingData;
  videoPlaying = false;
  showPlaylistLable = false;

  videoId = '';
  seriesId = '';
  playlistIdFromUrl = '';
  resp = [];
  inactiveMultipleToster: boolean = false;
  showCastButton: boolean = false;

  portrait = true;
  isClicked = false;

  order = null;

  setVideoFrameHeight:number;
  setVideoFrameWidth:number;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private zone: NgZone,
    public common: CommonService,
    public dummy: DummyService,
    public wacConfig: WacConfigService,
    public playlistService: PlaylistService,
    private userService: UserService,
    public toastController: ToastController,
    public paymentService: PaymentService,
    private socialSharing: SocialSharing,
    public recommendService: RecommendService,
    private router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    public alertController: AlertController,
    public trendingService: TrendingService,
    public orderService: OrderService,
    public commonService: CommonService,
    private navCtrl: NavController,
    public alertCtrl: AlertController,
    public seedService: SeedService,
    public seoService: SeoService,
    private insomnia: Insomnia,
    @Inject(DOCUMENT) private document: Document,
    public watchHistoryService: WatchHistoryService,
    public watchLaterService: WatchLaterService,
    public continueWatchingService: ContinueWatchingService,
    public platform: Platform,
    // private backgroundMode: BackgroundMode,
    // public dpadHandlerService: DpadHandlerService,
    public adMobService: AdmobService,
    // public http: HttpClient,
    private modalController: ModalController,
    public tvodOptions: TvodOptionsComponent,
    private advtConfigsService: AdvtConfigsService,
    public device: Device,
    private appTimeService: AppTimeService,
    private http: HttpProvider,
    private couponService: CouponService,
    private inAppPurchaseService: TvodIapService,
    public stripe: StripeService
  ) {
    console.log('player page is called');
  }

  ngOnInit() {
    console.log(
      'woo ngOnInit called',
      this.common.activeChannelId,
      this.playingVideo
    );
    // await this.wacConfig.setConfiguration();
    this.videoPlaying = false;
    this.loaded = false;
    this.commonService.tab = 'player';
  }

  showCrewCastSlider() {
    if (this.platform.is('desktop')) {
      return this.setOptsWeb;
    } else {
      return this.setOptsApp;
    }
  }

  async ionViewWillEnter() {
    // this.setDpadArr();
    this.startInsomia();
    this.parseUrl();
    await this.loadVideo();
    await this.loadPackages();
    this.getAdMobBanner();
    this.getAdMobRewardVideo();
    this.registerInAppPurchaseProducts(); // register In-app purchase

    console.log("loggggg",this.setVideoFrameHeight,this.setVideoFrameWidth)
    // this.route.queryParams.subscribe(params => {
    //   if (params?.videoPlaying && params?.videoPlaying == 'true') {
    //     this.playVideo(this.playingVideo);
    //   }
    // });
    if (sessionStorage.getItem('temp-last-url'))
      sessionStorage.setItem('temp-last-url', 'used');
  }

  // Register in app products for tvod videos when enter in the player page -
  async registerInAppPurchaseProducts() {
    try {
      console.log('logging the player page ::::', this.playingVideo);
      if (
        this.platform.is('android') &&
        this.playingVideo.TVOD.productIdAndroid
      ) {
        this.inAppPurchaseService.registerProducts(
          this.playingVideo.TVOD.productIdAndroid
        );
      } else if (
        this.platform.is('ios') &&
        this.playingVideo.TVOD.productIdIos
      ) {
        this.inAppPurchaseService.registerProducts(
          this.playingVideo.TVOD.productIdIos
        );
      }
    } catch (err) {
      console.log(
        `error occurred in registerIAP : ${JSON.stringify(err.message)}`
      );
    }
  }

  // To get AdMob Ads
  public async getAdMobBanner(): Promise<void> {
    this.adMobService.initialize();
    this.adMobService.showBanner();
  }

  // To get AdMob Ads
  public async getAdMobRewardVideo(): Promise<void> {
    this.adMobService.initialize();
    this.adMobService.showRewardVideo();
  }

  async removeBannerAd() {
    // await AdMob.removeBanner();
  }

  changeOrientation(e) {
    this.portrait = e;
    console.log(
      '\n\n\n\n\n\n\nOrientation change ::\n\n\n\n\n\n\n\n',
      e,
      this.portrait
    );
  }

  async checkConditionsToShowCast(video) {
    try {
      if (this.isTVOD(video)) {
        const purchased = await this.getVideoTransactionStatus(video);
        console.log('check for CastButton2 TVOD', purchased);
        if (purchased['isPurchased']) {
          return (this.showCastButton = true);
        }
      }

      if (this.checkConditionsforSVODChromeCast(video)) {
      }

      // if (this.wacConfig.configuration.playWithoutLogin) {
      //   console.log('check for CastButton4',this.wacConfig.configuration.playWithoutLogin);
      //   return this.showCastButton = true;
      // }

      // if (this.existUser){
      //   console.log('check for CastButton1', await this.checkUserLoggedIn());
      //   return this.showCastButton = true;
      // }
    } catch (error) {
      console.log(error);
    }
  }

  async checkConditionsforSVODChromeCast(video) {
    const userPackage: any = await this.userService.getUserValidPackages();
    if (!userPackage) return;
    let thisVideoPackage;
    for (let i = 0; i < video.packageIds.length; i++) {
      thisVideoPackage = userPackage.find(
        (o) => o.pkgId === video.packageIds[i]
      );
      console.log('thisVideoPackage for cast', thisVideoPackage);
      if (thisVideoPackage) break;
    }

    if (thisVideoPackage) {
      console.log('check for CastButton3', thisVideoPackage);
      return (this.showCastButton = true);
    }
  }

  ionViewWillLeave() {
    this.removeBannerAd();
    console.log('188leave');
  }

  parseUrl() {
    const urlArr = this.router.url.split('-');

    this.playlistIdFromUrl = urlArr.pop();
    this.videoId = urlArr[urlArr.length - 1];
    urlArr[urlArr.length - 2].length == 24
      ? (this.seriesId = urlArr[urlArr.length - 2])
      : '';
    console.log(
      'player page is called',
      'playlistIdFromUrl :: ',
      this.router.url,
      urlArr,
      this.playlistIdFromUrl,
      this.videoId
    );
  }
  async getVideoTransactionStatus(video: any) {
    try {
      const purchased: any = await this.orderService.isAlreadyPurchasedVideo(
        video._id,
        video.TVOD?.validity
      );
      // console.log("validity",video,video.TVOD.validity)
      return purchased;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  // setDpadArr() {
  //   if (this.commonService.deviceType != 'androidTv') return;

  //   this.dpadHandlerService.elementArr = [];

  //   this.dpadHandlerService.setup();

  //   this.dpadHandlerService.headId = 'player_header';

  //   this.dpadHandlerService.defaultAction['left'] = () => {
  //     this.playerIframe.seekToTime(-10);
  //   };
  //   this.dpadHandlerService.defaultAction['right'] = () => {
  //     this.playerIframe.seekToTime(10);
  //   };
  //   this.dpadHandlerService.defaultAction['enter'] = () => {
  //     console.log('i got called');
  //     this.playPauseToggle();
  //   };
  //   this.dpadHandlerService.defaultAction['85'] = () => {
  //     console.log('i got called 85');
  //     this.playPauseToggle();
  //   };

  //   this.dpadHandlerService.defaultAction['126'] = () => {
  //     console.log('i got called 126');
  //     this.playPauseToggle();
  //   };

  //   this.dpadHandlerService.defaultAction['127'] = () => {
  //     console.log('i got called 127');
  //     this.playPauseToggle();
  //   };
  //   // .push([{
  //   //   action: this.playerIframe.seekToTime(this.playerIframe.currenTime - 10);
  //   // }]);
  // }

  async loadVideo() {
    try {
      this.playerUrls = [];
      this.playingVideo = await this.playlistService.getVideoById(this.videoId);
      this.setVideoFrameHeight=  this.playingVideo?.videoMetadata?.height || null;
      this.setVideoFrameWidth= this.playingVideo?.videoMetadata?.width || null;
      console.log("@@@@",this.setVideoFrameHeight,this.setVideoFrameWidth)
      // increasing the quality of thumbnail
      this.playingVideo.horizontalThumbnail =
        this.playingVideo.horizontalThumbnail.replace('w500', 'w1000');
      // console.log('\n\n\n\n\n\nplayer page video\n\n\n\n\n', this.playingVideo.horizontalThumbnail)
      this.loaded = true;

      try {
        if (this.playingVideo.webhookResponse.playerUrl.includes('youtube')) {
          this.videoPlaying = true;
        }
      } catch (error) {
        console.log('error in getting Youtube URL', error);
      }

      try {
        if (this.playingVideo && this.playingVideo.AVOD) {
          await this.setAVOD(this.playingVideo);
        }
      } catch (e) {
        console.log('\n\nError in preloading app ::\n', e);
      }

      this.checkConditionsToShowCast(this.playingVideo);
      console.log('Logging Cast', this.playingVideo);

      // rahul looked wrong to me
      this.singleWatchLaterChecks(this.playingVideo);
      this.setupSeo();
      await this.loadAllPlaylists();
      console.log('playlistVideos 233', this.playingVideo, this.playlistVideos);
      // setTimeout(() => {

      // }, 1000);
    } catch (error) {
      console.log('our loadingVideo', error);
      // window.location.assign("tabs/tab1");
    }

    // if (
    //   this.playingVideo &&
    //   this.playingVideo.SVOD &&
    //   this.playingVideo.packageIds.length > 0
    // ) {
    // console.log("playsvod 244")
    //   this.playSVOD(this.playingVideo, true);
    // }
    // if (!localStorage) return;
    let localUser = await this.userService.getLocalUser();

    //if(this.wacConfig.configuration && this.wacConfig.configuration.onPlayLandscape){
    if (
      localUser &&
      localUser['_id'] &&
      localUser['verified'] &&
      this.wacConfig.configuration &&
      this.wacConfig.configuration.autoPlay == true
    ) {
      console.log('plyer');
      setTimeout(() => {
        console.log('playVideo() 259');

        this.playVideo(this.playingVideo);
      }, 1000);
    }

    // console.log("PlayingVideo ::: ", this.playingVideo);
    this.setReleaseDate();
    this.setUpIframe();
  }

  async setupSeo(tryCount=1) {
    try {
      if(!this.wacConfig.configuration) {
        await this.wacConfig.setConfiguration();
      }
      // title, des, img, url
      this.seoService.setSEOTags(
        this.playingVideo.title,
        this.playingVideo.description,
        this.playingVideo.horizontalThumbnail ||
          environment['horizontalDefaultImg'],
        this.wacConfig.domainName + this.router.url,
        this.playingVideo.tags
      );
      this.seoService.seoLdJson(this.wacConfig.configuration, 'player', {
        thumbnailUrl: this.playingVideo.horizontalThumbnail,
        duration: this.playingVideo.webhookResponse
          ? this.playingVideo.webhookResponse.duration
          : this.playingVideo.duration,
        goLiveTime: this.playingVideo.goLiveTime || this.playingVideo.createdAt,
      });
    } catch (err) {
      if(tryCount<3) {
        setTimeout(()=>this.setupSeo(++tryCount),500);
      }
      console.log('ours settingup SEO', err);
    }
  }

  async loadAllPlaylists() {
    try {
      console.log('localStorage', this.playlistService.playlist);
      this.loadContinueWatching();
      this.loadWatchLater();
      if (!localStorage) return;

      if (
        (this.playlistService.playlist?._id &&
          this.playlistService.playlist?._id.length == 24) ||
        (this.playlistIdFromUrl && this.playlistIdFromUrl.length == 24)
      ) {
        await this.loadPlaylist(this.playlistIdFromUrl, 10, 0);
      } else {
        // this.playlistVideos = this.playlistService.playlist;
        if (
          this.playlistIdFromUrl.toLowerCase() == 'trending' ||
          this.playlistIdFromUrl.toLowerCase() == 'popularvideos'
        ) {
          await this.loadTrending();
        } else if (this.playlistIdFromUrl.toLowerCase() == 'watchhistory') {
          await this.loadWatchHistory();
        } else {
          this.playlistVideos = {};
        }
        // this.playlistVideos
      }
      console.log('this.watchHistory', this.watchHistory.length);
      console.log('testing:- logging playlistvideos ', this.playlistVideos);
      if (this.playlistVideos && this.playlistVideos.videos) {
        this.playlistVideos.videos.forEach((video, index) => {
          this.watchHistory.forEach((watchedVideo) => {
            if (watchedVideo._id == video._id) {
              this.playlistVideos.videos.splice(index, 1);
              console.log('spliced playlists 318', this.playlistVideos.videos);
              this.playlistVideos.videos.push(video);
              console.log('pushed playlists 320', this.playlistVideos.videos);
            }
          });
        });
      }

      var promise = new Promise((resolve, reject) => {
        resolve(this.playlistVideos);
      });
      promise
        .then((success) => {
          // console.log(success);
        })
        .catch(function (error) {
          // console.log("errorrrrr", error);
        });

      console.log(
        'trending videos outer',
        this.playlistIdFromUrl.toLowerCase(),
        this.playlistVideos
      );

      try {
        if (this.playlistVideos && this.playlistVideos.videos) {
          // console.log("trending 330")
          this.playlistVideos.videos?.length > 0
            ? (this.showPlaylistLable = true)
            : (this.showPlaylistLable = false);
        }
        // console.log("trending 333")
      } catch (error) {
        console.log('errorrrr', error);
      }

      try {
        await this.loadSeries();

        if (this.playlistVideos && this.playlistVideos.videos.length > 0) {
          // let t = this.playlistVideos.videos.findIndex(
          //   (o) => o._id == this.playingVideo._id
          // );
          // if (t > -1) this.playlistVideos.videos.splice(t, 1);
        }
        this.playerIframe.setPlaylistVideo(this.playlistVideos.videos);
      } catch (E) {}

      // this.dpadHandlerService.regDpH();

      // this.setUpElementsArr();
      console.log('playlistVideos', this.playlistVideos);
    } catch (error) {
      throw error;
    }
  }

  // Get first top 10 videos from trending
  isTop10Video(id: string): boolean {
    return !!this.trendingService.top10Videos.find((video) => video._id === id);
  }

  //load trending videos
  async loadTrending() {
    try {
      if (
        this.wacConfig.configuration.defaultPlaylists?.trending?.status == 1
      ) {
        let videoAppId = this.wacConfig.configuration.videoAppId;
        let trendingDataResp = await this.trendingService.getTrendingVideo(
          videoAppId
        );
        if (!this.trendingData) {
          this.trendingData = trendingDataResp;
        } else {
          if (trendingDataResp && trendingDataResp['videos']) {
            this.trendingData['videos'] = [
              ...this.trendingData['videos'],
              ...trendingDataResp['videos'],
            ];
          }
        }
      }
      this.playlistVideos = this.wacConfig.configuration.defaultPlaylist
        ? this.wacConfig.configuration.defaultPlaylist?.trending
        : {};
      this.playlistVideos.name =
        this.playlistIdFromUrl.toLowerCase() == 'popularvideos'
          ? 'Popular Videos'
          : 'Trending';
      this.playlistVideos.videos = this.trendingData;
      this.watchLaterChecks(this.playlistVideos.videos);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  //load watchhistory
  async loadWatchHistory() {
    try {
      let date = new Date().toDateString();
      let localHistory: any = await this.watchHistoryService.getWatchHistory();

      let history = [];
      if (localHistory && localHistory.length > 0) {
        localHistory.forEach((video) => {
          history.push(video.video);
        });
        // this.watchHistory = history;
      } else history = [];
      this.playlistVideos = this.wacConfig.configuration.defaultPlaylist
        ? this.wacConfig.configuration.defaultPlaylist?.watchHistory
        : {};
      this.playlistVideos.name = 'Watch History';
      this.playlistVideos.type = 'Watch History';
      this.watchHistory = history;
      this.playlistVideos.videos = history;
      this.watchLaterChecks(this.playlistVideos.videos);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async loadWatchLater() {
    try {
      let watLater: any = await this.watchLaterService.getWatchLater();
      let watchLater = [];
      if (watLater && watLater.length > 0) {
        watLater.forEach((video) => {
          if (video.video._id.length == 24) watchLater.push(video.video);
        });
      }

      this.watchLaterplaylistss = this.wacConfig.configuration
        ? this.wacConfig.configuration.defaultPlaylists?.watchLater
        : {};

      this.watchLaterplaylistss.name = 'Watch Later';
      this.watchLaterplaylistss['videos'] = watchLater;

      // let t = this.watchLaterplaylistss.videos.findIndex(
      //   (o) => o._id == this.playingVideo._id
      // );
      // if (t > -1) this.watchLaterplaylistss.videos.splice(t, 1);

      this.watchLaterChecks(this.watchLaterplaylistss['videos']);
      console.log('new watchlaterss', this.watchLaterplaylistss);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async loadPlaylist(id, limit?, delta?) {
    try {
      console.log('loadPlaylist(', id, this.playlistService.playlist);
      if (!id || id.length != 24) return;

      let videosResp;
      console.log('id.length', id.length);
      videosResp = await this.playlistService.getPlaylistVideo(
        id,
        limit,
        delta
      );
      if (videosResp && videosResp['videos']) {
        this.playlistVideos = videosResp;
        // let t = this.playlistVideos.videos.findIndex(
        //   (o) => o._id == this.playingVideo._id
        // );
        // if (t > -1) this.playlistVideos.videos.splice(t, 1);
        this.watchLaterChecks(this.playlistVideos.videos);
      }
    } catch (error) {
      console.log('loadPlaylist error', error);
    }
  }

  seriesName;
  seasonName;
  async loadSeries() {
    try {
      if (!localStorage) return;

      this.seriesVideos = [];

      console.log('this.seriesId', this.seriesId);
      if (this.seriesId.length == 24) {
        const currentSeries: any = await this.playlistService.getSeriesById(
          this.seriesId
        );
        // this.seriesVideos.push(currentSeries);
        this.seriesName = currentSeries['seriesName'];
        this.seasonName = currentSeries['name'];
        // console.log("currentSeries",currentSeries);
        this.series = await this.playlistService.getSeriesBySeriesName(
          currentSeries['seriesName']
        );
        this.activeId = this.seriesId;
        // console.log("this.series",this.series);
        for (const s of this.series) {
          this.seriesVideos.push(
            await this.playlistService.getSeriesById(s._id)
          );
        }
        // console.log("this.seriesVideos",this.seriesVideos);

        this.playlistVideos.content = currentSeries?.videos.filter(
          (c) => c._id != this.videoId
        );
        this.playlistVideos = currentSeries;

        // console.log("this.playlistVideos",this.playlistVideos);

        // this.activateSeries(this.playlistService.seriesIndex);
        this.watchLaterChecks(this.playlistVideos.videos);
      }
    } catch (error) {}
  }

  async loadContinueWatching() {
    try {
      console.log('continue watchingggggg');
      this.continueWatching = [];
      let continueWatching: any =
        await this.continueWatchingService.getContinueWatching();

      continueWatching.forEach((video) => {
        if (video.video._id.length == 24)
          this.continueWatching.push(video.video);
      });
      this.watchLaterChecks(this.continueWatching);
    } catch (error) {
      this.continueWatching = [];
    }
    this.loaded = true;
  }

  async watchLaterChecks(playlists) {
    try {
      let watchLater: any = await this.watchLaterService.getWatchLater();
      // this.watchLater = JSON.parse(localStorage.getItem('savedVideosList'));
      if (watchLater && watchLater.length > 0) {
        playlists.forEach((video) => {
          watchLater.forEach((element) => {
            if (element.video._id == video._id) {
              video.watchLaterCheck = true;
            }
            return video.watchLaterCheck;
          });
        });
      }
    } catch (error) {}
  }

  startInsomia() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.insomnia.keepAwake().then(
        () => console.log('success'),
        () => console.log('error')
      );
    }
  }

  stopInsomia() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.insomnia.allowSleepAgain().then(
        () => console.log('success'),
        () => console.log('error')
      );
    }
  }

  setReleaseDate() {
    if (!this.playingVideo) return;

    this.releaseDate = new Date(
      this.playingVideo.releaseDate || this.playingVideo.createdAt
    )
      .toDateString()
      .split(' ')
      .slice(1)
      .join(' ');
  }

  readyToPlay = false;
  setUpIframe() {
    this.readyToPlay = false;
    this.videoPlaying = false;
    this.loaderGif = false;

    if (this.playerIframe) {
      this.playerIframe.pauseVideo();
      // this.playlistService.currentPlayingvideo = this.playingVideo;
      this.playerIframe.setVideo(this.playingVideo, false);
      return;
    }
    setTimeout(() => {
      this.setUpIframe();
    }, 100);
  }

  /*===================== Interval Player Page loaded successfully ================================*/

  hideSpinner(event) {
    this.readyToPlay = true;
    console.log('hideSpinner', event, this.loaderGif);
    if (this.loaderGif) {
      console.log('playVideo() 640');

      // Ask himanshu why commented this line - it interfared with AVOD
      // this.playerIframe.playVideo();
    }
    this.loaderGif = false;
    this.zone.run(() => {
      console.log('updating screen');
    });
    // this.playerIframe.playVideo();
  }

  loadVideoMeta(video) {
    this.zone.run(() => {
      this.playingVideo = video;
    });
  }

  playerUrls = [];
  settingNewVideoUrls(videoUrls) {
    console.log('settingNewVideoUrls', videoUrls);
    if (
      this.platform.is('android') ||
      this.platform.is('ios') ||
      (this.platform.width() < 900 && !this.platform.is('mobileweb'))
    ) {
      this.playerUrls = videoUrls;
      console.log(
        'i am trying settingNewVideoUrls playerUrls ',
        this.playerUrls
      );
    }
  }

  async playVideo(video) {
    this.isClicked = true;
    setTimeout(() => {
      this.isClicked = false;
    }, 2500);
    this.inactiveMultipleToster = true;
    try {
      await this.appTimeService.playedVideo();
      this.playerIframe.pauseVideo();
      this.playerIframe.setPlaylistVideo(this.playlistVideos.content);
      if (this.playingVideo?.trailerUrl) {
        this.trailerPlay = false;
      }
      this.checkConditionsToShowCast(video);
      console.log('playing videos', video);
      // this.fullscreenR = true;

      // video played whitout login when activated from maya.......

      if (
        !this.wacConfig.configuration.playWithoutLogin &&
        !(await this.checkUserLoggedIn())
      ) {
        sessionStorage.setItem('temp-last-url', window.location.href);
        return this.navCtrl.navigateRoot(['/login' + this.router.url]);
        // return this.router.navigate(['/login'], {queryParams: {playerPath: this.router.url}});
      }

      // if (this.platform.is('ios')) {
      //   return this.playAndAdd(video);
      // }

      setTimeout(() => {
        this.inactiveMultipleToster = false;
      }, 2000);

      if (video && video.AVOD) {
        await this.setAVOD(video);
      }

      // check if the video is already purchased
      const purchased = await this.getVideoTransactionStatus(video);
      console.log('Purchase check ::', purchased);
      if (purchased['isPurchased']) {
        return this.playAndAdd(video);
      }

      if (video && video.SVOD && video.packageIds.length > 0) {
        // If play without login active send to login
        if (
          this.wacConfig.configuration.playWithoutLogin &&
          !(await this.checkUserLoggedIn())
        ) {
          sessionStorage.setItem('temp-last-url', window.location.href);
          return this.navCtrl.navigateRoot(['/login' + this.router.url]);
        }

        return this.playSVOD(video);
      } else if (this.isTVOD(video)) {
        console.log('@tvod::', video);
        if (this.commonService.deviceType == 'androidTv') {
          this.presentToast(
            'To play this video - Please buy package from mobile or web app'
          );
          return;
        }
        // If play without login active send to login
        if (
          this.wacConfig.configuration.playWithoutLogin &&
          !(await this.checkUserLoggedIn())
        ) {
          sessionStorage.setItem('temp-last-url', window.location.href);
          return this.navCtrl.navigateRoot(['/login' + this.router.url]);
        }

        const purchased = await this.getVideoTransactionStatus(video);
        console.log('Purchase check ::', purchased);
        if (purchased['isPurchased']) {
          return this.playAndAdd(video);
        }

        const modal = await this.modalController.create({
          component: TvodOptionsComponent,
          componentProps: { video },
          cssClass: 'small',
          backdropDismiss: false,
        });
        await modal.present();
        const { data, role } = await modal.onWillDismiss();

        if (role === 'confirm') {
          if (data) {
            if (data.couponDiscount === 0) {
              // Check if full discount coupon
              // create order !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
              const body: any = {
                amount: video.TVOD.price,
                validity: video.TVOD.validity,
                senderId: this.userService.getLocalUser()['_id'],
                receiverId: environment['appId'],
                appId: environment['appId'],
                videoId: video._id,
                transaction: {},
                type: 'ppv',
                coupon: data,
                status: 'payment_verified',
              };
              console.log('body\n', body);
              console.log('body\n', data._id);

              await this.orderService.createMogiOrder(body, true, true);
              await this.couponService.useCoupon(data._id);
              return this.playAndAdd(video);
            }
            return this.playTVOD(video, data);
          } else return this.playTVOD(video);
        }
      } else {
        this.playAndAdd(video);
      }
    } catch (error) {
      console.log('\n\n\n\nError in playVideo() ::', error);
    }
  }

  async setAVOD(video) {
    const advtConfigs = await this.getAdvtConfigs();
    console.log('testing:- advtConfigs: ', advtConfigs);
    if (advtConfigs && advtConfigs.length) {
      this.playerIframe.setAdsUrl(advtConfigs);
    }
    // this.playAndAdd(video);
  }

  async playTVOD(video, coupon = null) {
    console.log('video@', video);
    try {
      await this.wacConfig.getConfig(this.wacConfig.appId, true);

      if (this.platform.is('android') && video && video.TVOD.productIdAndroid) {
        const dets: any = {
          name: video.title,
          description: video.description,
          imageurl: video.horizontalThumbnail,
          amount: video.TVOD.price,
          validity: video.TVOD.validity,
          videoId: video._id,
        };
        try {
          const res = await this.inAppPurchaseService.initTvodPurchase(
            dets,
            video,
            video.TVOD.productIdAndroid
          );

          // console.log('logging the response ', res);
          if (res) return this.playAndAdd(video);
        } catch (e) {
          console.log('\n\nCannot make Inapp TVOD ::', e);
        }
      } else if (this.platform.is('ios') && video && video.TVOD.productIdIos) {
        const dets: any = {
          name: video.title,
          description: video.description,
          imageurl: video.horizontalThumbnail,
          amount: video.TVOD.price,
          validity: video.TVOD.validity,
          videoId: video._id,
        };
        try {
          const res = await this.inAppPurchaseService.initTvodPurchase(
            dets,
            video,
            video.TVOD.productIdIos
          );

          // console.log('logging the response ', res);
          if (res) return this.playAndAdd(video);
        } catch (e) {
          console.log('\n\nCannot make Inapp TVOD ::', e);
        }
      } else if (
        this.wacConfig &&
        (this.wacConfig.configuration['gatewayName'] == 'Razorpay' ||
          this.wacConfig.configuration['gatewayName'] == 'razorpay')
      ) {
        const dets: any = {
          name: video.title,
          description: video.description,
          imageurl: video.horizontalThumbnail,
          amount: video.TVOD.price,
          validity: video.TVOD.validity,
          videoId: video._id,
        };
        if (coupon) dets.coupon = coupon;
        const res = await this.orderService.orderWithRazorpay(dets, video);
        console.log('playAndAdd 721', res);
        await this.couponService.useCoupon(coupon._id);
        if (!!res) return this.playAndAdd(video);
        // console.log('tvod res', res);
        return;
      } else if (
        this.wacConfig &&
        this.wacConfig.configuration['gatewayName'].toLowerCase() === 'stripe'
      ) {
        const dets: any = {
          name: video.title,
          description: video.description,
          imageurl: video.horizontalThumbnail,
          amount: video.TVOD.price,
          validity: video.TVOD.validity,
          videoId: video._id,
        };
        if (coupon) dets.coupon = coupon;
        const createdOrder: any = await this.orderService.createMogiOrder(this.orderService.createOrderObj({...dets}, await this.userService.getLocalUser()), true);
        // console.log('\n\n\n\n TVOD stripe :::', createdOrder);
        if (createdOrder) {
          this.order = createdOrder?.data;
          return this.stripe.stripePayment(createdOrder);
        }
      } else {
        console.log('HDFC@@');
        if (coupon) return this.proceedWithHDFC(video, coupon ? coupon : {});
        return this.proceedWithHDFC(video);
      }
    } catch (error) {}
  }

  async playSVOD(video, showPopUp = true) {
    try {
      let userPackage;

      userPackage = await this.userService.getUserValidPackages();
      console.log('SVOD', userPackage);

      let thisVideoPackage;
      for (let i = 0; i < video.packageIds.length; i++) {
        thisVideoPackage = userPackage.find(
          (o) => o.pkgId === video.packageIds[i]
        );
        console.log('thisVideoPackage', thisVideoPackage);
        if (thisVideoPackage) break;
      }

      if (thisVideoPackage) {
        console.log('playAndAdd 748');

        this.playAndAdd(video);
      } else {
        //looks wrong to me
        // let obj;
        // for (let i = 0; i < video.packageIds.length; i++) {
        //   obj = this.packages.find((o) => o._id === video.packageIds[i]);
        //   if (obj.price == 0) {
        //     console.log('playAndAdd 757');
        //     return this.playAndAdd(video);
        //   }
        // }

        console.log('pakage', this.packages, video.packageIds);
        // const obj = this.packages.find((i) => i._id == video.packageIds[0]);
        // this.buyPackageId = obj._id;
        this.buyPackageId = '';
        for (let i = 0; i < video.packageIds.length; i++)
          this.buyPackageId += video.packageIds[i] + '-';

        this.buyPackageId += '/' + window.location.href.replace(/\//g, '^');
        console.log('###### - ', this.buyPackageId);
        // this.buyPackageMessage = `Buy ${obj.name} to unlock`;
        this.buyPackageMessage = `Buy Package to unlock`;
        if (showPopUp) {
          this.presentToast(
            `You need to buy package to watch this video`
            // `You need to buy ${obj.name} package to watch this video`
          );
        }
      }
    } catch (error) {
      console.log('playSVOD error', error);
    }
  }

  addToWatchHistory(video) {
    try {
      this.watchHistoryService.addWatchHistoryVideo(video);
    } catch (err) {
      console.log('ours setting watchHistory :: ', err);
    }
  }
  playAndAdd(video) {
    this.addToWatchHistory(video);
    this.videoPlaying = true;
    // this.loaderGif = true;

    setTimeout(() => {
      // if (this.readyToPlay || this.loaderGif)
      console.log('playVideo() 778');

      this.playerIframe.playVideo();

      this.loaderGif = false;
      this.zone.run(() => {
        // console.log('updating screen');
      });
    }, 300);
  }

  isTVOD(video) {
    return (
      video &&
      video.TVOD &&
      Object.keys(video.TVOD).length > 0 &&
      video.TVOD['price'] != null &&
      video.TVOD['price'] != 0 &&
      video.TVOD['price'] != ''
    );
  }

  async checkUserLoggedIn() {
    if (!localStorage) return false;
    const localUser = await this.userService.getLocalUser();
    if (localUser && localUser['verified']) {
      this.existUser = true;
      return true;
    }
    return false;
  }

  async onSaveVideo(video) {
    if (await this.checkUserLoggedIn()) {
      this.watchLaterService.addWatchlaterVideo(video);
      this.presentToast('Added to watch later');
    } else {
      this.navCtrl.navigateRoot(['/login']);
    }
  }

  async onRemoveSavedVideo(video) {
    if (await this.checkUserLoggedIn()) {
      this.checkUserLoggedIn();
      this.watchLaterService.deleteWatchLater(video);
      this.presentToast('Removed from watch later');
    } else {
      this.navCtrl.navigateRoot(['/login']);
    }
  }

  // shareVideo(title, des, image) {
  //   if (this.shareEnable) return;
  //   this.shareEnable = true;
  //   let shareLink = this.wacConfig.configuration.appUrl + this.router.url;
  //   this.socialSharing
  //     .share(
  //       'Watch now\n' + title + '\n' + des + '\n',
  //       'Watch now ' + title,
  //       image,
  //       shareLink
  //     ) //Text, Image, Url
  //     .then(() => {
  //       // console.log('Success !!!');
  //       this.shareEnable = false;
  //     })
  //     .catch((error) => {
  //       // console.log('Failed to Share', error);
  //       this.shareEnable = false;
  //     });
  // }

  async openSocial() {
    try {
      const shareLink =
        this.wacConfig.configuration.appPlayStoreUrl ||
        this.wacConfig.configuration.appUrl + this.router.url;
      await Share.share({
        title: 'Watch Now - ' + this.playingVideo.title,
        text: this.playingVideo.description,
        url: shareLink,
        dialogTitle: 'Install App To Watch Now',
      });
    } catch (error) {
      console.log(error);
    }
  }

  async shareOnDesktop(platform, media) {
    // console.log("Platform ::: ", platform, "\nMedia info ::: ", media);
    let url =
      this.wacConfig.configuration.appUrl + this.router.url ||
      'https://ott.mogiio.com/share/' + this.wacConfig.configuration._id;
    let shareLink;
    switch (platform) {
      case 'whatsapp':
        shareLink =
          'https://api.whatsapp.com/send?text=' +
          'Watch now%0D%0A ' +
          media.title.replaceAll('', '') +
          '%0D%0A ' +
          media.description +
          '%0D%0A ' +
          url;
        break;
      case 'facebook':
        shareLink = 'https://facebook.com/sharer.php?u=' + url;
        break;
      case 'twitter':
        shareLink =
          'https://twitter.com/share?url=' +
          url +
          '&text=' +
          'Watch now%0D%0A ' +
          media.title.replaceAll('', '') +
          '%0D%0A ' +
          media.description +
          '%0D%0A ';
        break;
      case 'mail':
        shareLink =
          'mailto:?subject=' +
          media.title.replaceAll('', '') +
          '&body=' +
          'Watch now%0D%0A ' +
          media.title.replaceAll('', '') +
          '%0D%0A ' +
          media.description +
          '%0D%0A ' +
          url;
        break;
      default:
        break;
    }

    // console.log(platform, shareLink);
    window.open(shareLink);
  }

  async cast() {
    console.log('i am trying to cast ', this.playerUrls);
    await this.checkUserLoggedIn();
    if (this.playerUrls.length && this.existUser) {
      this.loadCC(
        this.playerUrls[0].src,
        this.playerUrls[0].type,
        this.playerIframe,
        this.playingVideo
      );
    }
  }

  loadCC(videoUrl, mineType, playerIframe, video) {
    // Must wait for deviceready before using chromecast

    var chrome = window['chrome'];

    // File globals
    var _session;
    var _media;

    initialize();

    function initialize() {
      // use default app id
      console.log(' ::chrome :: ', chrome);
      var appId = chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID;
      var apiConfig = new chrome.cast.ApiConfig(
        new chrome.cast.SessionRequest(appId),
        function sessionListener(session) {},
        function receiverListener(receiverAvailable) {}
      );

      // initialize chromecast, this must be done before using other chromecast features
      chrome.cast.initialize(
        apiConfig,
        function () {
          // Initialize complete
          // Let's start casting
          requestSession();
        },
        function (err) {
          // Initialize failure
          // console.log(err);
        }
      );
    }

    function requestSession() {
      // This will open a native dialog that will let
      // the user choose a chromecast to connect to
      // (Or will let you disconnect if you are already connected)
      chrome.cast.requestSession(
        function (session) {
          // Got a session!
          _session = session;

          // Load a video
          loadMedia(videoUrl, mineType);
        },
        function (err) {
          // Failed, or if err is cancel, the dialog closed
          console.log('playVideo() 953');
          playerIframe.playVideo();
          // this.playVideo(video);
          // console.log('error in requestSession ', err);
        }
      );
    }

    function loadMedia(videoUrl, mineType) {
      //  var videoUrl = 'https://ia801302.us.archive.org/1/items/TheWater_201510/TheWater.mp4';
      // console.log('videoUrl,mineType loading media :: ', videoUrl, mineType);
      var mediaInfo = new chrome.cast.media.MediaInfo(videoUrl, mineType);
      // var mediaInfo = new chrome.cast.media.MediaInfo("https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8", "application/x-mpegURL");

      _session.loadMedia(
        new chrome.cast.media.LoadRequest(mediaInfo),
        function (media) {
          // You should see the video playing now!
          // Got media!
          _media = media;
          playerIframe.pauseVideo();

          // Wait a couple seconds
          //  setTimeout(function () {
          //      // Lets pause the media
          //      pauseMedia();
          //  }, 4000);
        },
        function (err) {
          // Failed (check that the video works in your browser)
          // console.log('loadMedia :: ', err);
        }
      );
    }

    function pauseMedia() {
      _media.pause(
        {},
        function () {
          // Success

          // Wait a couple seconds
          setTimeout(function () {
            // stop the session
            stopSession();
          }, 2000);
        },
        function (err) {
          // Fail
          // console.log(err);
        }
      );
    }

    function stopSession() {
      // Also stop the session (if )
      _session.stop(
        function () {
          // Success
        },
        function (err) {
          // Fail
          // console.log(err);
        }
      );
    }
  }

  async loadContent(content, event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log('loading season content : ', content);
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (err) {
      console.log('ours scroll : ', err);
    }

    this.playlistVideos = content;
    // let t = this.playlistVideos.videos.findIndex(
    //   (o) => o._id == this.playingVideo._id
    // );
    // if (t > -1) this.playlistVideos.videos.splice(t, 1);
    this.activeId = content._id;
    await this.watchLaterChecks(this.playlistVideos.videos);
    // this.setUpElementsArr();
  }

  details(video, id, playlist, ind?, event?) {
    this.playerIframe.pauseVideo();
    console.log(
      'i am at details : videoObj : ',
      id,
      video,
      'playlist :: ',
      playlist,
      'event passed :::',
      event
    );
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    try {
      // alert("trying window.scroll");
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (err) {
      console.log('ours scroll : ', err);
    }

    console.log(
      'this.seriesId : ',
      playlist,
      this.seriesId,
      ' this.seriesName : ',
      this.seriesName,
      'this.seasonName : ',
      this.seasonName
    );
    this.commonService.goToPage('player', {
      genres: video.genres.join('-'),
      playlistName: playlist.name,
      name: playlist.name || '',
      title: video.title || '',
      date: video.createdAt,
      _id: video._id,
      playlistId: playlist._id ? this.playlistIdFromUrl : '',
      seriesId:
        playlist.seriesName && playlist.seriesName != '' ? playlist._id : '',
      seriesName: playlist.seriesName,
      seasonName: playlist.name,
    });
    this.close();
    // this.playlistService.playingVideo = video;

    // if(this.seriesId && this.seriesId.length == 24)
    //   this.ngOnInit();
  }

  playPauseToggle() {
    console.log('sdafasd');
    if (!this.isVideoPlaying) {
      console.log('playVideo() 1123');

      this.playVideo(this.playingVideo);
      this.isVideoPlaying = true;
    } else {
      this.playerIframe.isVideoPause
        ? this.playerIframe.playVideoEvent()
        : this.playerIframe.pauseVideo();
    }
  }

  isVideoPlaying = false;
  preventEvent = false;
  consecutiveUpDownKeyCount = 0;

  packages;
  async loadPackages() {
    try {
      if (!localStorage) return;
      this.packages = await this.seedService.getPackages();
    } catch (error) {}
  }

  async presentToast(message) {
    // console.log(message);
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'middle',
      cssClass: 'authTosterMessage',
      animated: true,
      buttons: [
        {
          side: 'end',
          icon: 'close-outline',
        },
      ],
    });
    toast.present();
  }

  async proceedWithHDFC(video, coupon = null) {
    let user = this.userService.getLocalUser();
    if (user) {
      const data: any = {
        amount: video.TVOD.price,
        senderId: this.userService.getLocalUser()['_id'],
        receiverId: environment['appId'],
        appId: environment['appId'],
        videoId: video._id,
        transaction: {},
        type: 'ppv',
      };
      if (coupon) data.coupon = coupon;
      const createdOrder = await this.orderService.createMogiOrder(data, true);
      if (createdOrder) {
        // console.log(createdOrder);
        if (createdOrder['status'].code == 201) {
          try {
            const response = await this.paymentService.processPayment(
              createdOrder
            );
            // console.log("Response ::: ", response);
            if (response && response == 'play') {
              console.log('playAndAdd 1192');
              return this.playAndAdd(video);
            }
          } catch (e) {
            return;
          }
        } else if (createdOrder['status'].code == 200)
          return this.playAndAdd(video);
      }
    }
  }

  w400Replace(img) {
    let str = /w400/;
    img = img.replace(str, this.seoService.setImageWidth());
    return img;
  }

  loadNext(resp, id) {
    resp.then((r) => {
      // console.log(this.playlistVideos.videos.length);
      if (
        this.playlistVideos.videos &&
        this.playlistVideos.videos.length >= environment['limit']
      )
        this.loadPlaylist(
          id,
          environment['limit'],
          this.playlistVideos && this.playlistVideos.videos
            ? Math.floor(
                this.playlistVideos.videos.length / environment['limit']
              )
            : 0
        );
    });
  }

  // setUpElementsArr() {
  //   // Sequence : [[search],[trending],[comingSoon]]
  //   // console.log(" setUpElementsArr again in player : ", this.dpadHandlerService.elementArr , this.series );
  //   // this.dpadHandlerService.elementArr = [[{id : 'searchString1', type: 'searchbar'}]];
  //   this.dpadHandlerService.elementArr = [];

  //   if (this.series && this.series.length) {
  //     this.dpadHandlerService.elementArr.push([]);
  //     this.series.forEach((season) => {
  //       this.dpadHandlerService.elementArr[
  //         this.dpadHandlerService.elementArr.length - 1
  //       ].push({
  //         index:
  //           this.dpadHandlerService.elementArr[
  //             this.dpadHandlerService.elementArr.length - 1
  //           ].length,
  //         id:
  //           season._id +
  //           this.dpadHandlerService.elementArr[
  //             this.dpadHandlerService.elementArr.length - 1
  //           ].length,
  //         type: 'season',
  //         season: season,
  //         series: this.series,
  //       });
  //     });
  //   }

  //   if (this.playlistVideos.videos && this.playlistVideos.videos.length) {
  //     this.dpadHandlerService.elementArr.push([]);
  //     this.playlistVideos.videos.forEach((element) => {
  //       this.dpadHandlerService.elementArr[
  //         this.dpadHandlerService.elementArr.length - 1
  //       ].push({
  //         id:
  //           element._id +
  //           this.dpadHandlerService.elementArr[
  //             this.dpadHandlerService.elementArr.length - 1
  //           ].length,
  //         type: 'moreFrom',
  //         video: element,
  //         playlist: this.playlistVideos,
  //       });
  //     });
  //   }

  //   if (this.continueWatching && this.continueWatching.length) {
  //     this.dpadHandlerService.elementArr.push([]);
  //     this.continueWatching.forEach((element) => {
  //       this.dpadHandlerService.elementArr[
  //         this.dpadHandlerService.elementArr.length - 1
  //       ].push({
  //         id:
  //           element._id +
  //           this.dpadHandlerService.elementArr[
  //             this.dpadHandlerService.elementArr.length - 1
  //           ].length +
  //           '_continueWatching',
  //         type: 'continueWatching',
  //         video: element,
  //         playlist: {},
  //       });
  //     });
  //   }

  //   if (
  //     this.watchLaterplaylistss.videos &&
  //     this.watchLaterplaylistss.videos.length
  //   ) {
  //     this.dpadHandlerService.elementArr.push([]);
  //     this.watchLaterplaylistss.videos.forEach((element) => {
  //       this.dpadHandlerService.elementArr[
  //         this.dpadHandlerService.elementArr.length - 1
  //       ].push({
  //         id:
  //           element._id +
  //           this.dpadHandlerService.elementArr[
  //             this.dpadHandlerService.elementArr.length - 1
  //           ].length +
  //           '_watchLater',
  //         type: 'watchLater',
  //         video: element,
  //         playlist: {},
  //       });
  //     });
  //   }
  // }

  //More from cast Start ------->>>>
  getMoreFromCast(name: string) {
    this.http.get(`/drives/videos/?name=${name}`).subscribe(({ data }: any) => {
      this.resp[0] = name;
      this.resp[1] = [...data];
    });
  }

  /*=====================++++++++++++++++++++++++++++++++++++++ Dead code ++++++++++++++++++++++++++++++++++++ ==================*/
  async rating(event: any) {
    event.preventDefault();
    event.stopPropagation();
    // console.log("Rating Value :: ", this.ratingValue);
    if (this.ratingValue > 0) {
      let resp = await this.userService.giveRating(
        this.ratingValue,
        this.playingVideo._id
      );
      if (this.ratingValue > 0) {
        this.playingVideo.totalRating = resp['data'].totalRating;
        this.playingVideo.userCount = resp['data'].userCount;
      }
      // this.giveRating = false;
      const timer = setTimeout(() => {
        this.giveRating = false;
      }, 1000);
    }
  }

  async loadRecommendation() {
    this.recommendation = await this.recommendService.getRecommendVideo();
  }

  toPlay(video) {
    this.playingVideo = video;
  }
  fullscreenR = false;

  setPlayer(video) {
    this.playingVideo = video;
    this.commonService.goToPage('player', this.playingVideo.title);
  }

  //single video check
  async singleWatchLaterChecks(video) {
    try {
      let watchLater: any = await this.watchLaterService.getWatchLater();
      // this.watchLater = JSON.parse(localStorage.getItem('savedVideosList'));
      if (watchLater && watchLater.length > 0) {
        watchLater.forEach((element) => {
          if (video._id == element.video._id) {
            video.watchLaterCheck = true;
          }
          return video.watchLaterCheck;
        });
      }
    } catch (error) {}
  }

  // trimString(string, length) {
  //   return (string && string.length > length) ? (string.substring(0, length) + '...') : string;
  // }

  openRateBox(event: any) {
    this.giveRating = true;
    this.showDesktopSocial = false;
    setTimeout(() => {
      this.giveRating = false;
    }, 10000);
    event.preventDefault();
    event.stopPropagation();
  }

  watchTrailer(video, trailer) {
    this.playerIframe.pauseVideo();
    this.setUpIframe();
    this.videoPlaying = true;
    // this.loaderGif = true;
    this.trailerPlay = !this.trailerPlay;
    if (trailer) {
      this.playerIframe.setVideo(video, true);
      // setTimeout(() => {
      //   if (this.playerIframe) {
      //     this.playerIframe.setVideo(video, true);
      //   }
      //   this.loaderGif = false;
      //   this.zone.run(() => {
      //   });
      // }, 3000);
    } else {
      this.playerIframe.pauseVideoTrailer();
      this.playerIframe.pauseVideo();
      this.videoPlaying = false;
      this.loaderGif = false;
    }
  }
  public toggleWatchLater(video: any, event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.common['showOptions'] = false;
    video.watchLaterCheck = !video.watchLaterCheck;
    if (video.watchLaterCheck) {
      this.onSaveVideo(video);
    } else {
      this.onRemoveSavedVideo(video);
    }
    return false;
    // video.watchLaterCheck = !video.watchLaterCheck;
  }

  async close() {
    await this.modalController.dismiss();
  }

  private async getAdvtConfigs(): Promise<any> {
    try {
      return await this.advtConfigsService.getAdvtConfigs();
    } catch (error) {
      console.log('error occurred while getting advt-configs: ', error);
    }
  }

  getIonContentClasses() {
    let c = '';
    if (this.platform.is('ios')) {
      if (this.portrait) c += 'ios-portrait';
      else c += 'ios-landscape';
    }

    if (this.portrait) c += ' scroll-on';
    else c += ' scroll-off';
    return c;
  }

  setCapatalizeName(labelName) {
    labelName = labelName.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    });
    return labelName;
  }


  @HostListener('window:focus', ['$event'])
  async tabActivation(event: any) {
    console.log('Tab reactivated!');
    if (this.order) {
      console.log('kept order ::::', this.order);
      // Check order status
      const result: any = await this.orderService.getOrder(this.order._id);
      if (result.data.status == 'payment_verified') {
        this.playVideo(this.playingVideo);
      }
    }
  }

  ngOnDestroy() {
    console.log('\n\n\n ngOnDestry Player page  : ');

    this.stopInsomia();
    // this.document.onkeydown = (e) => {};
    this.close();
  }
}
