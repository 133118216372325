import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { HttpProvider } from '../http.service';
import { WacConfigService } from 'src/app/services/wac-config/wac-config.service';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor(
    private http: HttpClient,
    private httpHeaders: HttpProvider,
    public wacConfig: WacConfigService,
    @Optional() @Inject('X_FORWARDED_HOST') private host: any
  ) {}

  getCouponByName(coupon: string, item = null) {
    return new Promise((resolve, reject) => {
      let url = environment.baseUrl + '/coupons/code?code=' + coupon;
      if(item) url += '&item=' + item;
      this.http
        .get(
          url,
          this.httpHeaders.setAuthInHeaders()
        )
        .subscribe(
          (res: any) => {
            console.log('\n\n\n\n\nCoupon Found ::::', res);
            if (res && res.data) resolve(res['data']);
            else reject('Invalid Coupon Code');
          },
          (err) => {
            console.log('\n\n\n\n Error in gettin coupon::::', err);
            reject(err);
          }
        );
    });
  }

  useCoupon(couponId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          environment.baseUrl + '/coupons/' + couponId + '/useCoupon',
          { userId: this.wacConfig.configuration.userId },
          this.httpHeaders.setAuthInHeaders()
        )
        .subscribe(
          (res: any) => {
            console.log('\n\n\n\n\nCoupon Used ::::', res);
            resolve(res['data']);
            reject('Invalid Coupon Code');
          },
          (err) => {
            console.log('\n\n\n\n Error in usin coupon::::', err);
            reject(err);
          }
        );
    });
  }
}
