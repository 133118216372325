import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AdsSliderComponent } from './ads-slider.component';
import { HeaderModule } from '../header/header.module';
import { SwiperModule } from 'swiper/angular';



@NgModule({
  declarations: [AdsSliderComponent],
  imports: [
    CommonModule,
    HeaderModule,
    IonicModule,
    SwiperModule
    // AdsSliderRoutingModule
  ],
  exports: [AdsSliderComponent]
})
export class AdsSliderModule { }
