import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const paymentUrl = environment.baseUrl + '/payment/';

@Injectable({
    providedIn: 'root'
})

export class StripeService {


    constructor(
      private http: HttpClient
    ) { }


    stripePayment(order: any) {
      const hrId = order["data"].hrId;
      this.http.get(paymentUrl + 'stripe/makeTransactionApp?hrId=' + hrId)
        .subscribe((res: any) => {
          if(res && res.url)
            window.open(res.url, "_blank");
        })
    }

}



