import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert/alert.service';
import { CommonService } from '../common/common.service';
import { CredentialService } from '../credential/credential.service';
import { GatewayService } from '../gateway/gateway.service';
import { HttpProvider } from '../http.service';
import { PaymentService } from '../payment/payment.service';
import { PlaylistService } from '../playlist/playlist.service';
import { UserService } from '../user/user.service';
import { WacConfigService } from '../wac-config/wac-config.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  isKube = environment['baseUrl'].includes('apis.mogiio.com') ? true : false;
  orderId: any;
  constructor(
    private httpClient: HttpClient,
    private http: HttpProvider,
    private navCtrl: NavController,
    private gatewayService: GatewayService,
    private userService: UserService,
    private wacConfig: WacConfigService,
    private credService: CredentialService,
    private alertService: AlertService,
    private playlistService: PlaylistService,
    private common: CommonService,
    public toastController: ToastController
  ) {}

  createMogiOrder(data, isppV?, inApp = false) {
    return new Promise((resolve, reject) => {
      let orderPath;
      orderPath = isppV ? '/orders/ppv' : '/orders';
      if (inApp) orderPath += '?inapp=true';
      this.http
        .post(orderPath, { ...data, appId: this.wacConfig.configuration._id })
        .subscribe((res) => {
          resolve(res);
          console.log('order res', res);
          if (res['status']['code'] == '201' && !isppV) {
            // this.presentToast(res['status']['message']);
          }
        });
    });
  }

  async presentToast(message, pos?) {
    console.log(message);
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: pos ? pos : 'bottom',
      buttons: [
        {
          side: 'end',
          icon: 'close-outline',
        },
      ],
    });
    toast.present();
  }

  updateOrder(id, data, query?) {
    console.log('updateOrder fun', id, data, query);
    return new Promise((resolve, reject) => {
      let orderPath = '/orders/' + id;
      if (query) orderPath += '?' + query + '=true';
      this.http.put(orderPath, data).subscribe(
        (res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getOrder(id) {
    return new Promise((resolve, reject) => {
      this.http.get('/orders/' + id).subscribe((res) => {
        console.log(res, res['status'].code);
        resolve(res);
      });
    });
  }

  //Razor Pay Integration
  orderWithRazorpay(options, video?) {
    return new Promise(async (resolve, reject) => {
      try {
        let user = await this.userService.getLocalUser();

        console.log('in order with rozaarpay');

        if (user) {
          console.log('orderWithRazorpay', options, user);
          let orderDetails = this.createOrderObj(options, user);

          let creds = await this.credService.getCreds();

          if (
            !creds['credentials']['Razorpay']['APIKEY'] ||
            !creds['credentials']['Razorpay']['SECRETKEY']
          )
            return reject(new Error('Creds Not found'));

          let order = await this.createMogiOrder(
            orderDetails,
            video ? true : false
          );

          let rpCreds = {
            apiKey: creds['credentials']['Razorpay']['APIKEY'],
            apiKeySecret: creds['credentials']['Razorpay']['SECRETKEY'],
          };

          if (video) {
            resolve(
              await this.proceedWithVideo(options, order, video, rpCreds)
            );
          } else {
            this.proceedWithPkg(order, options, rpCreds);
          }
        }
      } catch (error) {
        console.log('Error with payWithRazorPay :: ', error.message);
        // resolve(false);
        reject(error);
      }
    });
  }

  // async makeOrder(options, creds, order, video) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       console.log("makeOrder called", creds);

  //       let paymentCreds = creds['credentials']["Razorpay"];

  //       let razorPayKey = {
  //         apiKey: paymentCreds["APIKEY"],
  //         apiKeySecret: paymentCreds["SECRETKEY"],
  //       };

  //       if (order) {
  //         console.log("razorPayKey : : ", razorPayKey);
  //         console.log("order", order, video);
  //         if (video) {
  //           this.proceedWithVideo(options, order, video, razorPayKey);
  //         }
  //         else {
  //           this.proceedWithPkg(order, options, razorPayKey);
  //         }
  //       }
  //       else {
  //         reject(new Error("Mogi Order Not Found !"))
  //       }

  //     } catch (error) {
  //       reject(error);
  //     }

  //   })

  // }

  createOrderObj(options, user) {
    let orderDetails = {
      appId: this.wacConfig.configuration._id,
      senderId: user['_id'],
      name: options.name,
      description: options.description,
      image: options.image ? options.image : 'assets/imgs/logo.png',
      amount: options.amount, //Rupee to Paise
      currency: options.currency ? options.currency : 'INR',
    };

    if (options.videoId) orderDetails['videoId'] = options.videoId;
    if (options.validity) orderDetails['validity'] = options.validity;
    if (options.products) orderDetails['products'] = options.products;
    if (options.coupon) orderDetails['coupon'] = options.coupon;

    return orderDetails;
  }

  async paymentWithRazorPay(order, razorPayKey, video?) {
    return new Promise(async (resolve, reject) => {
      try {
        const mogiOrderId = order['data']['_id'];
        let paymentSuccess = await this.openRazorPayment(
          order['data'],
          razorPayKey
        );
        console.log('PaymentSuccess', paymentSuccess, order, mogiOrderId);
        if (paymentSuccess['response']['razorpay_signature']) {
          /*orderId : req.body.orderId,
          paymentId : req.body.paymentId,
          paymentSignature : req.body.paymentSignature,
          appId : req.body.appId,

          "razorpay_order_id":"order_J6xUt2aOJzwwwh",
          "razorpay_signature":"52489a980ad9c3e6bb15f9982c39e777a67a380b6c2316bfb88900b866fbcc8a",
          "razorpay_payment_id":"pay_J6xVXKU3ZpG6Q4"
          */
          let obj = {
            orderId: paymentSuccess['response']['razorpay_order_id'],
            paymentSignature: paymentSuccess['response']['razorpay_signature'],
            paymentId: paymentSuccess['response']['razorpay_payment_id'],
            appId: order['data']['receiverId'],
          };
          let paymentResp = await this.updateOrder(mogiOrderId, obj, 'rpay');
          console.log('paymentWithRazorPay paymentResp', paymentResp);
          if (paymentResp && paymentResp['status']['code'] == '200') {
            console.log('paymentResp : : : ', paymentResp);
            if (paymentResp['videoId']) {
              this.orderId = '';
              this.playlistService.playingVideo = video;
              this.playlistService.play = true;
              this.common.addToWatchHistory(video);
              resolve(true);
            } else {
              let buttons = [
                {
                  text: 'Ok',
                  role: 'cancel',
                },
              ];
              await this.alertService.showAlert(
                'Payment successful ✓',
                'Thanks for subscribing',
                buttons
              );
              this.orderId = '';
              resolve(true);
            }
          } else {
            this.orderId = '';
            resolve(false);
          }
        } else {
          let errorResp = await this.updateOrder(
            order['_id'],
            paymentSuccess['payload'],
            'rpayerror'
          );
          if (errorResp && errorResp['status'] == 'payment_failed')
            await this.alertService.showAlert(
              'Payment Failed',
              'Payment not complete, Please try again after sometime',
              { buttons: ['Ok'] }
            );
          this.orderId = '';
          console.log(
            'Error creating razorpay order, Contact Customer Service'
          );
          resolve(false);
        }
      } catch (error) {
        console.log('Error with paymentWithRazorPay :: ', error);
        reject(error);
      }
    });
  }

  proceedWithVideo(options, order, video, razorPayKey) {
    return new Promise(async (resolve, reject) => {
      console.log('proceedWithVideo', options, order, video, razorPayKey);
      // We'll just proceed with the payment no need for pop up
      resolve(await this.paymentWithRazorPay(order, razorPayKey, video));

      // Below code is deprecated as we are asking confirmation before this step
      if (
        options.videoId &&
        order['status'] &&
        order['status']['code'] == '200'
      ) {
        resolve(order['data']);
        // this.playlistService.playingVideo = video;
        // this.playlistService.play = true;
        // this.common.addToWatchHistory(video);
      } else {
        // let buttons = [
        //   {
        //     text: 'Cancel',
        //     role: 'cancel',
        //   },
        //   {
        //     text: 'Continue',
        //     handler: async () => {
        //       resolve(
        //         await this.paymentWithRazorPay(order, razorPayKey, video)
        //       );
        //     },
        //   },
        // ];
        // this.alertService.showAlert(
        //   video.title,
        //   'Pay ' +
        //     video.TVOD.price +
        //     ' INR to play this video and its validity is of ' +
        //     video.TVOD.validity +
        //     ' days.',
        //   buttons
        // );
      }
    });
  }

  async proceedWithPkg(order, options, razorPayKey) {
    let paymentResp = await this.paymentWithRazorPay(order, razorPayKey);
    if (paymentResp) {
      options.products[0]['pkgId'] = options.products[0]['_id'];
      let updatedPkg = await this.userService.setUserPackage(
        options.products[0]
      );
      if (updatedPkg == 200) {
        let myUser = JSON.parse(localStorage.getItem('user'));
        let myDate = new Date();
        let newExpiryDate = new Date(
          new Date().setDate(myDate.getDate() + options.products[0].validity)
        );
        myUser['validity'] = newExpiryDate.toISOString();
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(myUser));
        this.navCtrl.navigateRoot(['tabs']);
      }
    }
  }

  async openRazorPayment(order, razorPayKey) {
    console.log('ORDER :::::::::::::::::::::::::::', order);
    this.orderId = order['transaction']['payment']['orderId'];
    console.log(
      'Options razorpay :: ',
      order['transaction']['payment']['orderId'],
      typeof order['transaction']['payment']['orderId']
    );
    var options = this.gatewayService.setOptions(order, razorPayKey);
    return await this.gatewayService.razorCheckout(
      order,
      this.orderId,
      options
    );
  }

  // Getting User Order details--

  async getUserOrderDetails(currentpage) {
    try {
      // if (!localStorage) return;
      // let user = JSON.parse(localStorage.getItem('user'));
      // if (user)

      return new Promise((resolve, reject) => {
        if (!localStorage) reject('No Data In Local Storage!');
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) reject('No User Data!');

        console.log('logging the user: ', user);

        // const headers = new HttpHeaders({
        //   'Content-Type': 'application/json',
        //   'authorization': user?.token
        // });

        // this.http.get('/orders/' + user['_id'] + '/getOrdersByApp?pagesize=10&currentpage=' + currentpage)
        // console.log('https://dev-apis.mogiio.com/orders/' + user['_id'] + '/getOrdersByOttUser?pagesize=10&currentpage=' + currentpage, this.http.setAuthInHeaders())

        this.httpClient
          .get(
            environment['baseUrl'] +
              '/orders/' +
              user['_id'] +
              '/getOrdersByOttUser?pagesize=10&currentpage=' +
              currentpage
          )
          .subscribe(
            (res) => {
              console.log('logging the response');
              console.log('logging the response from orders :::');
              console.log(res);
              resolve(res['data']);
            },
            (error) => {
              console.log(error);
              console.log('hdvjscbhdvkjsdkj', error);
              reject(error);
            }
          );
      });
    } catch (error) {
      console.log('logging the error');
      console.log(error);
    }
  }
  inAppPurchase = async (pkg, orderId) => {
    let user = this.userService.getLocalUser();
    let orderDetails = {
      appId: this.wacConfig.configuration._id,
      senderId: user['_id'],
      currency: 'INR',
      validity: pkg.validity,
      products: [{ ...pkg, orderId }],
    };
    const req = () =>
      new Promise((resolve, reject) =>
        this.http.post('/orders', orderDetails).subscribe(resolve, reject)
      );
    const { data }: any = await req();
    console.trace(data);
    return data;
  };

  tvodInAppPurchase = async (options, orderId, video) => {
    let user = this.userService.getLocalUser();
    video['googleTrxnId'] = orderId;
    let orderDetails = {
      appId: this.wacConfig.configuration._id,
      senderId: user['_id'],
      name: options.name,
      description: options.description,
      image: options.image ? options.image : 'assets/imgs/logo.png',
      amount: options.amount,
      currency: options.currency ? options.currency : 'INR',
      validity: options.validity,
      videoId: options.videoId,
      products: [{ ...video }],
    };
    const req = () =>
      new Promise((resolve, reject) =>
        this.http.post('/orders/ppv', orderDetails).subscribe(resolve, reject)
      );
    const { data }: any = await req();
    console.trace(data);
    return data;
  };

  /**
   * this fuction is for to check wheater the  video is purchased already or not
   */
  public isAlreadyPurchasedVideo(id: string, validity: any) {
    return new Promise(async (resolve, reject) => {
      try {
        this.httpClient
          .get(
            environment['baseUrl'] +
              '/orders/' +
              `video/${id}?validity=${validity}`
          )
          .subscribe(
            (res) => {
              console.log('ressss', res);
              resolve(res['data']);
            },
            (error) => {
              console.log(error);
              console.log('hdvjscbhdvkjsdkj', error);
              reject(error);
            }
          );
      } catch (error) {
        reject(error);
      }
    });
  }

  createOrderIdForPluralGateWay(options, video?) {
    return new Promise(async (resolve, reject) => {
      try {
        let user = await this.userService.getLocalUser();

        console.log('in order with purple');

        if (user) {
          let orderDetails = this.createOrderObj(options, user);

          let order = await this.createMogiOrder(
            orderDetails,
            video ? true : false
          );

          return resolve(order['data']);
        }
      } catch (error) {
        console.log('error in order with plural gateway', error);
      }
    });
  }

  getPluralToken = async (options) => {
    try {
      const req = () => {
        new Promise((resolve, reject) =>
          this.http
            .post(`/payment/plural/createTokenForPluralGateway`, options)
            .subscribe(resolve, reject)
        );
      };
      const { data }: any = await req();
      console.log('logging the tokenn:', data);
      return data;
    } catch (error) {
      console.log('error in getting plural token::::', error);
    }
  };

  verifyPluralPayment = async (response, order) => {
    try {
      return new Promise((resolve, reject) => {
        this.http
          .get(
            `/payment/plural/verifyPayment?appId=${order.appId}&hrId=${order.hrId}&videoId=${order.videoId}&paymentId=${response.paymentId}`
          )
          .subscribe((res) => {
            console.log('logiing the ress', res, res['status']);
            if (res && res['status'] && res['status']['code'] == 200)
              return resolve({ status: 'payment_verified' });
            else {
              console.log('error in verifying payment of cashFree !!');
            }
          });
      });
    } catch (error) {
      console.log('error in verifying plural payment ::', error);
    }
  };
}
