import { Injectable } from '@angular/core';
import { HttpProvider } from '../http.service';
import { WacConfigService } from '../wac-config/wac-config.service';

@Injectable({
  providedIn: 'root'
})
export class WatchLaterService {

  constructor(
    public wacConfig: WacConfigService,
    public _http: HttpProvider
  ) { }

  async addWatchlaterVideo(watchLaterVideo) {
    if(!localStorage) return;
    let user = JSON.parse(localStorage.getItem('user'))
    watchLaterVideo['date'] = new Date();
    let data = {
        userId: user._id,
        video: watchLaterVideo,
        appId: this.wacConfig.configuration._id,
        videoId: watchLaterVideo._id,
        status: 1

    }
    return new Promise((resolve, reject) => {
        this._http.post("/ott/watch-later", data).subscribe(res => {
            resolve(res);
        },error => {
          reject(error);
        });
    });

  }

  deleteWatchLater(video) {
    if(!localStorage) return;
    let user = JSON.parse(localStorage.getItem('user'))
    let data ={
      status : -1
    }
    return new Promise((resolve, reject) => {
        this._http.put("/ott/watch-later/" + user['_id'] + "/video/" +video._id, data).subscribe(res => {
            resolve(res);
        }, error => {
          reject(error);
        })
    })
}

async getWatchLater() {
  if(!localStorage) return;
  let user = JSON.parse(localStorage.getItem('user'));
  if (user)
      return new Promise((resolve, reject) => {
          this._http.get("/ott/watch-later/" + user['_id']).subscribe(res => {
              resolve(res);
          }, error => {
            reject(error);
          });
      });
}
}
